<template>
  <div v-if="active">
    <TradesChart
      id="tradesChart"
      v-if="basic&&trades"
      :basic="basic"
      :trades="trades">
    </TradesChart>
    <ProcurementsChart
      id="procurementsChart"
      v-if="basic&&procurements_sum_by_year"
      :basic="basic"
      :procurements_sum_by_year="procurements_sum_by_year">
    </ProcurementsChart>
    <RevenueHistoryChart
      id="revenueHistoryChart"
      v-if="basic&&revenue_history"
      :basic="basic"
      :revenue_history="revenue_history">
    </RevenueHistoryChart>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import store from '@/store/index'
import { mapState, mapGetters } from 'vuex'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import pdfMake from 'pdfmake/build/pdfmake'
import moment from 'moment'
// import * as apiInterfaces from '@/interfaces/apiInterfaces.ts'
// import * as companyInterfaces from '@/interfaces/companyInterfaces.ts'
// import * as companysHistoryUtils from '@/utils/companysHistoryUtils.ts'
// import * as screenImageGetter from '@/utils/screenImageGetter.ts'
import TradesChart from '@/components/TradesChart/TradesChart.vue'
import ProcurementsChart from '@/components/ProcurementsChart/ProcurementsChart.vue'
import RevenueHistoryChart from '@/components/RevenueHistoryChart/RevenueHistoryChart.vue'
import * as pdfTemplates from '@/utils/pdfTemplates'

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    company: {
      required: true
    },
    condition:{
      default: function () {
        return {
          basic: ["description", "basicTable", "shareholders", "managers", "history", "branch", "factory", "登記地址近似公司"],
          news:true,
          verdicts: true,
          risk: ["dispute", "badVendor", 'data_gov_decision', 'data_gov_environmental_punishment', 'epa'],
          operating: ["trade", "procurement", "job", "patent", "award"],
          finance:[]
        }
      }
    },
    downloadRequestFilter: {
      default: function () {
        return {
          news: {},
          verdicts: {}
        }
      }
    }
  },
  components: {
    TradesChart,
    ProcurementsChart,
    RevenueHistoryChart
  },
  data () {
    return {
      basic: null,
      trades: null,
      procurements_sum_by_year: null,
      revenue_history: null
    }
  },
  computed: {
    ...mapState('companyDetail', [
      'companyNews',
    ]),
    ...mapGetters([
      'getterFirstCustomKeywords'
    ]),
    isShowTradesChart () {
      return this.basic && this.trades && this.trades.list && this.trades.list.length
    },
    isShowProcurementsChart () {
      return this.basic && this.procurements_sum_by_year && Object.keys(this.procurements_sum_by_year).length
    },
    isShowRevenueHistoryChart () {
      return this.basic && this.revenue_history && this.revenue_history.list && this.revenue_history.list.length
    }
  },
  methods: {
    async download () {
      // console.log('companyNews', this.companyNews)
      // console.log('downloadRequestFilter', this.downloadRequestFilter)
      // return
      try {
        // 等待繪製圖表
        await new Promise(resolve => setTimeout(() => resolve(), 1000))
        // -- 依權限建立pdfmake資料 --
        let baseTemplate = pdfTemplates.returnBaseTemplate({
          url: window.location.href,
          fontName: 'NotoSansTC'
        })

        baseTemplate.content = baseTemplate.content
          .concat(
            // 封面
            pdfTemplates.returnFrontCover({
              companyName: this.company.basic.company_name
            })
          ).concat(
            // 章節大標
            pdfTemplates.returnChepterTitle({
              title: this.company.basic.company_name,
              subtitle: ''
            })
          )

        if(this.condition.basic.includes("description")){
          baseTemplate.content = baseTemplate.content.concat(
            // 公司簡介 - 標題
            pdfTemplates.returnDescriptionTitle()
          ).concat(
            // 公司簡介
            pdfTemplates.returnDescription(this.company)
          )
        }
        if(this.condition.basic.includes("basicTable")){
          baseTemplate.content = baseTemplate.content.concat(
            // 商工登記資料 - 標題
            pdfTemplates.returnBasicTableTitle([this.company])
          ).concat(
            // 商工登記資料
            pdfTemplates.returnBasicTable(this.company)
          )
        }
        if (store.getters.getterLimitD) {
          // searchRequest.get.shareholders = { 'page-size': 10000 }
          // searchRequest.get.managers = { 'page-size': 10000 }
          // searchRequest.get.companys_history = { 'page-size': 10000 }
          // searchRequest.get.company_branchs = { 'page-size': 10000 }
          // searchRequest.get.factorys = { 'page-size': 10000 }
          if(this.condition.basic.includes("shareholders")){
            baseTemplate.content = baseTemplate.content
              .concat(
                // 董監事資料 - 標題
                pdfTemplates.returnShareholdersTableTitle([this.company])
              ).concat(
                // 董監事資料
                pdfTemplates.returnShareholdersTable(this.company)
              )
          }
          if(this.condition.basic.includes("managers")){
            baseTemplate.content = baseTemplate.content.concat(
              // 經理人資料 - 標題
              pdfTemplates.returnManagersTableTitle([this.company])
            ).concat(
              // 經理人資料
              pdfTemplates.returnManagersTable(this.company)
            )
          }
          if(this.condition.basic.includes("history")){
            baseTemplate.content = baseTemplate.content.concat(
              // 變更紀錄 - 標題
              pdfTemplates.returnCompanysHistoryTableTitle([this.company])
            ).concat(
              // 變更紀錄
              pdfTemplates.returnCompanysHistoryTable(this.company)
            )
          }
          if(this.condition.basic.includes("branch")){
            baseTemplate.content = baseTemplate.content.concat(
              // 分公司資料 - 標題
              pdfTemplates.returnCompanyBranchsTableTitle([this.company])
            ).concat(
              // 分公司資料
              pdfTemplates.returnCompanyBranchsTable(this.company)
            )
          }
        }

        // (與貿易資料相依)
        if(this.condition.operating.includes("trade")){
          baseTemplate.content = baseTemplate.content
            .concat(
              // 國貿局「出進口廠商管理系統」 - 標題
              pdfTemplates.returnCompanyNameTableTitle([this.company])
            ).concat(
              // 國貿局「出進口廠商管理系統」
              pdfTemplates.returnCompanyNameTable(this.company)
            ).concat(
              // 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站 - 標題
              pdfTemplates.returnCompanyStateTableTitle([this.company])
            ).concat(
              // 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站
              pdfTemplates.returnCompanyStateTable(this.company)
            )
        }
        if (store.getters.getterLimitD) {
          if(this.condition.basic.includes("factory")){
            baseTemplate.content = baseTemplate.content
              .concat(
                // 工廠登記 - 標題
                pdfTemplates.returnFactorysTableTitle([this.company])
              ).concat(
                // 工廠登記
                pdfTemplates.returnFactorysTable(this.company)
              )
          }

          if(this.condition.basic.includes("登記地址近似公司")){
            baseTemplate.content = baseTemplate.content.concat(
              // 登記地址近似公司 - 標題
              pdfTemplates.returnSimilarAddressTitle([this.company])
            ).concat(
              // 登記地址近似公司
              pdfTemplates.returnSimilarAddress(this.company)
            )
          }
        }

        // 風險指標
        if (store.getters.getterLimitE) {
          // searchRequest.get.verdicts = { 'page-size': 10000 }
          // searchRequest.get.news = {}
          // searchRequest.get.disputes = { 'page-size': 10000 }
          if(this.condition.verdicts){
            baseTemplate.content = baseTemplate.content
              .concat(
                // 法律訴訟 - 標題
                pdfTemplates.returnVerdictsTableTitle([this.company])
              ).concat(
                // 法律訴訟
                pdfTemplates.returnVerdictsTable(this.company)
              )
          }
          if(this.condition.news){
            // console.log('companyNews', this.companyNews)
            // console.log('downloadRequestFilter', this.downloadRequestFilter)
            let customKeywords = []
            // 如果有勾選「DBS CDD負面字串」filter陣列裡才有東西
            // if (this.companyNews.cls.custom && this.downloadRequestFilter.news.filter && this.downloadRequestFilter.news.filter.length) {
            //   customKeywords = Object.values(this.companyNews.cls.custom)
            //       .flat()
            //       .map(d => d.key)
            // }
            if (this.downloadRequestFilter.news.filter && this.downloadRequestFilter.news.filter.length) {
              customKeywords = this.getterFirstCustomKeywords
            }

            baseTemplate.content = baseTemplate.content.concat(
              // 新聞事件 - 標題
              pdfTemplates.returnNewsTableTitle([this.company], customKeywords)
            ).concat(
              // 新聞事件
              pdfTemplates.returnNewsTable(this.company)
            )
          }
          if(this.condition.risk.includes("dispute")){
            baseTemplate.content = baseTemplate.content.concat(
              // 勞資糾紛 - 標題
              pdfTemplates.returnDisputesTableTitle([this.company])
            ).concat(
              // 勞資糾紛
              pdfTemplates.returnDisputesTable(this.company)
            )
          }
          if(this.condition.risk.includes("badVendor")){
            baseTemplate.content = baseTemplate.content.concat(
              // 拒絕往來廠商紀錄 - 標題
              pdfTemplates.returnProcurementBadVendorsTableTitle([this.company])
            ).concat(
              // 拒絕往來廠商紀錄
              pdfTemplates.returnProcurementBadVendorsTable(this.company)
            )
          }
          if(this.condition.risk.includes("data_gov_decision")){
            baseTemplate.content = baseTemplate.content.concat(
              // 公平交易委員會行政決定 - 標題
              pdfTemplates.returnDataGovDecisionTableTitle([this.company])
            ).concat(
              // 公平交易委員會行政決定
              pdfTemplates.returnDataGovDecisionTable(this.company)
            )
          }
          if(this.condition.risk.includes("data_gov_environmental_punishment")){
            baseTemplate.content = baseTemplate.content.concat(
              // 環保署裁罰處分 - 標題
              pdfTemplates.returnDataGovEnvironmentalPunishmentTableTitle([this.company])
            ).concat(
              // 環保署裁罰處分
              pdfTemplates.returnDataGovEnvironmentalPunishmentTable(this.company)
            )
          }
          if(this.condition.risk.includes("epa")){
            baseTemplate.content = baseTemplate.content.concat(
              // 列管汙染源資料裁處資訊 - 標題
              pdfTemplates.returnEpaTableTitle([this.company])
            ).concat(
              // 列管汙染源資料裁處資訊
              pdfTemplates.returnEpaTable(this.company)
            )
          }
        }
        // 經營指標
        if (store.getters.getterLimitF) {
          // searchRequest.get.trades = { 'page-size': 10000 }
          // searchRequest.get.procurements = { 'page-size': 10000 }
          // searchRequest.get.patents = { 'page-size': 10000 }
          // searchRequest.get.awards = { 'page-size': 10000 }

          if(this.condition.operating.includes("trade")){
            // 進出口實績
            baseTemplate.content = baseTemplate.content
              .concat(
                // 進出口實績 - 標題
                pdfTemplates.returnTradesTableTitle([this.company])
              ).concat(
                // 進出口實績
                pdfTemplates.returnTradesTable(this.company)
              )

            // 進出口實績圖表
            if (this.isShowTradesChart) {
              const tradesChart = await pdfTemplates.returnTradesChart('#tradesChart')
              baseTemplate.content = baseTemplate.content.concat(tradesChart)
            }
          }
          if(this.condition.operating.includes("procurement")){
            // 政府標案
            baseTemplate.content = baseTemplate.content
              .concat(
                // 政府標案 - 標題
                pdfTemplates.returnProcurementsTableTitle([this.company])
              )
              .concat(
                // 政府標案
                pdfTemplates.returnProcurementsTable(this.company)
              )
            // 政府標案圖表
            if (this.isShowProcurementsChart) {
              const procurementsChart = await pdfTemplates.returnProcurementsChart('#procurementsChart')
              baseTemplate.content = baseTemplate.content.concat(procurementsChart)
            }
          }
          if(this.condition.operating.includes("job")){
          // 人力招聘
          baseTemplate.content = baseTemplate.content
            .concat(
              // 人力招聘 - 標題
              pdfTemplates.returnJobsTableTitle([this.company])
            ).concat(
              // 人力招聘
              pdfTemplates.returnJobsTable(this.company)
            )
          }

          if(this.condition.operating.includes("patent")){
          baseTemplate.content = baseTemplate.content
            .concat(
              // 專利資訊 - 標題
              pdfTemplates.returnPatentsTableTitle([this.company])
            ).concat(
              // 專利資訊
              pdfTemplates.returnPatentsTable(this.company)
            )
          }
          if(this.condition.operating.includes("award")){
            baseTemplate.content = baseTemplate.content.concat(
              // 得獎紀錄與政府推薦名單 - 標題
              pdfTemplates.returnAwardsTableTitle([this.company])
            ).concat(
              // 得獎紀錄與政府推薦名單
              pdfTemplates.returnAwardsTable(this.company)
            )
          }
        }
        // 財務指標
        if (store.getters.getterLimitG) {
          // searchRequest.get.revenue_history = { 'page-size': 10000 }
          // searchRequest.get.ifrss_summary = { 'page-size': 10000 }

          if(this.condition.finance.includes("news")){
            baseTemplate.content = baseTemplate.content
              .concat(
                // 公司近期發布之重大訊息 - 標題
                pdfTemplates.returnPublicsNewsTableTitle([this.company])
              ).concat(
                // 公司近期發布之重大訊息
                pdfTemplates.returnPublicsNewsTable(this.company)
              )
          }
          if(this.condition.finance.includes("revenue")){
            baseTemplate.content = baseTemplate.content.concat(
              // 營收資訊 - 標題
              pdfTemplates.returnRevenueHistoryTableTitle([this.company])
            ).concat(
              // 營收資訊
              pdfTemplates.returnRevenueHistoryTable(this.company)
            )
            // 歷年實績統計圖表
            if (this.isShowRevenueHistoryChart) {
              const revenueHistoryChart = await pdfTemplates.returnRevenueHistoryChart('#revenueHistoryChart')
              baseTemplate.content = baseTemplate.content.concat(revenueHistoryChart)
            }
          }

          if(this.condition.finance.includes("ifrss")){
          baseTemplate.content = baseTemplate.content
            .concat(
              // 採IFRSs後財報資訊 - 標題
              pdfTemplates.returnIfrssSummaryTableTitle([this.company])
            ).concat(
              // 採IFRSs後財報資訊
              pdfTemplates.returnIfrssSummaryTable(this.company)
            )
          }
        }
        // 封底
        baseTemplate.content = baseTemplate.content.concat(
          pdfTemplates.returnBackCover()
        )

        // -- 產生pdf --
        // pdfMake.vfs = await import('pdfmake/build/vfs_fonts')
        // pdfMake.vfs = await import('@/assets/fonts/vfs_fonts.js')
        pdfMake.vfs = await import('@/assets/fonts/vfs_NotoSansTC.js')

        pdfMake.fonts = {
          /*Roboto為默認字體*/
          // Roboto: {
          //   normal: 'Roboto-Regular.ttf',
          //   bold: 'Roboto-Medium.ttf',
          //   italics: 'Roboto-Italic.ttf',
          //   bolditalics: 'Roboto-Italic.ttf'
          // },
          /*加入我們自己的中文的字體*/
          // MSFont: {
          //   normal: 'MingLiU.ttf',
          //   bold: 'MingLiU.ttf',
          //   italics: 'MingLiU.ttf',
          //   bolditalics: 'MingLiU.ttf',
          // },
          NotoSansTC: {
            normal: 'NotoSansTC-Regular.woff2',
            bold: 'NotoSansTC-Bold.woff2',
          }
        }

        // console.log(baseTemplate)
        // console.log(JSON.stringify(baseTemplate, null, 2))

        pdfMake.createPdf(baseTemplate).download(`${this.basic.company_name}_${moment().format('YYYY-MM-DD')}`);

        this.$emit('update:active', false)
        // return Promise.resolve(baseTemplate)
      } catch (e) {
        console.error(e)
        this.$message.error('未知錯誤')
        // return Promise.reject(null)
        this.$emit('update:active', false)
      }
    }
  },
  watch: {
    active: {
      handler (d) {
        if (d === true && this.company && this.company.basic) {
          // 取得必要資料
          let trades = null
          let revenue_history = null
          let procurements_sum_by_year = null

          this.basic = JSON.parse(JSON.stringify(this.company.basic))
          if (this.company.trades) {
            trades = JSON.parse(JSON.stringify(this.company.trades))
          }
          this.trades = trades
          if (this.company.revenue_history) {
            revenue_history = JSON.parse(JSON.stringify(this.company.revenue_history))
          }
          this.revenue_history = revenue_history
          if (this.company.basic.procurements_sum_by_year) {
            procurements_sum_by_year = JSON.parse(JSON.stringify(this.company.basic.procurements_sum_by_year))
          }
          this.procurements_sum_by_year = procurements_sum_by_year

          // 下載
          this.download()
        }
      },
      immediate: true
    }
  }
}
</script>
