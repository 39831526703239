<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">專利認證 {{patentsCount}} 項</span>
      <span class="section-sub-title">資料來源：經濟部智慧財產局</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentPatents.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="專利名稱"
          prop="patent_name_chinese">
        </el-table-column>
        <el-table-column
          label="發布地區"
          width="100">
          <template slot-scope="scope">
            {{returnAreaName(scope.row.area_pub)}}
          </template>
        </el-table-column>
        <el-table-column
          label="發布日期"
          width="100"
          prop="publish_date">
        </el-table-column>
        <el-table-column
          label="申請號"
          width="120"
          prop="appl_no">
        </el-table-column>
        <el-table-column
          label="公告/公開號"
          width="120"
          prop="publish_no">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="patentsSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.patents
      }
    },
    'patent_rights': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentPatents: {
        list: [],
        stat: {}
      },
      currentPage: 1
    }
  },
  computed: {
    patentsCount () {
      if (this.currentPatents.stat['total-count']) {
        return this.currentPatents.stat['total-count']
      } else {
        return 0
      }
    },
    patentsSearchableCount () {
      if (this.currentPatents.stat['total-count']) {
        if (this.currentPatents.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentPatents.stat['total-count']
        }
      } else {
        return 0
      }
    },
  },
  methods: {
    returnAreaName (code) {
      if (code) {
        return codes.PATENTS_AREA_DIST[code] || '-'
      } else {
        return '-'
      }
    },
    search () {
      let searchRequest = {
        get: {
          patent_rights: {
            'page-index': this.currentPage
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.patent_rights) {
            this.currentPatents = data.payload.patent_rights
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'patent_rights': {
      handler (d) {
        if (d) {
          this.currentPatents = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
