













































































import { defineComponent, ref, computed, watch, onMounted, SetupContext, Ref } from '@vue/composition-api'
import moment from 'moment'

type Props = {
  width: string | number;
  height: string | number;
  headerHeight: string | number;
  title: string;
  tooltip: string;
  description: string;
  screenShotMode: boolean;
}

export default defineComponent({
  props: {
    width: {
      default () {
        return '100%'
      }
    },
    height: {
      default () {
        return 500
      }
    },
    headerHeight: {
      default () {
        return 65
      }
    },
    title: {
      default () {
        return ''
      }
    },
    tooltip: {
      default () {
        return ''
      }
    },
    description: {
      default () {
        return ''
      }
    },
    screenShotMode: {
      default () {
        return false
      }
    }
  },
  setup (props: Props, { emit }: SetupContext) {
    const containerWidth = computed(() => {
      return typeof props.width === 'number' ? `${props.width}px` : props.width
    })
    const containerHeight = computed(() => {
      return typeof props.height === 'number' ? `${props.height}px` : props.height
    })
    const headerHeightNumber = ref(
      typeof props.headerHeight === 'string' ? Number(props.headerHeight.replace('px', '')) : props.headerHeight
    )

    const screenShotTime = ref('')
    // const syncScreenShotMode = computed(() => props.screenShotMode)
    // watch(syncScreenShotMode, () => {
    //   screenShotTime.value = moment().format('YYYY/MM/DD HH:mm')
    // }, { immediate: true })

    const cardBody: Ref<HTMLElement | null> = ref(null)
    onMounted(() => {
      
    })

    return {
      cardBody,
      containerWidth,
      containerHeight,
      headerHeightNumber,
      screenShotTime
    }
  }
})
