<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：公開資訊觀測站</span>
    </h4>
    <div class="table-set" style="margin-bottom:15px">
      <el-table
        :data="tableData"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="年度(月)"
          width="180"
          prop="yearMonth">
        </el-table-column>
        <el-table-column
          label="(月)營收"
          width="180"
          prop="valueThisYear">
        </el-table-column>
        <el-table-column
          label="去年同期(月)營收"
          width="180"
          prop="valueLastYear">
        </el-table-column>
        <el-table-column
          label="去年同期累計(月)營收成長率"
          prop="rateGrowth">
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="revenueHistorySearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div> -->
    <div v-show="revenueHistoryCount">
      <RevenueHistoryChart
        v-if="currentRevenueHistory.list.length"
        :basic="basic"
        :revenue_history="currentRevenueHistory">
      </RevenueHistoryChart>
      <!-- <div
        ref="chart"
        style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import RevenueHistoryChart from '@/components/RevenueHistoryChart/RevenueHistoryChart.vue'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.revenue_history
      }
    },
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'revenue_history': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  components: {
    RevenueHistoryChart
  },
  data () {
    return {
      isLoading: false,
      currentRevenueHistory: {
        list: [],
        stat: {}
      },
      currentPage: 1,
    }
  },
  computed: {
    revenueHistoryCount () {
      if (this.currentRevenueHistory.stat['total-count']) {
        return this.currentRevenueHistory.stat['total-count']
      } else {
        return 0
      }
    },
    // revenueHistorySearchableCount () {
    //   if (this.currentRevenueHistory.stat['total-count']) {
    //     if (this.currentRevenueHistory.stat['total-count'] > config.SEARCH_MAX_COUNT) {
    //       return config.SEARCH_MAX_COUNT
    //     } else {
    //       return this.currentRevenueHistory.stat['total-count']
    //     }
    //   } else {
    //     return 0
    //   }
    // },
    tableData () {
      let list = this.currentRevenueHistory.list.map(d => {
        return {
          yearMonth: moment(d.year).format('YYYY年MM月 營收'),
          valueThisYear: d.value_this_year ? utils.formatCommaNumber(d.value_this_year) : '-',
          valueLastYear: d.value_last_year ? utils.formatCommaNumber(d.value_last_year) : '-',
          rateGrowth: d.rate_growth
        }
      })
      if (this.basic.publics && this.basic.publics.revenue_str) {
        // 累計營收資料
        const json = JSON.parse(this.basic.publics.revenue_str)
        if (json && json.total) {
          list = list.concat({
            yearMonth: '今年迄今累計營收',
            valueThisYear: json.total.this_year,
            valueLastYear: json.total.last_year,
            rateGrowth: json.total.rate_growth
          })
        }
      }
      return list
    }
  },
  methods: {
    // formatYearMonth (date) {
    //   return moment(date).format('YYYY年MM月 營收')
    // },
    // formatNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    returnImportOrExportLabel (code) {
      return codes.IMPORT_AND_EXPORT_DATA[code].label
    },
    search () {
      let searchRequest = {
        get: {
          revenue_history: {
            'page-index': this.currentPage
          }
        },
        uniID: this.uniID
      }
      if (this.startDate) {
        searchRequest.get.revenue_history.time.start = this.startDate
      }
      if (this.endDate) {
        searchRequest.get.revenue_history.time.end = this.endDate
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.revenue_history) {
            this.currentRevenueHistory = data.payload.revenue_history
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
  },
  watch: {
    revenue_history: {
      handler (d) {
        if (d) {
          this.currentRevenueHistory = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
