<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：104、1111</span>
    </h4>
    <pre
      id="company_description_text"
      style="position:relative;white-space:pre-wrap"
      :style="textStyle">
      <div v-if="isPreviewMode" class="cover">
      </div>
{{description}}
</pre>
    <el-button
      type="text"
      style="margin-top:10px"
      v-if="isPreviewMode"
      @click="fullTextMode">
      ... 繼續閱讀
    </el-button>
    <!-- <p>藍星球資訊股份有限公司創立於2013年， 組成兼具「資訊」技術與「人文」精神的團隊，致力於數位化、詮釋資料分析、數位加值 及資料庫檢索技術整合之服務與研發。</p>
    <p>在大數據、資訊爆炸的時代，藍星球團隊不斷精進核心技術，提供智慧化的文本分析、相似文件群集、前後文自動化串聯等中文字的探勘技術，藉以從龐大的資料中找出使用者所關注、需要的文件。藍星球專長將全文資料、非結構性資料、半結構性資料中重要的關鍵字詞自動化標註、將不同作者所撰寫的文本正規化，不但可確保資料格式的一致性、有效的減少資料中的錯誤，還可提升資料的可利用性。</p> -->
  </div>
</template>

<script>
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_ITEM_MAP.description.name
      }
    },
    'description': {
      required: true,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      lineLimit: 12,
      perLineHeight: 0, // 行高
      isPreviewMode: false
    }
  },
  computed: {
    // currentDescription () {

    // }
    // 文字預覽模式樣式
    textStyle () {
      if (this.isPreviewMode) {
        return {
          'height': `${this.perLineHeight * this.lineLimit}px`,
          'overflow': 'hidden'
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    fullTextMode () {
      this.isPreviewMode = false
    }
  },
  mounted () {
    // 取得行高
    let lineEle = document.getElementById('company_description_text')
    var styles = window.getComputedStyle(lineEle, null);
    this.perLineHeight = parseInt(styles.lineHeight, 10);

  },
  watch: {
    'description': {
      async handler (d) {
        if (d) {
          await this.$nextTick()
          let totalLines = utils.countLines(document.getElementById('company_description_text'))
          // console.log(totalLines)
          if (totalLines > this.lineLimit) {
            this.isPreviewMode = true
          } else {
            this.isPreviewMode = false
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  // 遮罩
  .cover {
    position: absolute;
    width: 100%;
    height: 100px;
    background: linear-gradient(rgba(255,255,255,0) 0%,rgba(255,255,255,100) 100%);
    left: 0px;
    bottom: 0px;
  }
</style>

