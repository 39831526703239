<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：經濟部商業司</span>
    </h4>
    <table class="table--fill table--border table--vertical table--rwd">
      <tr>
        <!-- 統一編號 -->
        <th>{{mapBasicColumnName(basic.data_type, 'uniID')}}</th>
        <td>{{basic.uniID}}</td>
        <!-- 代表人姓名 -->
        <th>{{mapBasicColumnName(basic.data_type, 'representative_name')}}</th>
        <td>
          {{basic.representative_name}}
          <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
          <router-link
            target="_blank"
            :to="returnRepresentativeLinkTo(basic.representative_name)">
            <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
          </router-link>
          <!-- </a> -->
        </td>
      </tr>
      <tr>
        <!-- 資本總額 -->
        <th>{{mapBasicColumnName(basic.data_type, 'capital_amount')}}</th>
        <td>{{basic.capital_amount | emptyFormat | numberWithComma}}</td>
        <!-- 實收資本額（元） -->
        <th>{{mapBasicColumnName(basic.data_type, 'paidup_capital_amount')}}</th>
        <td>{{basic.paidup_capital_amount | emptyFormat | numberWithComma}}</td>
      </tr>
      <tr>
        <!-- 核准設立日期 -->
        <th>{{mapBasicColumnName(basic.data_type, 'date_approved')}}</th>
        <td>{{basic.date_approved}}</td>
        <!-- 最後核准變更日期 -->
        <th>{{mapBasicColumnName(basic.data_type, 'date_approved_latest')}}</th>
        <td>{{basic.date_approved_latest}}</td>
      </tr>
      <tr>
        <!-- 登記機關 -->
        <th>{{mapBasicColumnName(basic.data_type, 'registration_department')}}</th>
        <td>{{basic.registration_department}}</td>
        <!-- 公司狀況 -->
        <th>{{mapBasicColumnName(basic.data_type, 'company_state')}}</th>
        <td>{{basic.company_state}}</td>
      </tr>
      <tr>
        <!-- 登記地址 -->
        <th>{{mapBasicColumnName(basic.data_type, 'company_address')}}</th>
        <td>
          {{basic.company_address}}
          <a href="#" target="_blank" style="margin-left: 4px;">
            <router-link
              target="_blank"
              :to="returnCompanyAddressLinkTo(basic.uniID)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link>
          </a>
        </td>
        <!-- 每股金額 -->
        <th>{{mapBasicColumnName(basic.data_type, 'value_per_share')}}</th>
        <td>{{basic.value_per_share | emptyFormat | numberWithComma}}</td>
      </tr>
      <tr>
        <!-- 所營事業資料 -->
        <th>{{mapBasicColumnName(basic.data_type, 'business_matters')}}</th>
        <td colspan="3">
          <div class="pre-line">
            {{basic.business_matters}}
            <!-- I301010  資訊軟體服務業
            I301020  資料處理服務業
            I301030  電子資訊供應服務業
            F118010  資訊軟體批發業
            F119010  電子材料批發業
            F218010  資訊軟體零售業
            F401010  國際貿易業
            ZZ99999  除許可業務外，得經營法令非禁止或限制之業務
            F113050  電腦及事務性機器設備批發業
            F213030  電腦及事務性機器設備零售業
            IZ10010  排版業
            IZ01010  影印業
            C702010  製版業
            F601010  智慧財產權業
            I105010  藝術品諮詢顧問業
            I401010  一般廣告服務業
            I501010  產品設計業
            C701010  印刷業
            CC01120  資料儲存媒體製造及複製業
            J399010  軟體出版業 -->
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as codes from '@/const/codes.ts'
import utils from '@/utils/utils.js'
import { mapBasicColumnName } from '@/utils/mapColumnName.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.basic
      }
    },
    'basic': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    mapBasicColumnName,
    returnCompanyAddressLinkTo (uniID) {
      return {
        name: 'map',
        query: {
          uniID
        }
      }
    },
    returnRepresentativeLinkTo (representativeName) {
      if (!representativeName) {
        return null
      }
      // 預設的比較人物查詢條件
      const basicList = [
        {
          field: 'all',
          keyword: representativeName
        }
      ]
      const defaultGet = {
        companys: {},
        verdicts: {},
        news: {
          // 'remove-duplicate': 1
        }
      }
      return {
        name: 'multiPersonDetail',
        params: {
          menuIndex: representativeName
        },
        query: {
          basicList: JSON.stringify(basicList),
          get: JSON.stringify(defaultGet)
        }
      }
    },
  },
  watch: {

  }
}
</script>
