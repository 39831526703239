<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">共為您搜尋到 {{dataCount}} 筆資料</span>
      <span class="section-sub-title">資料來源：公平交易委員會</span>
    </h4>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="起始日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <span class="connect">至</span>
          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="結束日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <!-- <el-select class="toolbar-input" v-model="searchType" placeholder="搜尋方式" size="mini">
            <el-option
              v-for="item in searchTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="search">
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="@Q@ 其實我也不知道這個是什麼" placement="bottom">
            <font-awesome-icon :icon="['far', 'question-circle']" style="vertical-align:middle;margin-right: 4px;"/>
          </el-tooltip> -->
        </div>
      </div>
      <el-table
        :data="currentTableData.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="公告/處分日期"
          width="100"
          prop="date">
        </el-table-column>
        <el-table-column
          label="違反事由"
          prop="title">
        </el-table-column>
        <el-table-column
          label="處分書"
          width="90">
          <template slot-scope="scope">
            <a target="_blank" :href="urlFormat(scope.row.file)">
              全文
            </a>
          </template>
        </el-table-column>
        <el-table-column
          label="備註"
          width="200"
          prop="note">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="searchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
// import searchTypeOptions from '@/const/searchTypeOptions.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.data_gov_decision
      }
    },
    'data_gov_decision': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      // searchTypeOptions,
      isLoading: false,
      currentTableData: {
        list: [],
        stat: {}
      },
      startDate: '',
      endDate: moment().format('YYYY-MM-DD'),
      searchType: '',
      currentPage: 1
    }
  },
  computed: {
    dataCount () {
      if (this.currentTableData.stat['total-count']) {
        return this.currentTableData.stat['total-count']
      } else {
        return 0
      }
    },
    searchableCount () {
      if (this.currentTableData.stat['total-count']) {
        if (this.currentTableData.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentTableData.stat['total-count']
        }
      } else {
        return 0
      }
    },
  },
  methods: {
    returnDisputesDate (date) {
      return date ? moment(date).format('YYYY-MM-DD') : '-'
    },
    search () {
      let searchRequest = {
        get: {
          data_gov_decision: {
            'page-index': this.currentPage,
            time: {}
          }
        },
        uniID: this.uniID
      }
      if (this.startDate) {
        searchRequest.get.data_gov_decision.time.start = this.startDate
      }
      if (this.endDate) {
        searchRequest.get.data_gov_decision.time.end = this.endDate
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.data_gov_decision) {
            this.currentTableData = data.payload.data_gov_decision
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
    urlFormat (url) {
      let newUrl = url.replace('https://', '').replace('http://', '')
      return `https://${newUrl}`
    }
  },
  watch: {
    'data_gov_decision': {
      handler (d) {
        if (d) {
          this.currentTableData = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
