import moment from 'moment'
import * as codes from '@/const/codes'
import * as apiInterfaces from '@/interfaces/apiInterfaces'
import * as companyInterfaces from '@/interfaces/companyInterfaces'
import utils from '@/utils/utils.js'
import * as screenImageGetter from '@/utils/screenImageGetter'
import * as companysHistoryUtils from '@/utils/companysHistoryUtils'
// @ts-ignore
import store from '@/store/index'


// 主題色
const subjectColor = '#C00000'

// 找最大日期
function findMaxDate(dateArr:Array<string>):string | null{
  try {
    const strArr = dateArr.filter(d => d)
    const momentArr = strArr.map(d => moment(d))
    if (momentArr.length === 0) {
      return null
    }
    let maxDateIndex = 0
    let maxDate = momentArr[0]
    for (let i = 1; i < momentArr.length; i++) {
      const date = momentArr[i]
      if (maxDate.isBefore(date)) {
        maxDateIndex = i
        maxDate = date
      }
    }
    return strArr[maxDateIndex]
  } catch(e) {
    console.error(e)
    return null
  }
}

// 資料群組標題
function returnSubjectTitleSection({ title, subtitle, url, updateTime }: { title: string, subtitle?: string, url?: string, updateTime?:string }):Array<any>{
  try {
    let template: any[] = [
      {
        stack: [
          {
            canvas: [
              {
                type: 'rect', color: subjectColor, x: 0, y: 0, w: 50, h: 3
              },
            ],
          },
          {
            text: [
              title,
            ],
            style: 'firstTitle'
          }
        ],
        style: 'firstTitleSection'
      }
    ]
    if (subtitle) {
      template[0].stack.push({
        text: subtitle,
        style: 'subtitle'
      })
    }
    if (url) {
      template[0].stack.push({
        text: `資料來源：${url}`,
        style: 'info'
      })
    }
    if (updateTime) {
      template[0].stack.push({
        text: `更新時間：${updateTime}`,
        style: 'info'
      })
    }
    return template
  } catch(e){
    console.error(e)
    return []
  }
}

// Table內容（直排列資料）
function returnTableSection(table: any): Array<any>{
  return [
    {
			table,
			layout: {
        hLineWidth: function (i: number, node: any) {
					return 0.5;
				},
				vLineWidth: function (i: number, node: any) {
					return 0
				},
				hLineColor: function (i: number, node: any) {
					return '3B3B3C';
				},
				// vLineColor: function (i, node) {
				// 	return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
				// },
				paddingTop: function () {
          return 5
				},
				paddingBottom: function () {
          return 5
				},
				paddingLeft: function (i: number) {
          return 10
        },
        paddingRight: function (i: number, node: any) {
          return 5
        },
				fillColor: function (rowIndex: number, node: any, columnIndex: number) {
					return (columnIndex % 2 === 1) ? '#E6E6E6' : null;
				}
			},
			style: 'contentSection'
		}
  ]
}

// Grid內容（橫排列資料）
function returnGridSection(body: Array<any>): Array<any>{
  return [
    {
			table: {
        widths: [100, '*', 200],
				body
			},
			layout: {
        hLineWidth: function (i: number, node: any) {
				  // 	return (i === 0 || i === node.table.body.length) ? 0 : 0.5;
          return i === 0 ? 0 : 0.5;
				},
				vLineWidth: function (i: number, node: any) {
					return 0
				},
				hLineColor: function (i: number, node: any) {
					return '3B3B3C';
				},
				// vLineColor: function (i, node) {
				// 	return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
				// },
				paddingTop: function () {
          return 5
				},
				paddingBottom: function () {
          return 5
				},
				paddingLeft: function (i: number) {
          return 10
        },
        paddingRight: function (i: number, node: any) {
          return 5
        },
				fillColor: function (rowIndex: number, node: any, columnIndex: number) {
					return (rowIndex > 0 && columnIndex % 2 === 1) ? '#E6E6E6' : null;
				}
			},
			style: 'contentSection'
		}
  ]
}

// 文字內容
function returnTextSection(text: string): Array<any>{
  return [
    {
      text: text || '-',
      style: 'contentSection'
    }
  ]
}

// 圖表區塊
async function returnChartSection(base64: string) {
  try {
    if (base64.length > 6) {
      return [
        {
          image: base64,
          width: 510,
          style: 'contentSection'
        },
      ]
    } else {
      return []
    }
  } catch(e){
    console.error(e)
    return []
  }
}

// 公司名稱標題（次標題）
export function returnSecondTitleSection(companyName:string): Array<any>{
  return [
    {
      text: companyName,
      style: 'secondTitleSection'
    }
  ]
}

// 基本template（除了content以外的東西先建起來）
export function returnBaseTemplate({ url, fontName = 'NotoSansTC' }: { fontName: string, url: string }): any{
  let baseTemplate = {
    pageMargins: [40, 40, 40, 75],
    footer: null,
    header: null,
    content: [],
    styles: {
      frontCoverTitle: {
        fontSize: 28,
        color: subjectColor,
        bold: true,
        margin: [0, 10]
      },
      frontCoverSubtitle: {
        fontSize: 16
      },
      footerUrl: {
        color: '#898989',
        fontSize: 8
      },
      footerPage: {
        fontSize: 10,
        bold: true
      },
      chepterTitleSection: {
        margin: [ 0, 10, 0, 30 ]
      },
      chepterTitle: {
        fontSize: 24,
        color: '#ACACAC',
        bold: true,
        margin: [ 0, 0, 0, 10 ]
      },
      chepterSubtitle: {
        fontSize: 14,
        // bold: true
      },
      firstTitleSection: {
        margin: [ 0, 10, 0, 10 ]
      },
      firstTitle: {
        fontSize: 18,
        margin: [ 0, 15, 0, 5 ]
      },
      subtitle: {
        fontSize: 10,
        margin: [0, 0, 0, 10]
      },
      info: {
        fontSize: 10,
        color: '#898989',
        // margin: [0, 2]
      },
      secondTitleSection: {
        fontSize: 14,
        color: subjectColor,
        margin: [10, 10, 0, 0]
      },
      contentSection: {
        fontSize: 10,
        margin: [0, 10]
      },
      tableHeader: {
        bold: true
      }
    },
    defaultStyle: {
      // font: 'MSFont'
      font: 'NotoSansTC',
      // alignment: 'justify'
    }
  }

  function dateRotatedText (text: string) {
    var ctx, canvas = document.createElement('canvas');
    // I am using predefined dimensions so either make this part of the arguments or change at will
    canvas.width = 14;
    canvas.height = 200;
    ctx = canvas.getContext('2d')!;
    ctx.font = '14pt Arial';
    ctx.save();
    ctx.translate(0,0);
    ctx.rotate(0.5*Math.PI);
    ctx.fillStyle = subjectColor;
    ctx.fillText(text , 0, 0);
    ctx.restore();
    return canvas.toDataURL();
  }

  // footer
  function returnFooter({ url }: { url: string }): any {
    return (currentPage: number, pageCount: number) => {
      if (currentPage === 1 || currentPage === pageCount) {
        return null
      }
      return {
        stack: [
          {
            canvas: [
              { type: 'line', x1: 40, y1: 0, x2: 595-(2*20), y2: 0, lineWidth: 1, color: '#3B3B3C' }
            ],
            margin: [0, 15, 0, 6]
          },
          {
            text: url,
            alignment: 'center',
            style: 'footerUrl'
          },
          {
            text: currentPage - 1,
            alignment: 'right',
            margin: [0,0,60,0],
            style: 'footerPage'
          },
        ],
        height:200
      }
    }
  }

  // header
  function returnHeader(): any{
    return function(currentPage: number, pageCount: number, pageSize: number) {
      // you can apply any logic and return any valid pdfmake element
      if (currentPage === 1 || currentPage === pageCount) {
        return null
      }
      return {
        columns: [
          {
            image: dateRotatedText(moment().format('YYYY - MM - DD')),
            fit:[6,150],
            alignment: 'right',
            margin: [0, 20, 5, 0]
          },
        ]
      }
    }
  }

  baseTemplate.header = returnHeader()
  baseTemplate.footer = returnFooter({ url })
  baseTemplate.defaultStyle.font = fontName

  return baseTemplate
}

// 封面
export function returnFrontCover({ companyName }: { companyName: string }): Array<any>{
  return [
    {
      stack: [
        {
          text:'Business Genie Report',
          style: 'frontCoverTitle'
        },
        {
          text: companyName,
          style: 'frontCoverSubtitle'
        },
      ],
      margin: [0, 260],
      pageBreak: 'after'
    },
  ]
}

// 封底
export function returnBackCover(): Array<any>{
  try {
    function backCoverRotatedText (text: string) {
      var ctx, canvas = document.createElement('canvas');
      // I am using predefined dimensions so either make this part of the arguments or change at will
      canvas.width = 32;
      canvas.height = 500;
      ctx = canvas.getContext('2d')!;
      ctx.font = '32pt Arial';
      ctx.save();
      ctx.translate(0,0);
      ctx.rotate(0.5*Math.PI);
      ctx.fillStyle = '#ffffff';
      ctx.fillText(text , 0, 0);
      ctx.restore();
      return canvas.toDataURL();
    }

    return [
      {
        canvas: [
          {
            type: 'rect',
            x: -40, y: -40, w: 595.28, h: 841.89, // w: 595.28, h: 841.89
            color: subjectColor,
          },
        ],
      },
      {
          image: backCoverRotatedText('Business Genie Report'),
          fit:[16,250],
          margin: [-5, -841.89 + 100],
          alignment: 'right',
      }
    ]
  } catch (e) {
    console.error(e)
    return []
  }
}

// 章節標題
export function returnChepterTitle({ title, subtitle }: { title: string, subtitle: string }):Array<any>{
  try {
    let section = [
      {
        stack: [
          {
              text: title,
              style: 'chepterTitle'
          }
        ],
        style: 'chepterTitleSection'
      }
    ]
    if (subtitle) {
      section[0].stack.push({
        text: subtitle,
        style: 'chepterSubtitle'
      })
    }
    return section
  } catch(e){
    console.error(e)
    return []
  }
}

// 公司簡介 - 標題
export function returnDescriptionTitle():Array<any>{
  try {
    return returnSubjectTitleSection({
      title: '公司簡介',
      url: '',
      updateTime: ''
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 公司簡介
export function returnDescription(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const description = company.basic.multi.description && company.basic.multi.description[0]
      ? company.basic.multi.description[0].value
      : '-'
    return returnTextSection(description)
  } catch(e){
    console.error(e)
    return []
  }
}

// 商工登記 - 標題
export function returnBasicTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>): Array<any>{
  try {
    const allDate = companyList.map(d => d.basic.datetime_update)
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '商工登記資料',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.basic,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 商工登記
export function returnBasicTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    return returnTableSection({
      widths: [120, '*'],
      body: [
        [
          {
            text: '公司名稱',
            style: 'tableHeader'
          },
          company.basic.company_name
        ],
        [
          {
            text: '統一編號',
            style: 'tableHeader'
          },
          company.basic.uniID
        ],
        [
          {
            text: '公司狀況',
            style: 'tableHeader'
          },
          company.basic.company_state || '-'
        ],
        [
          {
            text: '代表人姓名',
            style: 'tableHeader'
          },
          company.basic.representative_name || '-'
        ],
        [
          {
            text: '資本總額(元)',
            style: 'tableHeader'
          },
          utils.formatCommaNumber(company.basic.capital_amount)
        ],
        [
          {
            text: '實收資本額(元)',
            style: 'tableHeader'
          },
          company.basic.paidup_capital_amount && company.basic.paidup_capital_amount >=0
            ? utils.formatCommaNumber(company.basic.paidup_capital_amount)
            : '-'
        ],
        [
          {
            text: '核准設立日期',
            style: 'tableHeader'
          },
          moment(company.basic.date_approved).format('YYYY-MM-DD')
        ],
        [
          {
            text: '最後核准變更日期',
            style: 'tableHeader'
          },
          moment(company.basic.date_approved_latest).format('YYYY-MM-DD')
        ],
        [
          {
            text: '登記機關',
            style: 'tableHeader'
          },
          company.basic.registration_department || '-'
        ],
        [
          {
            text: '登記地址',
            style: 'tableHeader'
          },
          company.basic.company_address || '-'
        ],
        [
          {
            text: '所營事業資料',
            style: 'tableHeader'
          },
          company.basic.business_matters || '-'
        ],
      ]
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 登記地址近似公司 - 標題
export function returnSimilarAddressTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d['登記地址近似公司'].stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '登記地址近似公司',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.登記地址近似公司,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 登記地址近似公司
export function returnSimilarAddress(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '序號',
        style: 'tableHeader'
      },
      {
        text: '近似公司',
        style: 'tableHeader'
      },
      {
        text: '登記地址',
        style: 'tableHeader'
      }
    ]
    let body = company.登記地址近似公司.list.map((d:any, i: number) => {
      return [
        i+1, // 序號
        d.company_name || '-', // 近似公司
        d.company_address || '-' // 登記地址
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [25, '*', '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 董監事資料 - 標題
export function returnShareholdersTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.shareholders.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '董監事資料',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.shareholders,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 董監事資料
export function returnShareholdersTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const stockType = company.basic.stock_type
    const title = [
      {
        text: '序號',
        style: 'tableHeader'
      },
      {
        text: '職稱',
        style: 'tableHeader'
      },
      {
        text: '姓名',
        style: 'tableHeader'
      },
      {
        text: '所代表法人',
        style: 'tableHeader'
      },
      {
        text: stockType ==='有限公司' ? '出資額(元)' : '持有股份數',
        style: 'tableHeader'
      }
    ]
    let body = company.shareholders.list.map((d:any) => {
      return [
        d.sno, // 序號
        d.title || '-', // 職稱
        d.name || '-', // 姓名
        d.ROL || '-', // 所代表法人
        stockType ==='有限公司' ? utils.formatCommaNumber(d.contribution) : utils.formatCommaNumber(d.stocks) // 持有股份數
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [25, 80, 60, '*', 100],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 經理人資料 - 標題
export function returnManagersTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.managers.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '經理人資料',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.managers,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 經理人資料
export function returnManagersTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '序號',
        style: 'tableHeader'
      },
      {
        text: '姓名',
        style: 'tableHeader'
      },
      {
        text: '到職日期',
        style: 'tableHeader'
      }
    ]
    let body = company.managers.list.map((d:any) => {
      return [
        d.sno, // 序號
        d.name || '-', // 姓名
        moment(d.arrival_date).format('YYYY-MM-DD'), // 到職日期
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [25, '*', 100],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 變更紀錄 - 標題
export function returnCompanysHistoryTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.companys_history.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '變更紀錄',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.companys_history,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 變更紀錄
export function returnCompanysHistoryTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '變更紀錄',
        style: 'tableHeader'
      },
      {
        text: '變更項目',
        style: 'tableHeader'
      },
      {
        text: '變更前',
        style: 'tableHeader'
      },
      {
        text: '變更後',
        style: 'tableHeader'
      }
    ]
    let body = company.companys_history.list.map((d:any) => {
      let oldText = ''
      let newText = ''
      const componentType = companysHistoryUtils.returnDetailComponent(d.col_name_en)
      try {
        // 依類別組出文字
        if (componentType === 'PeopleDetail') {
          let oldData:Array<companyInterfaces.ICompanyHistoryPeople> = JSON.parse(d.old)
          let newData:Array<companyInterfaces.ICompanyHistoryPeopleCompared> = JSON.parse(d.new)
          newData = companysHistoryUtils.parsePeopleDetail(newData, oldData)
          // 變更前
          oldData.forEach((d,i) => {
            if (d.name) {
              oldText += `姓名：${d.name}\n`
            }
            if (d.title) {
              oldText += `職稱：${d.title}\n`
            }
            if (d.contribution) {
              oldText += `持有股份數：${utils.formatCommaNumber(d.contribution)}\n`
            }
            if (d.stocks) {
              oldText += `持有股份數：${utils.formatCommaNumber(d.stocks)}\n`
            }
            if (d.ROL) {
              oldText += `所代表法人：${d.ROL}\n`
            }
            if (i < oldData.length - 1) {
              oldText += '----------------------------------------\n'
            }
          })
          // 變更後 @Q@ 暫不處理有變動時字體highlight
          newData.forEach((d,i) => {
            if (d.name) {
              newText += `姓名：${d.name}\n`
            }
            if (d.title) {
              newText += `職稱：${d.title}\n`
            }
            if (d.contribution) {
              newText += `持有股份數：${utils.formatCommaNumber(d.contribution)}\n`
            }
            if (d.stocks) {
              newText += `持有股份數：${utils.formatCommaNumber(d.stocks)}\n`
            }
            if (d.ROL) {
              newText += `所代表法人：${d.ROL}\n`
            }
            if (i < oldData.length - 1) {
              newText += '----------------------------------------\n'
            }
          })
        } else if (componentType === 'EtaxDetail') {
          let oldData:Array<companyInterfaces.ICompanyHistoryEtax> = JSON.parse(d.old)
          let newData:Array<companyInterfaces.ICompanyHistoryEtaxCompared> = JSON.parse(d.new)
          newData = companysHistoryUtils.parseEtaxDetail(newData, oldData)
          // 變更前
          oldData.forEach((d,i) => {
            if (d.code) {
              oldText += `稅藉編號：${d.code}\n`
            }
            if (d.title) {
              oldText += `稅藉內容：${d.title}\n`
            }
            if (i < oldData.length - 1) {
              oldText += '----------------------------------------\n'
            }
          })
          // 變更後 @Q@ 暫不處理有變動時字體highlight
          newData.forEach((d,i) => {
            if (d.code) {
              newText += `稅藉編號：${d.code}\n`
            }
            if (d.title) {
              newText += `稅藉內容：${d.title}\n`
            }
            if (i < oldData.length - 1) {
              newText += '----------------------------------------\n'
            }
          })
        } else if (componentType === 'DefaultDetail') {
          oldText = d.old
          newText = d.new
        }
      } catch (e) { console.error(e) }

      return [
        moment(d.datetime_update).format('YYYY-MM-DD'), // 變更紀錄
        d.col_name || '-', // 變更項目
        oldText || '-', // 變更前
        newText || '-' // 變更後
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [60, 80, '*', '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 分公司資料 - 標題
export function returnCompanyBranchsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.company_branchs.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '分公司資料',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.company_branchs,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 分公司資料
export function returnCompanyBranchsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '序號',
        style: 'tableHeader'
      },
      {
        text: '統一編號',
        style: 'tableHeader'
      },
      {
        text: '分公司名稱',
        style: 'tableHeader'
      },
      {
        text: '公司狀況',
        style: 'tableHeader'
      },
      {
        text: '分公司核准設立日期',
        style: 'tableHeader'
      },
      {
        text: '最後核准變更日期',
        style: 'tableHeader'
      }
    ]
    let body = company.company_branchs.list.map((d:any, i:number) => {
      return [
        i+1, // 序號
        d.uniID, // 統一編號
        d.company_name, // 分公司名稱
        d.company_state || '-', // 公司狀況
        moment(d.date_approved).format('YYYY-MM-DD'), // 分公司核准設立日期
        moment(d.date_approved_latest).format('YYYY-MM-DD') // 最後核准變更日期
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [25, 60, 100, '*', 80, 80],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 國貿局「出進口廠商管理系統」 - 標題
export function returnCompanyNameTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.trades.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '國貿局「出進口廠商管理系統」',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.trades,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 國貿局「出進口廠商管理系統」
export function returnCompanyNameTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    return returnTableSection({
      widths: [150, '*'],
      body: [
        [
          {
            text: '廠商中文名稱',
            style: 'tableHeader'
          },
          company.basic.company_name || '-'
        ],
        [
          {
            text: '廠商英文名稱',
            style: 'tableHeader'
          },
          company.basic.company_name_en_MOEA || '-'
        ]
      ]
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站 - 標題
export function returnCompanyStateTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.trades.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '財政部稅務入口網-營業(稅籍)登記資料公示查詢網站',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.trades,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站
export function returnCompanyStateTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    return returnTableSection({
      widths: [150, '*'],
      body: [
        [
          {
            text: '營業狀況',
            style: 'tableHeader'
          },
          company.basic.company_operate || '-'
        ],
      ]
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 工廠登記 - 標題
export function returnFactorysTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.factorys.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '工廠登記',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.factorys,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 工廠登記
export function returnFactorysTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '工廠登記',
        style: 'tableHeader'
      },
      {
        text: '工廠地址',
        style: 'tableHeader'
      },
      {
        text: '公司登記狀況',
        style: 'tableHeader'
      }
    ]
    let body = company.factorys.list.map((d:any, i:number) => {
      return [
        d.factory_name || '-', // 工廠登記
        d.factory_address || '-', // 工廠地址
        d.factory_state || '-', // 公司登記狀況
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [160, '*', 60],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 法律訴訟 - 標題
export function returnVerdictsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.verdicts.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '法律訴訟',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.verdicts,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 法律訴訟
export function returnVerdictsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '管轄法院',
        style: 'tableHeader'
      },
      {
        text: '判決類型',
        style: 'tableHeader'
      },
      {
        text: '判決日期',
        style: 'tableHeader'
      },
      {
        text: '判決字號',
        style: 'tableHeader'
      },
      {
        text: '案由',
        style: 'tableHeader'
      }
    ]
    let body = company.verdicts.list.map((d:any, i:number) => {
      return [
        d.court || '-', // 管轄法院
        d.type || '-', // 判決類型
        moment(d.date).format('YYYY-MM-DD'), // 判決日期
        d.unit_part || '-', // 判決字號
        d.title || '-' // 案由
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [120, 60, 60, 80, '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 新聞事件 - 標題
export function returnNewsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>, customKeywords: string[] = []):Array<any>{
  try {
    let allDate: string[] = []
    companyList.forEach(company => {
      company.news.list.forEach((d: any) => {
        if (d.datetime_pub) {
          allDate.push(d.datetime_pub)
        }
      })
    })
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '新聞事件',
      subtitle: customKeywords.length
        ? `* ${store.getters.getterFirstCustomKeywordTitle}：${customKeywords.join('、')}`
        : '',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.news,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 新聞事件
export function returnNewsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '日期',
        style: 'tableHeader'
      },
      {
        text: '標題',
        style: 'tableHeader'
      },
      {
        text: '來源',
        style: 'tableHeader'
      }
    ]
    let body = company.news.list.map((d:any, i:number) => {
      return [
        moment(d.datetime_pub).format('YYYY-MM-DD'), // 日期
        d.title || '-', // 標題
        d.author || '-', // 來源
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [60, '*', '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 勞資糾紛 - 標題
export function returnDisputesTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.disputes.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '勞資糾紛',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.disputes,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 勞資糾紛
export function returnDisputesTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '日期',
        style: 'tableHeader'
      },
      {
        text: '發文字號',
        style: 'tableHeader'
      },
      {
        text: '違反法規法條',
        style: 'tableHeader'
      },
      {
        text: '違法內容',
        style: 'tableHeader'
      }
    ]
    let body = company.disputes.list.map((d:any, i:number) => {
      return [
        moment(d.date_pub).format('YYYY-MM-DD'), // 日期
        d.sid || '-', // 發文字號
        d.regulation || '-',
        d.content || '-', // 違法內容
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [55, 110, 110, '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 拒絕往來廠商紀錄 - 標題
export function returnProcurementBadVendorsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.procurement_bad_vendors.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '拒絕往來廠商紀錄',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.procurement_bad_vendors,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 拒絕往來廠商紀錄
export function returnProcurementBadVendorsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '刊登機關名稱',
        style: 'tableHeader'
      },
      {
        text: '標案名稱',
        style: 'tableHeader'
      },
      {
        text: '符合政府採購法第101條所列之情形',
        style: 'tableHeader'
      },
      {
        text: '拒絕往來生效日',
        style: 'tableHeader'
      },
      {
        text: '拒絕往來截止日',
        style: 'tableHeader'
      },
      {
        text: '異議或申訴結果',
        style: 'tableHeader'
      }
    ]
    let body = company.procurement_bad_vendors.list.map((d:any, i:number) => {
      return [
        d.org_name || '-', // 刊登機關名稱
        d.bid_name || '-', // 標案名稱
        d.base_law || '-', // 符合政府採購法第101條所列之情形
        moment(d.date_start).format('YYYY-MM-DD'), // 拒絕往來生效日
        moment(d.date_end).format('YYYY-MM-DD'), // 拒絕往來截止日
        d.appeal || '-', // 異議或申訴結果
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [70, 60, '*', 60, 60, 80],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 公平交易委員會行政決定 - 標題
export function returnDataGovDecisionTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.data_gov_decision.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '公平交易委員會行政決定',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.data_gov_decision,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 公平交易委員會行政決定
export function returnDataGovDecisionTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '公告/處分日期',
        style: 'tableHeader'
      },
      {
        text: '違反事由',
        style: 'tableHeader'
      },
      {
        text: '處分書',
        style: 'tableHeader'
      },
      {
        text: '備註',
        style: 'tableHeader'
      },
    ]
    let body = company.data_gov_decision.list.map((d:any, i:number) => {
      return [
        d.date || '-',
        d.title || '-',
        d.file || '-',
        d.note || '-'
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [70, '*', 120, 60],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 環保署裁罰處分 - 標題
export function returnDataGovEnvironmentalPunishmentTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.data_gov_environmental_punishment.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '環保署裁罰處分',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.data_gov_environmental_punishment,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 環保署裁罰處分
export function returnDataGovEnvironmentalPunishmentTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '裁處時間',
        style: 'tableHeader'
      },
      {
        text: '違反事由',
        style: 'tableHeader'
      },
      {
        text: '處分內容',
        style: 'tableHeader'
      },
      {
        text: '違反法令',
        style: 'tableHeader'
      },
      {
        text: '備註',
        style: 'tableHeader'
      },
    ]
    let body = company.data_gov_environmental_punishment.list.map((d:any, i:number) => {
      return [
        d.date || '-',
        d.fact || '-',
        d.fine ? `裁處金額-${d.fine}元` : '-',
        d.law || '-',
        d.note || '-',
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [60, '*', '*', '*', 60],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 列管汙染源資料裁處資訊 - 標題
export function returnEpaTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.epa.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '列管汙染源資料裁處資訊',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.epa,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 列管汙染源資料裁處資訊
export function returnEpaTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '公告/處分日期',
        style: 'tableHeader'
      },
      {
        text: '字號',
        style: 'tableHeader'
      },
      {
        text: '違反事由',
        style: 'tableHeader'
      },
      {
        text: '處分內容',
        style: 'tableHeader'
      },
      {
        text: '備註',
        style: 'tableHeader'
      },
    ]
    let body = company.epa.list.map((d:any, i:number) => {
      return [
        d.date || '-',
        d.JJID || '-',
        d.reason || '-',
        d.subject || '-',
        d.note || '-',
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [70, 75, '*', '*', 60],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 進出口實績 - 標題
export function returnTradesTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.trades.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '進出口實績',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.trades,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 進出口實績
export function returnTradesTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '年度',
        style: 'tableHeader'
      },
      {
        text: '總進口實績',
        style: 'tableHeader'
      },
      {
        text: '總出口實績',
        style: 'tableHeader'
      }
    ]
    let body = company.trades.list.map((d:any, i:number) => {
      return [
        d.year, // 年度
        (codes.IMPORT_AND_EXPORT_DATA as any)[d.import].label || '-', // 總進口實績
        (codes.IMPORT_AND_EXPORT_DATA as any)[d.export].label || '-', // 總出口實績
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [80, '*', '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 進出口實績圖表
export async function returnTradesChart(selector: any) {
  try {
    // 截取圖表轉base64
    const base64 = await screenImageGetter.getScreenBase64(selector)
    return returnChartSection(base64)
  } catch(e){
    console.error(e)
    return []
  }
}

// 政府標案 - 標題
export function returnProcurementsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.procurements_award.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '政府標案',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.procurements,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 政府標案
export function returnProcurementsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '決標年度',
        style: 'tableHeader'
      },
      {
        text: '得標案件',
        style: 'tableHeader'
      },
      {
        text: '得標金額',
        style: 'tableHeader'
      }
    ]
    let _body = company.procurements_award.list.map((d:any, i:number) => {
      if (!d._suggest) {
        return ['','','']
      }
      return [
        moment(d._suggest.決標日期).format('YYYY-MM'), // 決標年度
        d._suggest.得標標案 || '-', // 得標案件
        utils.formatCommaNumber(d._suggest.決標金額), // 得標金額
      ]
    })
    if (!_body.length) {
      _body = [['-', '-', '-']]
    }
    return returnTableSection({
      widths: [80, '*', 100],
      body: [title].concat(_body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 人力招聘 - 標題
export function returnJobsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.job104s_jobs.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '人力招聘',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.jobs,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 人力招聘
export function returnJobsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '發布日期',
        style: 'tableHeader'
      },
      {
        text: '來源網站',
        style: 'tableHeader'
      },
      {
        text: '需求人數',
        style: 'tableHeader'
      },
      {
        text: '原文連結',
        style: 'tableHeader'
      }
    ]
    let body = [[
      company.job104s_jobs.stat['max-datetime_update']
        ? moment(company.job104s_jobs.stat['max-datetime_update']).format('YYYY-MM-DD')
        : '-',
      '104人力銀行',
      company.job104s_jobs.list.length,
      (company.basic.jobs
        && company.basic.jobs.job104s
        && company.basic.jobs.job104s.uniID)
        ? `http://www.104.com.tw/company/${company.basic.jobs.job104s.uniID}`
        : ''
    ]]
    if (!body.length) {
      body = [['-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [60, 80, 60, '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 政府標案圖表
export async function returnProcurementsChart(selector: any) {
  try {
    // 截取圖表轉base64
    const base64 = await screenImageGetter.getScreenBase64(selector)
    return returnChartSection(base64)
  } catch(e){
    console.error(e)
    return []
  }
}

// 專利資訊 - 標題
export function returnPatentsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.patent_rights.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '專利資訊',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.patents,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 專利資訊
export function returnPatentsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '專利名稱',
        style: 'tableHeader'
      },
      {
        text: '發布地區',
        style: 'tableHeader'
      },
      {
        text: '發布日期',
        style: 'tableHeader'
      },
      {
        text: '申請號',
        style: 'tableHeader'
      },
      {
        text: '公告/公開號',
        style: 'tableHeader'
      }
    ]
    let body = company.patent_rights.list.map((d:any, i:number) => {
      return [
        d.patent_name_chinese, // 專利名稱
        // @ts-ignore
        codes.PATENTS_AREA_DIST[d.area_pub] || '-', // 發布地區
        moment(d.publish_date).format('YYYY-MM-DD'), // 發布日期
        d.appl_no || '-', // 申請號
        d.publish_no || '-' // 公告/公開號
      ]
    })
    if (!body.length) {
      body = [['-', '-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: ['*', 40, 60, 60, 70],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 得獎紀錄與政府推薦名單 - 標題
export function returnAwardsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.awards.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '得獎紀錄與政府推薦名單',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.awards,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 得獎紀錄與政府推薦名單
export function returnAwardsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    // const title = ['得獎名稱', '得獎年份']
    const title = [
      {
        text: '得獎名稱',
        style: 'tableHeader'
      }
    ]
    let body = company.awards.list.map((d:any, i:number) => {
      return [
        d.award || '-', // 得獎名稱
        // '@Q@ api無此資料'
      ]
    })
    if (!body.length) {
      body = [['-']]
    }
    return returnTableSection({
      // widths: ['*', 120],
      widths: ['*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 公司近期發布之重大訊息 - 標題
export function returnPublicsNewsTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.publics_news.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '公司近期發布之重大訊息',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.publics_news,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 公司近期發布之重大訊息
export function returnPublicsNewsTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    // const title = ['得獎名稱', '得獎年份']
    const title = [
      {
        text: '日期',
        style: 'tableHeader'
      },
      {
        text: '主旨',
        style: 'tableHeader'
      }
    ]
    let body = company.publics_news.list.map((d:any, i:number) => {
      return [
        d.date_pub || '-', // 日期
        d.title || '-', // 主旨
      ]
    })
    if (!body.length) {
      body = [['-', '-']]
    }
    return returnTableSection({
      widths: [60, '*'],
      body: [title].concat(body)
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 營收資訊 - 標題
export function returnRevenueHistoryTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.revenue_history.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '營收資訊',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.revenue_history,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 營收資訊
export function returnRevenueHistoryTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    const title = [
      {
        text: '年度',
        style: 'tableHeader'
      },
      {
        text: '營收',
        style: 'tableHeader'
      },
      {
        text: '去年同期累計(月)營收',
        style: 'tableHeader'
      },
      {
        text: '去年同期累計(月)營收成長率',
        style: 'tableHeader'
      }
    ]
    let body = company.revenue_history.list.map((d:any, i:number) => {
      return [
        moment(d.year).format('YYYY年MM月營收'), // 年度
        utils.formatCommaNumber(d.value_this_year), // 營收
        utils.formatCommaNumber(d.value_last_year), // 去年同期累計(月)營收
        d.rate_growth || '-', // 去年同期累計(月)營收成長率
      ]
    })
    // let sumThisYear = body.reduce((accumulator:number, current:any) => {
    //   return accumulator + current.value_this_year
    // }, 0)
    // sumThisYear = utils.formatCommaNumber(sumThisYear)
    // let sumLastYear = body.reduce((accumulator:number, current:any) => {
    //   return accumulator + current.value_last_year
    // }, 0)
    // sumLastYear = utils.formatCommaNumber(sumLastYear)
    // let sumRate = ''
    // let _rate = sumThisYear / sumLastYear
    // if (isNaN(_rate) === false) {
    //   sumRate = (_rate * 100).toFixed(2) + '%'
    // }
    if (company.basic.publics && company.basic.publics.revenue_str) {
      // 累計營收資料
      const json = JSON.parse(company.basic.publics.revenue_str)
      if (json && json.total) {
        body.push([
          '今年迄今累計營收',
          json.total.this_year,
          json.total.last_year,
          json.total.rate_growth
        ])
      }
    }
    if (!body.length) {
      body = [['-', '-', '-', '-']]
    }
    return returnTableSection({
      widths: [100, 100, '*', '*'],
      body: [title]
        .concat(body)
        // .concat([['今年迄今累計營收', sumThisYear, sumLastYear, sumRate]])
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 營收資訊圖表
export async function returnRevenueHistoryChart(selector: any) {
  try {
    // 截取圖表轉base64
    const base64 = await screenImageGetter.getScreenBase64(selector)
    return returnChartSection(base64)
  } catch(e){
    console.error(e)
    return []
  }
}

// 採IFRSs後財報資訊 - 標題
export function returnIfrssSummaryTableTitle(companyList:Array<apiInterfaces.ICompanyGetResponsePayload>):Array<any>{
  try {
    const allDate = companyList.map(d => d.ifrss_summary.stat['max-datetime_update'])
    const maxDate = findMaxDate(allDate)
    return returnSubjectTitleSection({
      title: '採IFRSs後財報資訊',
      url: codes.COMPANY_DETAIL_SOURCE_DICT.ifrss_summary,
      updateTime: maxDate ? moment(maxDate).format('YYYY-MM-DD hh:mm') : '-'
    })
  } catch(e){
    console.error(e)
    return []
  }
}

// 採IFRSs後財報資訊
export function returnIfrssSummaryTable(company:apiInterfaces.ICompanyGetResponsePayload):Array<any>{
  try {
    let tableData:any = {}, title1 = '', title2 = '', title3 = '', title4 = ''
      , row1: {[key:string]:string} = {}, row2: {[key:string]:string} = {}, row3: {[key:string]:string} = {}, row4: {[key:string]:string} = {}, row5: {[key:string]:string} = {}, row6: {[key:string]:string} = {}, row7: {[key:string]:string} = {}, row8: {[key:string]:string} = {}, row9: {[key:string]:string} = {}, row10: {[key:string]:string} = {}, row11: {[key:string]:string} = {}
    if (company.ifrss_summary.list[0] && company.ifrss_summary.list[0].view) {
      tableData = JSON.parse(company.ifrss_summary.list[0].view)
      title1 = tableData.col_order[0]
      title2 = tableData.col_order[1]
      title3 = tableData.col_order[2]
      title4 = tableData.col_order[3]
      row1 = tableData['資產總計']
      row2 = tableData['負債總計']
      row3 = tableData['權益總計']
      row4 = tableData['每股淨值(元)']
      row5 = tableData['營業收入']
      row6 = tableData['營業利益(損失)']
      row7 = tableData['稅前淨利(淨損)']
      row8 = tableData['基本每股盈餘(元)']
      row9 = tableData['營業活動之淨現金流入(流出)']
      row10 = tableData['投資活動之淨現金流入(流出)']
      row11 = tableData['籌資活動之淨現金流入(流出)']
    }
    const body: any[] = [
      [
        {text: '期別', colSpan: 2, style: 'tableHeader'},
        {},
        title1 || '-',
        title2 || '-',
        title3 || '-',
        title4 || '-'
      ],
      [
        {text: '簡明負債資產', rowSpan: 4, style: 'tableHeader'},
        {text:'資產總計', style: 'tableHeader'},
        row1[title1] || '-',
        row1[title2] || '-',
        row1[title3] || '-',
        row1[title4] || '-'
      ],
      [
        {},
        {text:'負債總計', style: 'tableHeader'},
        row2[title1] || '-',
        row2[title2] || '-',
        row2[title3] || '-',
        row2[title4] || '-'
      ],
      [
        {},
        {text:'權益總計', style: 'tableHeader'},
        row3[title1] || '-',
        row3[title2] || '-',
        row3[title3] || '-',
        row3[title4] || '-'
      ],
      [
        {},
        {text:'每股淨值(元)', style: 'tableHeader'},
        row4[title1] || '-',
        row4[title2] || '-',
        row4[title3] || '-',
        row4[title4] || '-'
      ],
      [
        {text: '簡明綜合損益表', rowSpan: 4, style: 'tableHeader'},
        {text:'營業收入', style: 'tableHeader'},
        row5[title1] || '-',
        row5[title2] || '-',
        row5[title3] || '-',
        row5[title4] || '-'
      ],
      [
        {},
        {text:'營業利益(損失)', style: 'tableHeader'},
        row6[title1] || '-',
        row6[title2] || '-',
        row6[title3] || '-',
        row6[title4] || '-'
      ],
      [
        {},
        {text:'稅前淨利(淨損)', style: 'tableHeader'},
        row7[title1] || '-',
        row7[title2] || '-',
        row7[title3] || '-',
        row7[title4] || '-'
      ],
      [
        {},
        {text:'基本每股盈餘(元)', style: 'tableHeader'},
        row8[title1] || '-',
        row8[title2] || '-',
        row8[title3] || '-',
        row8[title4] || '-'
      ],
      [
        {text: '簡明現金流量表', rowSpan: 3, style: 'tableHeader'},
        {text:'營業活動之淨現金流入(流出)', style: 'tableHeader'},
        row9[title1] || '-',
        row9[title2] || '-',
        row9[title3] || '-',
        row9[title4] || '-'
      ],
      [
        {},
        {text:'投資活動之淨現金流入(流出)', style: 'tableHeader'},
        row10[title1] || '-',
        row10[title2] || '-',
        row10[title3] || '-',
        row10[title4] || '-'
      ],
      [
        {},
        {text:'籌資活動之淨現金流入(流出)', style: 'tableHeader'},
        row11[title1] || '-',
        row11[title2] || '-',
        row11[title3] || '-',
        row11[title4] || '-'
      ],
    ]
    return returnTableSection({
      widths: [60, 100, '*', '*', '*', '*'],
      body
    })
  } catch(e){
    console.error(e)
    return []
  }
}

