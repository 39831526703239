





















import { defineComponent, ref, computed, Ref, ComputedRef, SetupContext } from '@vue/composition-api'

type Props = {
  options: Array<{
    label: string;
    value: string;
  }>,
  value: string;
}

type ChildOption = {
  label: string;
  value: string;
  hover: boolean;
  selected: boolean
}

export default defineComponent({
  props: {
    options: {
      type: Array
    },
    value: {
      type: String
    }
  },
  setup (props: Props, { emit }: SetupContext) {
    const selectedIndex = computed(() => {
      return props.options.map(d => d.value).indexOf(props.value)
    })
    const hoverIndex = ref(-1)
    const childOption = computed(() => {
      return props.options.map((d, i) => {
        return {
          ...d,
          hover: i == hoverIndex.value ? true : false,
          selected: i == selectedIndex.value ? true : false
        }
      })
    })
    
    return {
      childOption,
      handleItemMouseover (index: number) {
        hoverIndex.value = index
      },
      handleItemMouseout () {
        hoverIndex.value = -1
      },
      handleItemClick (index: number) {
        emit('input', childOption.value[index].value)
        emit('change', childOption.value[index].value)
      }
    }
  },
})
