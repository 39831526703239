import moment from 'moment'

export default {
  insert (keyword, routeData) {
    if (!routeData) {
      return
    }
    let recentQuery = localStorage.getItem('bp__dbs__recentQuery')
    if (!recentQuery) {
      recentQuery = []
    } else {
      recentQuery = JSON.parse(recentQuery)
    }
    recentQuery = recentQuery.filter(d => d.keyword !== keyword)
    recentQuery.unshift({
      keyword,
      date: moment().format('YYYY-MM-DD HH:mm:ss'),
      name: routeData.name,
      path: routeData.path,
      params: JSON.parse(JSON.stringify(routeData.params)),
      query: JSON.parse(JSON.stringify(routeData.query))
    })
    if (recentQuery.length > 10) {
      recentQuery = recentQuery.slice(0, 10)
    }
    localStorage.setItem('bp__dbs__recentQuery', JSON.stringify(recentQuery))
  },
  getAll () {
    let str = localStorage.getItem('bp__dbs__recentQuery')
    if (str) {
      return JSON.parse(str)
    } else {
      return []
    }
  },
}