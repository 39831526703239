
















































































































































import { defineComponent, SetupContext, ref, computed, Ref, ComputedRef, watch, onMounted } from '@vue/composition-api'
import ChartCard from '@/components/charts/ChartCard.vue'
import TreeChartDrawer, { DrawerData } from './TreeChartDrawer.vue'
import Tree2ChartContainer from './Tree2ChartContainer.vue'
import LineChartContainer, { defaultSortValue } from './LineChartContainer.vue'
import moment from 'moment'
import getNodeOffset from './getNodeOffset'
import { chartScoutRoute } from '@bpchart/d3-modules'
import { formatPercentage, formatNumberWithComma } from '@/utils/text'
import * as screenImageGetter from '@/utils/screenImageGetter'
// import { getRouterCount } from '@/utils/parseGoData'
import { getSVGGSize, getElementSize, getTransform } from '@/utils/element'
// import { htmlToImageBuffer } from '@/apis'
import createChartHtml from '@/utils/createChartHtml'
import { filterOptions, defaultFilterValue, createTreeChartFilter, createLineChartFilter, FilterConfig } from './filterOptions'
import downloadCompanyRelationshipExcel from '@/utils/downloadCompanyRelationshipExcel'
import AppSelectedList from '@/components/app/AppSelectList.vue'

import * as d3 from 'd3'

declare module $nuxt {
  const $store: any
}

type Props = {
  cardId: string;
  uniID: string;
  companyName: string;
  options: {
    stockMin: number;
  };
  chartData: chartScoutRoute.ChartScoutRouteTreeDataset;
  height: number;
}

type LineChart = {
  // tab: number;
  label: string;
  uniID: string;
  // params: chartScoutRoute.ChartScoutRouteLinesParams;
  chartData: chartScoutRoute.ChartScoutRouteLinesDataset;
  description: string;
  // update: chartScoutRoute.ChartScoutRouteLinesUpdateConfig;
  autoZoom: boolean;
  // filter: FilterConfig;
  sortValue: '-1' | '1'; // 排序選單
  filterValue: string; // 篩選選單
  // height: string;
  // GResizeObserver: ResizeObserver | undefined;
}


const tabHeight = 56
const footerHeight = 22

export default defineComponent({
  props: {
    cardId: {
      default () {
        return 'companyStockTree2Chart'
      }
    },
    'uniID': {
      type: String,
      required: true
    },
    'companyName': {
      type: String,
      required: true
    },
    options: {
      default () {
        return {
          stockMin: 0,
          // expandAll: false, // 展開所有節點
          // mergeName: false, // 合併相同姓名
          // direction: 'RL'
        }
      }
    },
    chartData: {
      type: Object as () => chartScoutRoute.ChartScoutRouteTreeDataset,
      default () {
        return {
          nodes: [],
          edges: [],
          rootId: ''
        }
      }
    },
    height: {
      default () {
        return 800
      }
    }
  },
  components: {
    ChartCard,
    Tree2ChartContainer,
    LineChartContainer,
    TreeChartDrawer,
    AppSelectedList
  },
  setup (props: Props, { root }: SetupContext) {
    
    // -- chartCard --
    // const cardId = 'companyStockTree2Chart'
    const cardTitle = computed(() => `【${props.companyName}】股權結構分析圖`)
    const headerHeight = 40
    const cardWidth: Ref<number | string> = ref(0)
    const cardHeight: Ref<number | string> = ref(0)
    const screenShotMode: Ref<boolean> = ref(false)
    const isFullScreen: Ref<boolean> = ref(false)
    const isDownloading: Ref<boolean> = ref(false)
    const outerContainerSize: ComputedRef<any> = computed(() => {
      if (isFullScreen.value) {
        return {
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: '0px',
          left: '0px',
          'z-index': 2000
        }
      } else {
        return {
          position: 'relative',
          width: '100%',
          height: `${props.height}px`
        }
      }
    })
    const cardSizeState: ComputedRef<{ isFullScreen: boolean; screenShotMode: boolean }> = computed(() => {
      return {
        isFullScreen: isFullScreen.value,
        screenShotMode: screenShotMode.value
      }
    })
    watch(cardSizeState, value => {
      if (cardSizeState.value.screenShotMode === true && document) {
        let width = 0
        let height = 0
        let gSelector: any = null
        if (currentTab.value === props.uniID) {
          gSelector = document.querySelector(`#${props.cardId} g.chart-scout-route__group`)
          // 取得寬高
          const screenShotSize = getSVGGSize(gSelector)
          width = screenShotSize.width + 140 // 圖表寬高加上卡片間距
          height = screenShotSize.height + headerHeight + tabHeight + footerHeight + 150 // 圖表寬高加上卡片間距
        } else {
          gSelector = document.querySelectorAll(`#${props.cardId} g.chart-scout-route__group`)[1]
          // 取得寬高
          const gShotSize = getSVGGSize(gSelector)
          const chartShotSize = getElementSize(document.querySelector(`#${props.cardId}`))
          width = chartShotSize.width
          height = gShotSize.height + headerHeight + tabHeight + footerHeight + 150 // 圖表寬高加上卡片間距
        }
        
        if (width < 640) {
          width = 640
        }
        if (height < 480) {
          height = 480
        }
        cardWidth.value = width
        cardHeight.value = height
      } else if (cardSizeState.value.isFullScreen === true) {
        cardWidth.value = '100vw'
        cardHeight.value = '100vh'
      } else {
        cardWidth.value = '100%'
        cardHeight.value = `${props.height}px`
      }
    }, { immediate: true })
    // -- tab --
    const currentTab: Ref<string> = ref(props.uniID)
    let lastTab = props.uniID // 前一次點選的 tab
    // -- chartCardDrawer --
    const drawerVisible: Ref<boolean> = ref(false)
    const drawerData: Ref<DrawerData | undefined> = ref(undefined)
    // -- tree chart --
    const treeFilterSelectValue = ref(defaultFilterValue)
    const treeAutoZoom: Ref<boolean> = ref(false) // 螢幕變換尺寸時 zoom還原預設（用於縮放全螢幕時）
    const treeDescription: Ref<string> = ref('')
    watch(props, () => {
      let title = ''
      let beneficialArr = []
      let beneficialText = ''
      if (props.chartData.nodes) {
        beneficialArr = props.chartData.nodes.filter(d => {
          return d.role === '自然人' && d['total-investment-ratio']! >= 25
        })
        let beneficialTextArr = beneficialArr.map(d => {
          return `${d.name}（持股${formatPercentage(d['total-investment-ratio']!)}）`
        })
        beneficialText = beneficialTextArr.join('、')
      }
      // title = `【${props.companyName}】根據公開資料計算，有 ${beneficialArr.length} 位實質受益人`
      // if (beneficialArr.length) {
      //   title += `，分別為 ${beneficialText}。`
      // } else {
      //   title += '。'
      // }
      treeDescription.value = title
    }, { immediate: true })

    // -- line chart --
    const lineChartArr: Ref<Array<LineChart>> = ref([])
    const lineAutoZoom: Ref<boolean> = ref(false) // 螢幕變換尺寸時 zoom還原預設（用於縮放全螢幕時）
    const lineDescription: Ref<string> = ref('')
    
    // -- download --
    const downloadCsv = () => {
      downloadCompanyRelationshipExcel(props.chartData, props.uniID, props.companyName)
    }
    // 由前端截圖（舊的做法）
    const download = async () => {
      
      let fileTitle = ''
      if (currentTab.value == props.uniID) {
        // fileTitle = `【${props.companyName}】股權結構分析圖v2_${moment().format('YYYY_MM_DD_HH_mm')}`
        fileTitle = `【${props.companyName}】股權結構分析圖`
        // treeAutoZoom.value = true // 圖表回復初始位置

        // -- 置中 --
        const chart: any = document.querySelector(`#${props.cardId} #chart-${currentTab.value} svg`)!
        // const newChart: any = chart.cloneNode(true)
        const g = chart.querySelector('g')
        // const newChartG = newChart.querySelector('g')
        let { width, height, x, y } = getElementSize(g)
        const transform = getTransform(g) ?? {}
        const scale: number | string = transform.scale ?? 1
        width = width / Number(scale)
        height = height / Number(scale)
        let translateX = 0
        let translateY = 0
        if (currentTab.value == props.uniID) {
          const nodes = d3.select(chart).selectAll('.nodes__NODE_g')
          const rootNode = nodes.filter((d) => (d as chartScoutRoute.TreeFullNodeDatum).uniID === props.uniID)
          const { left, top } = (rootNode.node() as HTMLElement).getBoundingClientRect()
          translateX = (left / Number(scale)) - (x / Number(scale)) + 40
          translateY = (top / Number(scale)) - (y / Number(scale)) + 20 + 40
        }
        g.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(1)`)
        // g.setAttribute('width', width + 80)
        // g.setAttribute('height', height + 60)
      } else {
        // fileTitle = `【${props.companyName}】股權結構分析圖v2個別路徑圖_${moment().format('YYYY_MM_DD_HH_mm')}`
        fileTitle = `【${props.companyName}】股權結構分析圖`
        lineAutoZoom.value = true // 圖表回復初始位置
      }
      const originDrawerVisible = drawerVisible.value
      drawerVisible.value = false
      screenShotMode.value = true

      await new Promise(resolve => setTimeout(resolve, 500))
      await screenImageGetter.downloadScreenImage(
        `#${props.cardId}`,
        fileTitle,
        '#app'
      )

      screenShotMode.value = false
      drawerVisible.value = originDrawerVisible
      treeAutoZoom.value = true // 回復原位置
      setTimeout(() => {
        treeAutoZoom.value = false
        lineAutoZoom.value = false
      }, 800)
    }

    return {
      formatNumberWithComma,
      tabHeight,
      cardId: props.cardId,
      cardTitle,
      cardWidth,
      cardHeight,
      currentTab,
      headerHeight,
      screenShotMode,
      isFullScreen,
      outerContainerSize,
      isDownloading,
      treeAutoZoom,
      treeFilterSelectValue,
      treeDescription,
      lineChartArr,
      lineAutoZoom,
      lineDescription,
      drawerVisible,
      drawerData,
      async openLineChart (id: string) {
        const findTabIndex = lineChartArr.value.findIndex(d => d.uniID == id)
        if (findTabIndex >= 0) {
          lineChartArr.value.splice(findTabIndex, 1)
          await new Promise((resolve) => setTimeout(resolve))
        }
        
        const lineChartDate: LineChart = {
          label: drawerData.value!.label,
          uniID: id,
          description: `${drawerData.value!.label}至${props.companyName}之間，共 ${drawerData.value!.routesNumber}條路徑，總持股 ${drawerData.value!.totalInvestmentRatio}`,
          chartData: {
            nodes: props.chartData.nodes,
            rootId: props.uniID,
            targetId: id,
            mainRelatedToRoot: drawerData.value!.mainRelatedToRoot,
            sort: {
              'routes-of-stock-detail-single-value': -1,
              'routes_to_downs-of-stock-detail-single-value': -1
            }
          },
          autoZoom: lineAutoZoom.value,
          sortValue: defaultSortValue,
          filterValue: treeFilterSelectValue.value,
        }
        
        // 新增 tab
        lineChartArr.value.push(lineChartDate)
                
        currentTab.value = id
      },
      handleTabClick (d: any) {
        console.log(d)
        lastTab = currentTab.value // 紀錄前一次點選的 tab
        // currentTab.value = Number(d.name)
        currentTab.value = d.name
      },
      handleTabRemove (name: string) {
        // const index = lineChartArr.value.map(d => d.tab).indexOf(name)
        console.log(name)
        // const index = Number(name)
        const index = lineChartArr.value.map(d => d.uniID).indexOf(name)
        lineChartArr.value.splice(index, 1)
        
        // 如果移除的 tab是目前顯示的 tab，則將顯示 tab換成前一次顯示的 tab
        if (name === currentTab.value) {
          if (lineChartArr.value.find(d => d.uniID === lastTab)) {
            currentTab.value = lastTab
          } else {
            currentTab.value = props.uniID // 如前一次的 tab也不存在了則換成第一個 tab
          }
        }
      },
      handleDownloadClick (value: string) {
        if (value == 'jpeg') {
          download()
        } else if (value == 'csv') {
          downloadCsv()
        }
      },
      // async download () {
      //   isDownloading.value = true
      //   let fileTitle = ''
      //   let description = ''
      //   if (currentTab.value == props.uniID) {
      //     fileTitle = `【${props.companyName}】股權結構分析圖v2_${moment().format('YYYY_MM_DD_HH_mm')}`          
      //     description = treeDescription.value
      //   } else {
      //     fileTitle = `【${props.companyName}】股權結構分析圖v2個別路徑圖_${moment().format('YYYY_MM_DD_HH_mm')}`          
      //     const lineChart = lineChartArr.value.find(d => d.uniID === currentTab.value)
      //     description = lineChart ? lineChart.description : ''
      //   }
      //   const html = createChartHtml({
      //     chart: document.querySelector(`#${cardId} #chart-${currentTab.value} svg`)!,
      //     title: cardTitle,
      //     description,
      //     headerHeight: tabHeight,
      //     setChart: (chart, newChart) => {
      //       // 尺寸
      //       const g = chart.querySelector('g')
      //       const newChartG = newChart.querySelector('g')
      //       let { width, height, x, y } = getElementSize(g)
      //       const transform = getTransform(g) ?? {}
      //       const scale: number | string = transform.scale ?? 1
      //       width = width / Number(scale)
      //       height = height / Number(scale)
      //       let translateX = 0
      //       let translateY = 0
      //       if (currentTab.value == props.uniID) {
      //         const nodes = d3.select(chart).selectAll('.nodes__NODE_g')
      //         const rootNode = nodes.filter((d) => (d as chartScoutRoute.TreeFullNodeDatum).uniID === props.uniID)
      //         const { left, top } = (rootNode.node() as HTMLElement).getBoundingClientRect()
      //         translateX = (left / Number(scale)) - (x / Number(scale)) + 40
      //         translateY = (top / Number(scale)) - (y / Number(scale)) + 20 + 40
      //       }
      //       newChartG.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(1)`)
      //       newChart.setAttribute('width', width + 80)
      //       newChart.setAttribute('height', height + 60)
      //     }
      //   })
      //   const b64Data = await htmlToImageBuffer({
      //     html
      //   })
      //   const href = `data:image/png;base64,${b64Data}`
      //   const link = document.createElement('a');
      //   document.body.appendChild(link);
      //   link.href = href
      //   link.download = fileTitle
      //   link.click()
      //   isDownloading.value = false
      // },
      toggleFullScreen (toggle: boolean) {
        if (toggle) {
          isFullScreen.value = true
          $nuxt.$store.commit('mutationIsBlurboxActive', false) // 切換是否啟用背景（因backdrop-filter會影響到position:fixed）
        } else {
          isFullScreen.value = false
          $nuxt.$store.commit('mutationIsBlurboxActive', true) // 切換是否啟用背景（因backdrop-filter會影響到position:fixed）
        }
      },
    }
  }
})
