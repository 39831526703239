<template>
  <el-dialog :visible.sync="currentVisible" width="70%" @opened="onOpenDialog">
    <div slot="title" class="selectorTitle">
      <h4>選擇下載項目</h4>
      <p>
        <el-button type="primary" @click="onDownload">下載檔案</el-button>
      </p>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="基本資料" v-if="tabs.basic" name="basic">
        <p><el-checkbox :indeterminate="checkSome.basic" v-model="checkAll.basic" @change="handleCheckAll($event, 'basic')">選取全部</el-checkbox></p>
        <el-checkbox-group v-model="checked.basic" @change="handleChecked($event, 'basic')">
          <el-checkbox v-for="(item,i) in items.basic" :label="item.label" :key="'basic'+i">{{item.text}}</el-checkbox>
        </el-checkbox-group>
      </el-tab-pane>
      <el-tab-pane label="新聞事件" v-if="tabs.news" name="news">
        <p><el-checkbox v-model="checked.news">新聞事件</el-checkbox></p>
        <div v-show="checked.news">
          <p class="connect">
            起始時間 <el-date-picker size="mini" v-model="newsPeriod.start" type="date" placeholder="請選擇起始時間" @change="onChangeNewsRange" :picker-options="pickerOptions"></el-date-picker>
            結束時間 <el-date-picker size="mini" v-model="newsPeriod.end" type="date" placeholder="請選擇結束時間" @change="onChangeNewsRange" :picker-options="pickerOptions"></el-date-picker>
            <el-checkbox
              style="margin-left:15px"
              @change="onChangeNewsCustom"
              v-model="newsPeriod.custom">
              {{getterFirstCustomKeywordTitle}}
            </el-checkbox>
          </p>
          <el-table
            ref="elTableNews"
            v-loading="newsLoading"
            :data="newsData.list"
            @select="handleSelectNews"
            @select-all="handleSelectAllNews"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column property="title" label="新聞標題"></el-table-column>
            <el-table-column property="media" label="新聞來源" width="150"></el-table-column>
            <el-table-column property="date" label="新聞日期" width="100"></el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="newsPageCount"
            :current-page.sync="newsQuery.get.news['page-index']"
            @current-change="onChangeNewsPage">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="法律訴訟" v-if="tabs.verdicts" name="verdicts">
        <p><el-checkbox v-model="checked.verdicts">法律訴訟</el-checkbox></p>
        <div v-show="checked.verdicts">
          <p class="connect">
            審判法院
            <el-select
              placeholder="請選擇審判法院"
              value-key="key"
              size="mini"
              v-model="verdictFilter.court"
              @change="onChangeVerdict($event, 'court')"
              clearable
            >
              <el-option
                v-for="court in verdictData.cls.court"
                :key="court.key"
                :label="court.key"
                :value="court">
              </el-option>
            </el-select>
            案件類型
            <el-select
              placeholder="請選擇案件類型"
              value-key="key"
              size="mini"
              v-model="verdictFilter.JType"
              @change="onChangeVerdict($event, 'JType')"
              clearable
            >
              <el-option
                v-for="type in verdictData.cls.JType"
                :key="type.key"
                :label="type.key"
                :value="type">
              </el-option>
            </el-select>
          </p>
          <el-table
            ref="elTableVerdict"
            v-loading="verdictLoading"
            :data="verdictData.list"
            @select="handleSelectVerdict"
            @select-all="handleSelectAllVerdict"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column property="title" label="判決書標題"></el-table-column>
            <el-table-column property="type" label="案件類型" width="150"></el-table-column>
            <el-table-column property="date" label="判決日期" width="100"></el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="verdictPageCount"
            :current-page.sync="verdictQuery.get.verdicts['page-index']"
            @current-change="onChangeVerdict('page')">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="風險指標" v-if="tabs.risk" name="risk">
        <p><el-checkbox :indeterminate="checkSome.risk" v-model="checkAll.risk" @change="handleCheckAll($event,'risk')">選取全部</el-checkbox></p>
        <el-checkbox-group v-model="checked.risk" @change="handleChecked($event,'risk')">
          <el-checkbox v-for="(item,i) in items.risk" :label="item.label" :key="'risk'+i">{{item.text}}</el-checkbox>
        </el-checkbox-group>
      </el-tab-pane>
      <el-tab-pane label="經營指標" v-if="tabs.operating" name="operating">
        <p><el-checkbox :indeterminate="checkSome.operating" v-model="checkAll.operating" @change="handleCheckAll($event,'operating')">選取全部</el-checkbox></p>
        <el-checkbox-group v-model="checked.operating" @change="handleChecked($event,'operating')">
          <el-checkbox v-for="(item,i) in items.operating" :label="item.label" :key="'operating'+i">{{item.text}}</el-checkbox>
        </el-checkbox-group>
      </el-tab-pane>
      <el-tab-pane label="財務指標" v-if="tabs.finance" name="finance">
        <p><el-checkbox :indeterminate="checkSome.finance" v-model="checkAll.finance" @change="handleCheckAll($event,'finance')">選取全部</el-checkbox></p>
        <el-checkbox-group v-model="checked.finance" @change="handleChecked($event,'finance')">
          <el-checkbox v-for="(item,i) in items.finance" :label="item.label" :key="'finance'+i">{{item.text}}</el-checkbox>
        </el-checkbox-group>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import LoginFormVue from '@/views/login/LoginForm.vue'

function initData(){
  return{
    activeTab:"basic",
    checkAll:{
      basic:false,
      risk:false,
      operating:false,
      finance: false
    },
    checked:{
      // basic: ["description", "basicTable", "shareholders", "managers", "history", "branch", "factory", "登記地址近似公司"],
      // news:true,
      // verdicts: true,
      // risk: ["dispute", "badVendor", 'data_gov_decision', 'data_gov_environmental_punishment', 'epa'],
      // operating: ["trade", "procurement", "job", "patent", "award"],
      // finance:[]
      basic: [],
      news:false,
      verdicts: false,
      risk: [],
      operating: [],
      finance:[]
    },
    checkSome:{
      basic:false,
      risk:false,
      operating: false,
      finance: false
    },
    items:{
      basic:[
        { label:"description", text:"公司簡介" },
        { label:"basicTable", text:"商工登記資料" },
        { label:"shareholders", text:"董監事資料" },
        { label:"managers", text:"經理人資料" },
        { label:"history", text:"變更紀錄" },
        { label:"branch", text:"分公司資料" },
        { label:"factory", text:"工廠登記" },
        { label:"登記地址近似公司", text:"登記地址近似公司" },
      ],
      risk:[
        { label:"dispute", text:"違反勞動法令案件" },
        { label:"badVendor", text:"拒絕往來廠商紀錄" },
        { label:"data_gov_decision", text:"公平交易委員會行政決定" },
        { label:"data_gov_environmental_punishment", text:"環保署裁罰處分" },
        { label:"epa", text:"列管汙染源資料裁處資訊" },
      ],
      operating:[
        { label:"trade", text:"進出口實續" },
        { label:"procurement", text:"政府標案" },
        { label:"job", text:"人力招聘" },
        { label:"patent", text:"專利資訊" },
        { label:"award", text:"得獎紀錄與政府推薦名單" },
      ],
      finance:[
        { label:"news", text:"公司近期發布之重大訊息" },
        { label:"revenue", text:"營收資訊" },
        { label:"ifrss", text:"採IFRSs後財報資訊" },
      ]
    },
    newsData:{list:[],stat:{"total-count":0}},
    newsLoading: true,
    newsPeriod:{
      start:"",
      end: moment().format('YYYY-MM-DD'),
      custom: false
    },
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
    },
    newsIgnore:[],
    newsQuery:{
      get:{
        news:{
          time:{start:"", end: moment().format('YYYY-MM-DD')},
          "page-index":1,
          'cls-custom':undefined
        }
      },
      uniID: undefined
    },
    verdictData:{
      list:[],
      cls:{JType:[],court:[]},
      stat:{"total-count":0}
    },
    verdictLoading: true,
    verdictFilter:{
      court:{},
      JType:{}
    },
    verdictIgnore:[],
    verdictQuery:{
      get:{
        verdicts:{
          filter:[],
          "page-index":1
        }
      },
      uniID: undefined
    }
  }
}

function createNewsFilter (query) {
  return [
    {
      method: 'should',
      query
    }
  ]
}

export default {
  name:"CompanyDonwloadSelector",
  props: {
    'visible': {
      default () {
        return false
      }
    },
    'uniID': String,
    tabs:{
      default () {
        return {
          basic: true,
          news: true,
          verdicts: true,
          risk: true,
          operating: true,
          finance: true
        }
      }
    }
  },
  data(){
    return initData()
  },
  methods:{
    onOpenDialog(){
      if(this.newsLoading) this.fetchNews()
      if(this.verdictLoading) this.onChangeVerdict()

      // if(this.tabs.finance){
      //   this.checked.finance = ["news", "revenue", "ifrss"]
      //   this.checkAll.finance = true
      // }
    },
    onChangeNewsRange(){
      this.newsQuery.get.news['page-index'] = 1
      this.fetchNews()
    },
    onChangeNewsPage(){
      this.fetchNews()
    },
    onChangeNewsCustom(){
      this.newsQuery.get.news['page-index'] = 1

      if (this.newsPeriod.custom) {
        this.newsQuery.get.news.filter = createNewsFilter(this.getterFirstCustomKeywordQueries)
      } else {
        this.newsQuery.get.news.filter = []
      }
      this.fetchNews()
    },
    fetchNews(){
      this.newsLoading = true

      this.newsQuery = {
        uniID: this.uniID,
        get: {
          news: {
            ...this.newsQuery.get.news,
            time: {
              start: this.newsPeriod.start
                ? moment(this.newsPeriod.start).format('YYYY-MM-DD')
                : undefined,
              end: this.newsPeriod.end
                ? moment(this.newsPeriod.end).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
            }
          }
        }
      }

      apis.companyGet(this.newsQuery)
        .then((data) => {
          if (data.payload.news) {
            // let news = data.payload.news.list
            data.payload.news.list.forEach(n => {
              n.date = n.datetime_pub.slice(0,10)
            });
            this.newsData = data.payload.news
            //全選
            this.$nextTick(()=>{
              this.newsData.list.forEach(row=>{
                this.$refs.elTableNews.toggleRowSelection(row, !this.newsIgnore.includes(row.uniID));
              })
            })
          }
          this.newsLoading = false
        })
        .catch(e => {
          console.log(e);
          this.newsLoading = false
        })
    },
    onChangeVerdict(_, type){
      this.verdictLoading = true
      let filter = []
      if(this.verdictFilter.court.key){
        let courtItem = returnFilterItem(this.verdictFilter.court)
        filter.push(courtItem)
      }
      if(this.verdictFilter.JType.key){
        let jTypeItem = returnFilterItem(this.verdictFilter.JType)
        filter.push(jTypeItem)
      }
      this.verdictQuery.get.verdicts.filter = filter
      if(_ !== 'page') this.verdictQuery.get.verdicts['page-index'] = 1;
      this.verdictQuery.uniID = this.uniID

      apis.companyGet(this.verdictQuery).then((data) => {
        this.verdictLoading = false
        if (data.payload.verdicts) {
          this.verdictData = data.payload.verdicts

          //全選
          this.$nextTick(()=>{
            this.verdictData.list.forEach(row=>{
              this.$refs.elTableVerdict.toggleRowSelection(row, !this.verdictIgnore.includes(row.uniID));
            })
          })
        }
      })
      .catch(e => {
        this.verdictLoading = false
      })

      function returnFilterItem (val) {
        let filterQuery = [val.queries] // 查詢條件
        let courtTextArr = [val.key]
        let infoTextArr = [] // 顯示查詢條件文字
        if (courtTextArr.length) {
          const text = courtTextArr.join('、')
          infoTextArr.push(`${codes.VERDICTS_FILTER_ITEM_DICT[type]}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
    },
    onDownload(){
      this.$emit('download',{
        checked: this.checked,
        filter:{
          news: {
            time: this.newsQuery.get.news.time,
            filter: this.newsPeriod.custom
              ? createNewsFilter(this.getterFirstCustomKeywordQueries)
              : []
          },
          verdicts: { filter:this.verdictQuery.get.verdicts.filter }
        },
        ignore:{
          news: this.newsIgnore,
          verdicts: this.verdictIgnore
        }
      })
      this.currentVisible = false

      setTimeout(() => {
        Object.assign(this.$data, initData());
      }, 500);
    },
    handleCheckAll(val, type) {
      this.checked[type] = val ? this.items[type].map(b => b.label) : [];
      this.checkSome[type] = false;
    },
    handleChecked(value, type) {
      let checkedCount = value.length;
      this.checkAll[type] = checkedCount === this.items[type].length;
      this.checkSome[type] = checkedCount > 0 && checkedCount < this.items[type].length;
    },
    handleSelectNews(all, row){
      let found = this.newsIgnore.indexOf(row.uniID)
      if(found >= 0){
        this.newsIgnore.splice(found,1)
      }else{
        this.newsIgnore.push(row.uniID)
      }
    },
    handleSelectAllNews(all){
      const ids = all.map(a=>a.uniID)
      if(ids.length > 0){
        this.newsIgnore = this.newsIgnore.filter(id => !ids.includes(id))
      }else{
        let combine = [...this.newsIgnore, ...this.newsData.list.map(n=>n.uniID)]
        this.newsIgnore = this.getUnique(combine)
      }
    },
    handleSelectVerdict(all, row){
      let found = this.verdictIgnore.indexOf(row.uniID)
      if(found >= 0){
        this.verdictIgnore.splice(found,1)
      }else{
        this.verdictIgnore.push(row.uniID)
      }
    },
    handleSelectAllVerdict(all){
      const ids = all.map(a=>a.uniID)
      if(ids.length > 0){
        this.verdictIgnore = this.verdictIgnore.filter(id => !ids.includes(id))
      }else{
        let combine = [...this.verdictIgnore, ...this.verdictData.list.map(n=>n.uniID)]
        this.verdictIgnore = this.getUnique(combine)
      }
    },
    getUnique(arr) {
      var a = arr.concat();
      for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
            a.splice(j--, 1);
        }
      }
      return a;
    }
  },
  computed: {
    ...mapGetters([
      'getterFirstCustomKeywordTitle'
    ]),
    ...mapState('companyDetail', [
      // 'basicData',
      // 'riskData',
      // 'operatingData',
      // 'financeData',
      'companyData',
      'companyNews',
      // 'downloadCompanyData'
      'isGoFullLoading',
      'companyGoFull',
    ]),
    ...mapGetters('companyDetail', [
      'getterFirstCustomKeywordQueries'
    ]),
    currentVisible: {
      get () {
        return this.visible
      },
      set (d) {
        this.$emit('update:visible', d)
      }
    },
    newsPageCount () {
      if (this.newsData.stat['total-count']) {
        if (this.newsData.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.newsData.stat['total-count']
        }
      } else {
        return 0
      }
    },
    verdictPageCount () {
      if (this.verdictData.stat['total-count']) {
        if (this.verdictData.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.verdictData.stat['total-count']
        }
      } else {
        return 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-checkbox-group{
  margin-left: 1.5rem;
}

.selectorTitle{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
}

</style>
