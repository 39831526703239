
































































































































































































































// @ts-nocheck
import { defineComponent, SetupContext } from '@vue/composition-api'
// import { COMPANY_ITEM_MAP } from '@/const/codes.js'
import * as screenImageGetter from '@/utils/screenImageGetter'
import ChartCard from '@/components/charts/ChartCard.vue'
import AppSelectedList from '@/components/app/AppSelectList.vue'
import { D3ModuleContainer } from '@bpchart/vue'
import { chartDirected } from '@bpchart/d3-modules'
// import ChartDirectedDagreDbs from '@bpchart/d3-modules/chartDirected'
import { filterStockMin, isCompanyClosed, returnTags, createTagTooltipText } from '../../../utils/parseGoData'
import { formatPercentage, formatNumberWithComma } from '@/utils/text'
import { getSVGGSize, getElementSize, getTransform } from '@/utils/element'
import { Shareholder } from '@/types/apis/goV3'
import { companyGet } from '@/apis/index.ts'
import moment from 'moment'
import { GoNode, GoEdge, PersonPayloadBasic, CompanyPayloadBasic } from '@/types/apis/goV3'
import createChartHtml from '@/utils/createChartHtml'
// import { htmlToImageBuffer } from '@/apis'
import CompanySummaryLightbox from '@/components/CompanySummaryLightbox/CompanySummaryLightbox.vue'
import parseDbsRelationshipChartData from './parseDbsRelationshipChartData'
import downloadCompanyRelationshipExcel from '@/utils/downloadCompanyRelationshipExcel'

// declare module $nuxt {
//   const $store: any
// }

type SourceData = { edges: Array<GoEdge>, nodes: Array<GoNode> }

type RouteData = {
  detail: any;
  'routes-in-id': string[][];
  'routes-of-stock': string;
  'routes-of-stock-detail': number[][];
  'routes-of-stock-detail-single-value': number[];
  'total-investment-ratio': number;
  'Substantial-beneficiary': Array<{
    direct: number;
    indirect: number;
    name: string;
    total: number;
  }>
}

// 套件的節點渲染資料
/* 資料範例：
elem: g.node
intersect: ƒ (point)
label: "中國信託商業銀行股份有限公司"
labelStyle: "fill: #303133;font-weight: normal;font-size: 14px;"
paddingBottom: 10
paddingLeft: 10
paddingRight: 10
paddingTop: 10
rx: 0
ry: 0
shape: "rect"
style: "rx:5px;ry:5px;stroke: #a2c9fb;stroke-width: 1.5px;fill: #fff;"
x: 122
y: 560
*/
type DagreNode = {
  elem: {
    __data__: string; // id
  };
  intersect: any;
  label: string;
  labelStyle: string;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  rx: number;
  ry: number;
  shape: string;
  style: string;
  x: number;
  y: number;
}


const directionMap = {
  RL: {
    value: 'RL',
    tooltip: '由右至左顯示',
    icon: [ 'fas', 'long-arrow-alt-left' ]
  },
  LR: {
    value: 'LR',
    tooltip: '由左至右顯示',
    icon: [ 'fas', 'long-arrow-alt-right' ]
  },
  TB: {
    value: 'TB',
    tooltip: '由上至下顯示',
    icon: [ 'fas', 'long-arrow-alt-down' ]
  }
}

const defaultChartParams = {
  styles: {
    // 一般文字
    text: 'fill: #303133;font-weight: normal;font-size: 14px;',
    // 根節點文字
    textRoot: 'fill: #c00;font-weight: bold;font-size: 14px;',
    // 上游的線
    pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
    // 下游的線
    pathDown: 'stroke: #eb9999; stroke-width: 1.5px; fill:none;',
    // 歇業公司的線
    pathClosed: 'stroke: #c0c4cc; stroke-width: 1.5px; fill:none;',
    // 上游的指標
    arrowTop: 'fill: #909399',
    // 下游的指標
    arrowDown: 'fill: #eb9999',
    // 歇業公司的指標
    arrowClosed: 'fill: #c0c4cc',
    // 根節點
    rectRoot: 'rx:5px;ry:5px;stroke:#eb9999;stroke-width:3px;fill:#fae6e6;',
    // 公司節點
    rectCompany: 'rx:5px;ry:5px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
    // 自然人節點
    rectPerson: 'rx:19px;ry:19px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
    // 下游公司節點
    rectCompanyDown: 'rx:5px;ry:5px;stroke: #eb9999;stroke-width: 1.5px;fill: #fff;',
    // 已歇業公司根節點
    rectRootClosed: 'rx:5px;ry:5px;stroke:#c0c4cc;stroke-width:3px;fill:#DCDFE6;',
    // 已歇業公司節點
    rectCompanyClosed: 'rx:5px;ry:5px;stroke: #c0c4cc;stroke-width: 1.5px;fill: #DCDFE6;',
    // 展開按鈕
    expandBtn: 'fill:#000000'
  }
}

const directionOptions = [
  {
    label: '由右至左顯示',
    value: 'RL'
  },
  {
    label: '由左至右顯示',
    value: 'LR'
  },
  {
    label: '由上至下顯示',
    value: 'TB'
  },
]

// // 選單顯示的倒數計時器
// let mouseoutCounter = -1
// let isCountdownOn = false

const footerHeight = 22

// @Q@ v2版go用的
// const parseChartData = (sourceNodes: Array<GoNode>, sourceEdges: Array<GoEdge>, rootID: string, stockMin: number): chartDirected.ChartDirectedDagreDataset => {
//   const { nodes, edges } = filterStockMin(sourceNodes, sourceEdges, rootID, stockMin)
  
//   // route 的nodes&edges資料Map
//   let arr: [string, string[]][] = nodes.map(d => {
//     let routes: string[] = []
//     if (d['routes-in-id']) {
//       d['routes-in-id'].forEach(arr => {
//         arr.forEach((route)=> {
//           if (routes.indexOf(route) < 0) {
//             routes.push(route)
//           }
//         })
//       })
//     }
//     return [
//       d.uniID, // key
//       routes // value
//     ]
//   })
//   const EdgesOfRoutesMap = new Map(arr)
//   const NodesOfRoutesMap = new Map(
//     arr.map(item => {
//       let ids: string[] = []
//       item[1].forEach(d => {
//         ids = ids.concat(d.split('->')) // 取得路徑中的兩個id
//       })
//       return [
//         item[0], // key
//         ids // value
//       ]
//     })
//   )
//   const RouteDataMap: Map<string, RouteData> = new Map(
//     nodes.map(d => {
//       return [
//         d.uniID,
//         {
//           detail: d.detail,
//           'routes-in-id': d['routes-in-id'] || [],
//           'routes-of-stock': d['routes-of-stock'] || '',
//           'routes-of-stock-detail': d['routes-of-stock-detail'] || [],
//           'routes-of-stock-detail-single-value': d['routes-of-stock-detail-single-value'] || [],
//           'total-investment-ratio': d['total-investment-ratio'] != null ? d['total-investment-ratio'] : -1,
//           'Substantial-beneficiary': d['Substantial-beneficiary'] || []
//         }
//       ]
//     })
//   )
  
//   const chartNodes: Array<chartDirected.ChartDirectedDagreNode> = nodes.map(sourceNode => {
    
//     return {
//       id: sourceNode.uniID,
//       uniID: sourceNode.uniID,
//       label: sourceNode.name,
//       style: returnRectStyle(sourceNode, rootID),
//       // tooltip: createTooltipText({
//       //   shareholders: sourceNode.detail.payload.basic.shareholders,
//       //   RouteDataMap,
//       //   routeId: sourceNode.uniID,
//       //   companyName: sourceNode.detail.payload.basic.company_name
//       // }),
//       tooltip: (d: chartDirected.ChartDirectedDagreNode) => {
//         return createTooltipText(d, RouteDataMap)
//       },
//       hasTop: false, // 後面程式再計算
//       isTopExpanded: false,
//       tags: returnTags(sourceNode),
//       // tagTooltip: returnTagTooltip(sourceNode),
//       tagTooltip: (d: chartDirected.ChartDirectedDagreNode) => {
//         return createTagTooltipText(d.data)
//       },
//       // data: sourceNode, // 原始資料
//       // 原始資料。由於原始資料太大包了，所以只放需要的資料
//       data: {
//         detail: {
//           payload: {
//             // basic: {
//             //   company_state: (sourceNode as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state
//             // }
//             basic: (sourceNode as GoNode<CompanyPayloadBasic>).detail.payload.basic
//           }
//         },
//         name_PEP_name: sourceNode.name_PEP_name,
//         name_PEP_family: sourceNode.name_PEP_family
//       },
//       edgesOfRoutes: sourceNode.uniID === rootID ? [] : (EdgesOfRoutesMap.get(sourceNode.uniID) ?? []), // 所有路徑的edges id
//       nodesOfRoutes: sourceNode.uniID === rootID ? [] : (NodesOfRoutesMap.get(sourceNode.uniID) ?? []), // 所有路徑的nodes id
//       // 路徑詳細資料
//       routeData: {
//         'routes-in-id': sourceNode['routes-in-id'] ?? [],
//         'routes-of-stock': sourceNode['routes-of-stock'] ?? '',
//         'routes-of-stock-detail': sourceNode['routes-of-stock-detail'] ?? [],
//         'routes-of-stock-detail-single-value': sourceNode['routes-of-stock-detail-single-value'] ?? [],
//         'total-investment-ratio': sourceNode['total-investment-ratio'],
//         'stock-down': sourceNode['stock-down'],
//         'stock-up': sourceNode['stock-up'],
//         'Substantial-beneficiary': sourceNode['Substantial-beneficiary']
//       }
//     }
//   })

//   // 將根節點移到第一個
//   const rootNodeIndex = chartNodes.map(d => d.id).indexOf(rootID)
//   if (rootNodeIndex > 0) {
//     chartNodes.splice(0, 0, chartNodes[rootNodeIndex])
//     chartNodes.splice(rootNodeIndex + 1, 1)
//   }
  
//   const NodesMap = new Map(chartNodes.map(d => {
//     return [
//       d.id,
//       d
//     ]
//   }))

//   const chartEdges: Array<chartDirected.ChartDirectedDagreEdge> = edges.map(sourceEdge => {

//     const startData = NodesMap.get(sourceEdge['source-uniID'])
//     const isStartClosed = startData && isCompanyClosed(
//       (startData.data as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state
//     )
//     const direction = startData && startData.id === rootID ? 'down' : 'top'

//     return {
//       id: sourceEdge.id,
//       start: sourceEdge['source-uniID'],
//       end: sourceEdge['target-uniID'],
//       _start: sourceEdge['source-uniID'], // 因為"source"傳入d3的function之後資料會被渲染，所以多留一份原始資料
//       _end: sourceEdge['target-uniID'], // 因為"target"傳入d3的function之後資料會被渲染，所以多留一份原始資料
//       // routeIndex: string; // 路徑索引（非維一值）
//       label: isStartClosed
//         ? ''
//         : formatPercentage(sourceEdge.percentage),
//       direction,
//       percentage: sourceEdge.percentage,
//       style: {
//         path: isStartClosed ? 'pathClosed'
//           : direction === 'down' ? 'pathDown'
//           : direction === 'top' ? 'pathTop'
//           : 'pathTop',
//         arrow: isStartClosed ? 'arrowClosed'
//           : direction === 'down' ? 'arrowDown'
//           : direction === 'top' ? 'arrowTop'
//           : 'arrowTop',
//       }
//     }
//   })

//   modifyHasTopBtnStatus(chartNodes, chartEdges, false, rootID)

//   modifyDirectionStatus(chartEdges, rootID)

//   return {
//     nodes: chartNodes,
//     edges: chartEdges,
//     expandAll: false,
//     direction: 'RL'
//   }
// }

const modifyHasTopBtnStatus = (
  allNodes: Array<chartDirected.ChartDirectedDagreNode>,
  allEdges: Array<chartDirected.ChartDirectedDagreEdge>,
  defaultExpanded: boolean,
  rootID: string
) => {
  for (let node of allNodes) {
    // 先假設無上層
    node.hasTop = false
    node.isTopExpanded = false
    // 根節點無展開按鈕
    if (node.id === rootID) {
      continue
    }
    let hasTop = allEdges.some(edge => {
      // 非node的上層
      if (edge._end !== node.id) {
        return false
      }
      // 如果上層為根節點則無展開按鈕
      if (edge._start === rootID) {
        return false
      }
      // 有上層且非根節點
      return true
    })
    if (hasTop) {
      node.hasTop = true
      node.isTopExpanded = defaultExpanded // 預設是否展開
    }
  }
}

// const modifyDirectionStatus = (
//   allEdges: Array<chartDirected.ChartDirectedDagreEdge>,
//   rootID: string
// ) => {
//   // 先全部預設為'top'
//   for (const edge of allEdges) {
//     edge.direction = 'top'
//   }
//   // 修改direction資料（遞迴）
//   let checkedEdge: Array<chartDirected.ChartDirectedDagreEdge> = []
//   const modifyDownEdges = (nodeID: string) => {
//     for (const edge of allEdges) {
//       if (checkedEdge.find(l => edge._start === l._start && edge._end === l._end)) {
//         continue
//       }
//       if (edge._start === nodeID && edge._end !== rootID) { // 要加上edge._end !== this.rootID這個條件，否則同時是根節點的上流及下流的節點也會被誤判為top
//         checkedEdge.push(edge)
//         edge.direction = 'down'
//         // modifyDownEdges(edge._end) // 往下找
//       }
//     }
//   }
//   // 從根節點開始往下找
//   modifyDownEdges(rootID)
// }

// const returnRectStyle = (node: GoNode, rootID: string) => {
//   if (node.uniID === rootID) {
//     return isCompanyClosed((node as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state) ? 'rectRootClosed' : 'rectRoot'
//   } else if (node.role === '自然人') {
//     return 'rectPerson'
//   }
//   // 法人、根節點下游
//   else if (node['stock-up'].includes(rootID)) {
//     return isCompanyClosed((node as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state) ? 'rectCompanyClosed' : 'rectCompanyDown'
//   }
//   // 法人、根節點上游
//   else {
//     return isCompanyClosed((node as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state) ? 'rectCompanyClosed' : 'rectCompany'
//   }
// }

// const createTooltipText = (nodeData: chartDirected.ChartDirectedDagreNode, RouteDataMap: Map<string, RouteData>) => {
//   // const routeData = RouteDataMap.get(nodeData.id)
//   const routeData = nodeData.routeData
//   const shareholders: Array<Shareholder> = nodeData.data?.detail?.payload?.basic?.shareholders ?? ''
//   const companyName: string = nodeData.data?.detail?.payload?.basic?.company_name ?? ''

//   // -- 實質受益路徑資訊 --
//   let routeInfo = ''
//   if (routeData && routeData['routes-in-id'].length) {
//     const rate = routeData['total-investment-ratio'].toFixed(2)
//     routeInfo += `【持股路徑】`
//     // 各別持股路徑
//     routeData['routes-in-id'].forEach((routesInId, routesIndex) => {
//       const rate = routeData['routes-of-stock-detail-single-value'][routesIndex].toFixed(2)
//       let itemInfo = `</br>${routesIndex + 1}. 本路徑共持股 ${rate}%，路徑及計算如下：`
//       // 持股路徑中的節點
//       routesInId.forEach((d, i) => {
//         const rate = routeData['routes-of-stock-detail'][routesIndex][i].toFixed(2)
//         const thisId = d.slice(0, d.indexOf('->'))
//         const findData = RouteDataMap.get(thisId)
//         itemInfo += `</br><span style="padding-left:15px">${findData!.detail.payload.basic.company_name}（${rate}%）→</span>`
//       })
//       // 加上根節點
//       itemInfo += `</br><span style="padding-left:15px">${companyName}</span>`

//       routeInfo += itemInfo
//     })
//     routeInfo += `</br>＊共 ${routeData['routes-in-id'].length}條持股路徑，總計持股 ${rate}%`
//   }
//   // -- 董監事名單 --
//   let shareholdersInfo = ''
//   if (shareholders && shareholders.length) {
//     shareholdersInfo = '【董監事名單】</br>'
//     shareholdersInfo += shareholders
//       .map((d, i) => {
//         let bracketText = ''
//         if (d.ROL) {
//           bracketText += d.ROL
//         }
//         if (d.title) {
//           if (bracketText) {
//             bracketText += '，'
//           }
//           bracketText += d.title
//         }
//         if (d.stocks) {
//           if (bracketText) {
//             bracketText += '，'
//           }
//           bracketText += `持股 ${formatNumberWithComma(d.stocks)}`
//         }
//         return `${i + 1}. ${d.name}（${bracketText}）`
//       })
//       .join('</br>')
//   }

//   if (routeInfo && shareholdersInfo) {
//     return `${routeInfo}<hr style="margin-top:10px;margin-bottom:10px;">${shareholdersInfo}`
//   } else {
//     return `${routeInfo}${shareholdersInfo}`
//   }
// }

const getNodeZoom = ({ rootNode, targetNode, gSelector }: {
  rootNode: DagreNode;
  targetNode: DagreNode;
  gSelector: SVGGElement;
}) => {
  const gSize = getSVGGSize(gSelector)
  const offsetX = (gSize.width / 2) - rootNode.x
  const offsetY = (gSize.height / 2) - rootNode.y

  return {
    x: offsetX - (targetNode.x - rootNode.x),
    y: offsetY - (targetNode.y - rootNode.y),
    k: 1
  }
}

// 選單顯示的倒數計時器
const createFoldedMenuCounter = () => {
  
  let mouseoutCounter = -1
  let isCountdownOn = false
  let promise = new Promise((resolve, reject) => resolve)
  let _reject
  let _setTimeout
  
  // start
  return (time = 2000) => {
    mouseoutCounter = time
    isCountdownOn = true
    _setTimeout && clearTimeout(_setTimeout)
    _reject && _reject()

    promise = new Promise((resolve, reject) => {
      _reject = reject
      const countdown = () => {
        _setTimeout = setTimeout(() => {
          mouseoutCounter -= 100
          if (mouseoutCounter > 0) {
            countdown()
            return
          }
          // 倒數結束
          mouseoutCounter = -1
          isCountdownOn = false
          resolve()
        }, 100)
      }
      countdown()
    })

    return promise
  }
}

export default defineComponent({
  props: {
    cardId: {
      default () {
        return 'companyStockRelationshipChart'
      }
    },
    // demoMode: {
    //   required: false,
    //   default () {
    //     return false
    //   }
    // },
    'uniID': {
      type: String,
      required: true
    },
    'companyName': {
      type: String,
      required: true
    },
    options: {
      default () {
        return {
          stockMin: 0,
          expandAll: false, // 展開所有節點
          mergeName: false, // 合併相同姓名
          direction: 'RL'
        }
      }
    },
    // 圖表所需的所有節點資料（api的原始結構未parse）
    sourceData: {
      type: Object as () => SourceData,
      default () {
        return {
          edges: [],
          nodes: []
        }
      }
    },
    // chartData: {
    //   type: Object as () => chartDirected.ChartDirectedDagreDataset,
    //   default () {
    //     return {
    //       edges: [],
    //       nodes: [],
    //       expandAll: false,
    //       direction: 'RL'
    //     }
    //   }
    // },
    height: {
      default () {
        return 800
      }
    }
  },
  components: {
    D3ModuleContainer,
    ChartCard,
    AppSelectedList,
    CompanySummaryLightbox
  },
  data () {
    return {
      isProduction: process.env.VUE_APP_MODE === 'production',
      ChartDirectedDagreDbs: chartDirected.ChartDirectedDagreDbs,
      // cardId: 'companyStockRelationshipChart',
      // cardTitle: `【${this.companyName}】股權結構分析圖`,
      cardTooltip: `股權結構分析圖係依您所查詢之公司，並根據經濟部 OPEN DATA 進行股權計算之樹狀結構圖。<br>
                  ．箭頭所流向之單位，表示為公司所投資之對象或擔任其董事、監察人。<br>
                  ．箭頭所源於之單位或自然人，表示為公司之董監事或監察人。`,
      cardDescription: '「股權百分比」預設為每股面額 10 元，算法：持有股份/(實收資本額/10)*100%。如出現持股逾 100% 的情形，係因該單位每股面額非 10 元之故。',
      cardWidth: 0 as number | string,
      cardHeight: 0 as number | string,
      headerHeight: 70,
      currentChartData: {
        nodes: [],
        edges: [],
        expandAll: false,
        direction: 'RL'
      } as chartDirected.ChartDirectedDagreDataset,
      updateKey: Number(new Date()), // 強制變更資料
      isFullScreen: false,
      isDownloading: false,
      screenShotMode: false,
      screenShotModeChart: false, // 圖表本身的screenShotMode
      loadedNodeUniIDs: [], // 紀錄已載入進來的所有node id
      EdgesOfRoutesMap: new Map(), // key:node id, value: 所有路徑edges
      NodesOfRoutesMap: new Map(), // key:node id, value: 所有路徑nodes
      RouteDataMap: new Map(), // key:node id, value: 相關route資料
      isShowChart: true,
      isShowDirectionMenu: false,
      isShowDownloadMenu: false,
      directionMenuCounter: createFoldedMenuCounter(),
      downloadMenuCounter: createFoldedMenuCounter(),
      isCompanyLightboxVisible: false,
      companyLightboxBasic: {},
      direction: 'RL', // 方向
      directionOptions,
      // autoZoom: false, // 螢幕變換尺寸時 zoom還原預設（用於縮放全螢幕時）
      highlightId: undefined as string | undefined,
      keyword: '' as string,
      isSearchPopoverVisible: false,
      chartZoom: undefined as any,
      // svgSelector: undefined as SVGElement | undefined,
      // gSelector: undefined as SVGGElement | undefined,
      isLightboxLoading: false
    }
  },
  computed: {
    syncOptions: {
      get () {
        return this.options
      },
      set (d) {
        this.$emit('update:options', d)
      }
    },
    cardTitle () {
      return `【${this.companyName}】股權結構分析圖`
    },
    cardSizeState () {
      return {
        isFullScreen: this.isFullScreen,
        screenShotMode: this.screenShotMode,
      }
    },
    outerContainerSize () {
      if (this.isFullScreen) {
        return {
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: '0px',
          left: '0px',
          'z-index': 2000
        }
      } else {
        return {
          position: 'relative',
          width: '100%',
          height: `${this.height}px`
        }
      }
    },
    fullScreenIcon () {
      if (this.isFullScreen) {
        return 'compress'
      } else {
        return 'expand'
      }
    },
    rootID () {
      return this.uniID
      // return '80333183'
      // this.basic.uniID
    },
    directionMenu () {
      if (this.syncOptions.direction === 'RL') {
        // return [directionMap.RL, directionMap.LR, directionMap.TB]
        return [directionMap.LR, directionMap.TB, directionMap.RL]
      } else if (this.syncOptions.direction === 'LR') {
        // return [directionMap.LR, directionMap.TB, directionMap.RL]
        return [directionMap.TB, directionMap.RL, directionMap.LR]
      } else if (this.syncOptions.direction === 'TB') {
        // return [directionMap.TB, directionMap.RL, directionMap.LR]
        return [directionMap.RL, directionMap.LR, directionMap.TB]
      }
      return []
    },
    chartParams () {
      return {
        ...defaultChartParams,
        key: this.updateKey, // 強制變更資料
        screenShotMode: this.screenShotModeChart,
        direction: this.syncOptions.direction,
        // autoZoom: this.autoZoom,
        highlightId: this.highlightId
      }
    },
    // chartSelection () {
    //   // return (this.$refs.refChart as any)?.d3Module?.selection ?? undefined
    //   if ((this.$refs.refChart as any)?.d3Module?.selection ?? undefined) {
    //     const nodes = (this.$refs.refChart as any)?.d3Module?.selection.selectAll<SVGGElement, SourceNode>('g.node')
    //     console.log('nodes', nodes)
    //     return (this.$refs.refChart as any).d3Module.selection
    //   } else {
    //     return undefined
    //   }      
    // }
  },
  watch: {
    cardSizeState: {
      handler (cardSizeState) {
        if (cardSizeState.screenShotMode === true && document) {
          // 取得寬高
          const screenShotSize = getSVGGSize(document.querySelector('.choose__dagre-directed-chart__g'))
          // 將卡片寬高設為和符合圖表尺寸（圖表寬高加上卡片間距）
          // let width = screenShotSize.width + this.cardStyle['padding-left'] + this.cardStyle['padding-right']
          // let height = screenShotSize.height + this.cardStyle['padding-top'] + this.cardStyle['padding-bottom'] + this.cardStyle.header.height + this.cardStyle.footer.height + 20
          let width = screenShotSize.width + 140 // 圖表寬高加上卡片間距
          let height = screenShotSize.height + this.headerHeight + footerHeight + 120 // 圖表寬高加上卡片間距
          width = width * 1.1
          height = height * 1.1
          if (width < 640) {
            width = 640
          }
          if (height < 480) {
            height = 480
          }
          this.cardWidth = width
          this.cardHeight = height
        } else if (cardSizeState.isFullScreen === true) {
          this.cardWidth = '100vw'
          this.cardHeight = '100vh'
        } else {
          this.cardWidth = '100%'
          this.cardHeight = `${this.height}px`
        }
      },
      immediate: true
    },
    sourceData: {
      handler (d) {
        this.setChartData(d)
      },
      immediate: true
    },
    // chartData: {
    //   handler (d) {
    //     if (d && d.nodes) {
    //       this.setChartData(d, true)
    //     }
    //   },
    //   immediate: true
    // }
    // 'chartSelection': {
    //   handler (chartSelection) {
    //     console.log('watch')
    //     debugger
    //     if (chartSelection) {
    //       debugger
    //       extandsNodeInfo(chartSelection, this.currentChartData)
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    // ...mapMutations([
    //   'mutationIsBlurboxActive'
    // ]),
    setChartData (d: SourceData) {
      if (d && d.nodes) {
        // if (demo === true) {
        //   this.currentChartData = d as chartDirected.ChartDirectedDagreDataset
        //   return
        // }
        // const data = JSON.parse(JSON.stringify(d)) as SourceData
        
        this.currentChartData = parseDbsRelationshipChartData(d.nodes, d.edges, this.uniID, Number(this.syncOptions.stockMin))
        console.log('setChartData', this.currentChartData)
      }
    },
    async toggleDirectionMenu (isShow: boolean) {
      this.isShowDirectionMenu = true
      
      // await this.$nextTick()
      // this.isShowDirectionMenu = isShow
      // if (isShow) {
      //   mouseoutCounter = 2000
      // }
    },
    handleDirectionMenuMouseout () {
      this.isShowDirectionMenu = true
      
      this.directionMenuCounter()
        .then(() => {
          this.isShowDirectionMenu = false
        })
      // if (isCountdownOn) {
      //   return
      // }
      // isCountdownOn = true
      // const countdown = () => {
      //   setTimeout(() => {
      //     mouseoutCounter -= 100
      //     console.log('handleDirectionMenuMouseout', mouseoutCounter)
      //     if (mouseoutCounter > 0) {
      //       countdown()
      //       return
      //     }
      //     mouseoutCounter = -1
      //     isCountdownOn = false
      //     this.isShowDirectionMenu = false
      //   }, 100)
      // }
      // countdown()
    },
    async toggleDownloadMenu (isShow: boolean) {
      this.isShowDownloadMenu = true
      
      // await this.$nextTick()
      // this.isShowDownloadMenu = isShow
      // if (isShow) {
      //   mouseoutCounter = 2000
      // }
    },
    handleDownloadMenuMouseout () {
      this.isShowDownloadMenu = true
      
      this.downloadMenuCounter()
        .then(() => {
          this.isShowDownloadMenu = false
        })
      // if (isCountdownOn) {
      //   return
      // }
      // isCountdownOn = true
      // const countdown = () => {
      //   setTimeout(() => {
      //     mouseoutCounter -= 100
      //     console.log('handleDownloadMenuMouseout', mouseoutCounter)
      //     if (mouseoutCounter > 0) {
      //       countdown()
      //       return
      //     }
      //     mouseoutCounter = -1
      //     isCountdownOn = false
      //     this.isShowDownloadMenu = false
      //   }, 100)
      // }
      // countdown()
    },
    changeDirection (direction: string) {
      this.toggleDirectionMenu(false)

      this.$set(this.syncOptions, 'direction', direction)
      
    },
    async resetChart () {
      // 更新圖表
      this.isShowChart = false
      this.$set(this.syncOptions, 'expandAll', false)
      this.currentChartData = {
        nodes: [],
        edges: [],
        expandAll: false,
        direction: 'RL'
      }
      // 重新建立節點資料
      this.setChartData(this.sourceData)
      await this.$nextTick()
      this.isShowChart = true
      // await this.$nextTick()
      
    },
    async toggleExpandedMode (isExpandAll: boolean) {
      this.isShowChart = false
      this.$set(this.syncOptions, 'expandAll', isExpandAll)
      // let currentChartData = JSON.parse(JSON.stringify(this.currentChartData))
      // if (this.allNodesMode) {
      //   this.setAllExpanded(currentChartData.nodes, currentChartData.edges)
      // } else {
      //   this.setAllUnexpaned(currentChartData.nodes, currentChartData.edges)
      // }
      // 修正是否有展開按鈕
      modifyHasTopBtnStatus(this.currentChartData.nodes, this.currentChartData.edges, this.syncOptions.expandAll, this.rootID)

      this.currentChartData.expandAll = this.syncOptions.expandAll
      // this.currentChartData = currentChartData

      await this.$nextTick()
      this.isShowChart = true
      
      // 回到根節點
      setTimeout(() => {
        const gSelector = document.querySelector(`#ChartDirectedDagre-${this.uniID} g.choose__dagre-directed-chart__g`) as SVGGElement
        const nodes: {[key:string]:DagreNode} = (this.$refs.refChart as any).d3Module.chartG._nodes
        const rootNode = nodes[this.uniID]
        this.chartZoom = getNodeZoom({ rootNode, targetNode: rootNode, gSelector })        
      }, 1000)
    },
    // -- download --
    downloadCsv () {
      downloadCompanyRelationshipExcel(
        {
          nodes: this.sourceData.nodes,
          edges: this.sourceData.edges,
          rootId: this.rootID
        },
        this.rootID,
        this.companyName
      )
    },
    // 由前端截圖（舊的做法）
    async download () {
      this.screenShotMode = true
      // await this.$nextTick()
      // const screenHotModeFooterHtml = `
      //   ${logoSvg}
      //   <span style="color:#1778f5;font-size:14px;margin-left:10px">
      //     圖表繪製時間 ${moment().format('YYYY/MM/DD HH:mm')}
      //   </span>
      //   `
      // this.$refs.chart.d3Module.setFooterHtml(screenHotModeFooterHtml)
      await new Promise(resolve => setTimeout(resolve, 2500))
      this.screenShotModeChart = true
      await new Promise(resolve => setTimeout(resolve))
      
      let result = await screenImageGetter.downloadScreenImage(
        `#${this.cardId}`,
        `${this.cardTitle} ${moment().format('YYYY/MM/DD HH:mm')}`,
        '#app'
      )
      // this.$refs.chart.d3Module.setFooterHtml(logoSvg)
      this.screenShotMode = false
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.screenShotModeChart = false
    },
    // async download () {
    //   this.isDownloading = true
    //   const fileTitle = `${this.cardTitle}_${moment().format('YYYY_MM_DD_HH_mm')}` 
    //   const chart = document.querySelector(`#${this.cardId} .chart-component svg`)
    //   const html = createChartHtml({
    //     chart,
    //     title: this.cardTitle,
    //     setChart: (chart, newChart) => {
    //       const g = chart.querySelector('g')
    //       const newChartG = newChart.querySelector('g')
    //       let { width, height } = getElementSize(g)
    //       const transform = getTransform(g) ?? {}
    //       const scale: number = Number(transform.scale) ?? 1
    //       width = width / scale
    //       height = height / scale
    //       newChartG.setAttribute('transform', `translate(10, 10) scale(1)`)
    //       newChart.setAttribute('width', width + 20)
    //       newChart.setAttribute('height', height + 20)
    //     }
    //   })
    //   const b64Data = await htmlToImageBuffer({
    //     html
    //   })
    //   const href = `data:image/png;base64,${b64Data}`
    //   const link = document.createElement('a');
    //   document.body.appendChild(link);
    //   link.href = href
    //   link.download = fileTitle
    //   link.click()
    //   this.isDownloading = false
    // },
    // 切換全營幕
    async toggleFullScreen (isFullScreen: boolean) {
      // this.autoZoom = true
      this.isFullScreen = isFullScreen
      // this.mutationIsBlurboxActive(!this.isFullScreen) // 切換是否啟用背景（因backdrop-filter會影響到position:fixed）
      // $nuxt.$store.commit('mutationIsBlurboxActive', !this.isFullScreen) // 切換是否啟用背景（因backdrop-filter會影響到position:fixed）

      // this.isShowChart = false
      // // 回到根節點
      // setTimeout(() => {
      //   const gSelector = document.querySelector(`#ChartDirectedDagre-${this.uniID} g.choose__dagre-directed-chart__g`) as SVGGElement
      //   const nodes: {[key:string]:DagreNode} = (this.$refs.refChart as any).d3Module.chartG._nodes
      //   const rootNode = nodes[this.uniID]
      //   this.chartZoom = getNodeZoom({ rootNode, targetNode: rootNode, gSelector })

      //   // this.isShowChart = true
      //   // this.updateKey = Number(new Date()) // 強制變更資料
      // }, 100)
      
    },
    async handleDropdownClick (command: 'directionLeft' | 'directionDown' | 'directionRight' | 'expand' | 'compress' | 'download' | 'fullscreen' | 'originScreen') {
      this.updateKey = Number(new Date()) // 強制變更資料

      if (command === 'directionLeft') {
        this.changeDirection('RL')
      } else if (command === 'directionDown') {
        this.changeDirection('TB')
      } else if (command === 'directionRight') {
        this.changeDirection('LR')
      } else if (command === 'expand') {
        this.toggleExpandedMode(true)
      } else if (command === 'compress') {
        this.toggleExpandedMode(false)
      } else if (command === 'download') {
        this.download()
      } else if (command === 'fullscreen') {
        this.toggleFullScreen(true)
      } else if (command === 'originScreen') {
        this.toggleFullScreen(false)
      }
    },
    handleKeywordChange () {
      if (!this.keyword || !(this.$refs.refChart as any) || !(this.$refs.refChart as any).d3Module) {
        this.chartZoom = undefined
        this.highlightId = undefined
        return
      }
      
      let findNode: DagreNode | undefined = undefined
      let rootNode: DagreNode | undefined = undefined
      
      let findNodeKey: string | undefined
      if ((this.$refs.refChart as any)?.d3Module?.chartG?._nodes) {
        const nodes: {[key:string]:DagreNode} = (this.$refs.refChart as any).d3Module.chartG._nodes
        findNodeKey = Object.keys(nodes).find((key: string) => {
          return nodes[key].label.includes(this.keyword) || key.includes(this.keyword)
        })
        // const rootNodeKey = Object.keys(nodes).find((key: string) => {
        //   return key === this.uniID
        // })
        findNode = findNodeKey != undefined ? nodes[findNodeKey] : undefined
        rootNode = this.uniID != undefined ? nodes[this.uniID] : undefined
      }

      // 找到節點
      if (findNodeKey && findNode && rootNode) {
        // this.highlightId = findNode.elem.__data__
        this.highlightId = findNodeKey
        const gSelector = document.querySelector(`#ChartDirectedDagre-${this.uniID} g.choose__dagre-directed-chart__g`) as SVGGElement
        this.chartZoom = getNodeZoom({ rootNode, targetNode: findNode, gSelector })
      }
    },
    async handleNodeClick (data: any) {
      // 先取得lightbox所需部份資料
      this.companyLightboxBasic = data?.data?.data?.detail?.payload?.basic ?? {}
      this.isCompanyLightboxVisible = true
      this.isLightboxLoading = true
      // 取得lightbox完整資料
      try {
        const result = await companyGet({
          get: {},
          uniID: this.uniID
        })
        // 全部的資料
        this.companyLightboxBasic = result.payload.basic
        this.isLightboxLoading = false
      } catch (e) {
        this.isLightboxLoading = false
      }
    }
  }
})
