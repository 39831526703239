import { SourceNode } from '@/types/apis/GraphicForDbs'
import { RowCompanyRoot, RowCompanyTop, RowCompanyBottom } from '@/types/relationshipExcel'
import { TreeFullRow } from '@bpchart/d3-modules/chartScoutRoute'
import { ChartScoutRouteTree2Dataset, TreeFullNodeDatum } from '@bpchart/d3-modules/chartScoutRoute'
import parseTreeNodeData from '@/utils/parseTreeNodeData'
import { formatPercentage } from '@/utils/text'
import * as XLSX from 'xlsx-js-style'

// type ExcelData = {
//   root: RowCompanyRoot
//   top: RowCompanyTop[]
//   bottom: RowCompanyBottom[]
// }

const parseRowCompanyRoot = (node: TreeFullNodeDatum): RowCompanyRoot => {
  // const node = row.lineEndNodes[0]
  return {
    name: node.label,
    public_issue: node.sourceData.public_issue ?? '-',
    stock_code_from_publics: node.sourceData.stock_code_from_publics ?? '-',
    date_approved: node.sourceData.date_approved ?? '-',
    uniID: node.uniID
  }
}

const parseRowCompanyTop = (nodes: TreeFullNodeDatum[], TreeNodesCurrentMap: Map<string, TreeFullNodeDatum>): RowCompanyTop[] => {
  return nodes.map(node => {
    // const bottomCompanys = Array.from(node._NodesOfRoutesSet)
    //   .map(uniID => {
    //     const bottomNode = TreeNodesCurrentMap.get(uniID)!
    //     return bottomNode
    //   })
    //   .filter(d => {
    //     return d.label !== node.label // 排除掉自己本身
    //       && d._trunkNode && d._trunkNode.uniID === node.uniID
    //   })
    //   .map(d => d.label)
    const BottomCompanysSet: Set<string> = new Set()
    node._routesDataUp.forEach(routesDataUp => {
      const bottomId = routesDataUp['routes-in-id'][0] ? routesDataUp['routes-in-id'][0].split('->')[1] : ''
      const bottomNode = TreeNodesCurrentMap.get(bottomId)
      if (bottomNode && bottomNode.label) {
        BottomCompanysSet.add(bottomNode.label)
      }
    })
    const bottomCompanys = Array.from(BottomCompanysSet).map(d => d)
    
    return {
      name: node.label,
      public_issue: node.sourceData.public_issue ?? '-',
      stock_code_from_publics: node.sourceData.stock_code_from_publics ?? '-',
      date_approved: node.sourceData.date_approved ?? '-',
      uniID: isNaN(Number(node.uniID)) ? '-' : node.uniID,
      bottomCompanys,
      'total-investment-ratio': node.sourceData['total-investment-ratio']
        ? node.sourceData['total-investment-ratio'] / 100
        : 0,
      routeAmount: node.sourceData['routes-of-stock-detail']
        ? node.sourceData['routes-of-stock-detail'].length
        : 0
    }
  })
}

const parseRowCompanyBottom = (nodes: TreeFullNodeDatum[], TreeNodesCurrentMap: Map<string, TreeFullNodeDatum>): RowCompanyBottom[] => {
  return nodes.map(node => {
    // const topCompanys = Array.from(node._NodesOfRoutesSet)
    //   .map(uniID => {
    //     const topNode = TreeNodesCurrentMap.get(uniID)!
    //     return topNode
    //   })
    //   .filter(d => {
    //     return d.label !== node.label // 排除掉自己本身
    //       && d._twigNodes.find(_d => _d.uniID === node.uniID)
    //   })
    //   .map(d => d.label)
    const TopCompanysSet: Set<string> = new Set()
    node._routesDataDown.forEach(routesDataDown => {
      const lastPath = routesDataDown['routes_to_downs-in-id'][routesDataDown['routes_to_downs-in-id'].length - 1]
      const topId = lastPath ? lastPath.split('->')[0] : ''
      const topNode = TreeNodesCurrentMap.get(topId)
      if (topNode && topNode.label) {
        TopCompanysSet.add(topNode.label)
      }
    })
    const topCompanys = Array.from(TopCompanysSet).map(d => d)
    
    // api沒給所以要自己算（全部路徑持股相加）
    const totalInvestmentRatio = (
      ((node.sourceData['routes_to_downs-of-stock-detail-single-value'] as number[])
        .reduce((prev, curr) => prev + curr, 0) ?? 0)
      / 100
    )

    return {
      name: node.label,
      public_issue: node.sourceData.public_issue ?? '-',
      stock_code_from_publics: node.sourceData.stock_code_from_publics ?? '-',
      date_approved: node.sourceData.date_approved ?? '-',
      uniID: isNaN(Number(node.uniID)) ? '-' : node.uniID,
      topCompanys,
      'total-investment-ratio': totalInvestmentRatio,
      routeAmount: node.sourceData['routes_to_downs-of-stock-detail']
        ? node.sourceData['routes_to_downs-of-stock-detail'].length
        : 0
    }
  })
}

const parseTableData = (RowsMap: Map<number, TreeFullNodeDatum[]>, TreeNodesCurrentMap: Map<string, TreeFullNodeDatum>): any[] => {
  let rowCompanyRoot: RowCompanyRoot
  let rowsCompanyTop: RowCompanyTop[][] = []
  let rowsCompanyBottom: RowCompanyBottom[][] = []

  const rows: Array<{ rowIndex: number; nodes: TreeFullNodeDatum[] }> = Array.from(RowsMap)
    .map(([rowIndex, nodes]) => ({ rowIndex, nodes }))
    .sort((a, b) => a.rowIndex - b.rowIndex) // 小到大
  console.log(rows)
  const zeroIndex = rows.findIndex((d, i) => d.rowIndex == 0)

  // root
  rowCompanyRoot = parseRowCompanyRoot(rows[zeroIndex].nodes[0])

  // top
  for (let i = zeroIndex + 1; i < rows.length; i++) {
    if (!rows[i].nodes.length) {
      continue
    }
    rowsCompanyTop.push(parseRowCompanyTop(rows[i].nodes, TreeNodesCurrentMap))
  }

  // bottom
  for (let i = zeroIndex - 1; i >= 0; i--) {
    if (!rows[i].nodes.length) {
      continue
    }
    rowsCompanyBottom.push(parseRowCompanyBottom(rows[i].nodes, TreeNodesCurrentMap))
  }

  // let row = [
  //   { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
  //   { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
  //   { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
  //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
  // ];
  const headerStyle = { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { fgColor: { rgb: 'c00000' }}}
  const topCompanyHeader = [
    { v: '法人/自然人名稱' },
    { v: '公開發行狀態' },
    { v: '股票代碼' },
    { v: '設立日期' },
    { v: '統編' },
    { v: '下游投資公司' },
    { v: '對查詢公司之總持股比例' },
    { v: '對查詢公司之持股路徑數' },
  ]
  const bottomCompanyHeader = [
    { v: '法人/自然人名稱' },
    { v: '公開發行狀態' },
    { v: '股票代碼' },
    { v: '設立日期' },
    { v: '統編' },
    { v: '上游投資公司' },
    { v: '查詢公司對其之總持股比例' },
    { v: '查詢公司對其之持股路徑數' },
  ]

  const table = [
    [
      { v: '您所查詢的公司',  t: 's', s: headerStyle, _flag: 'header' },
    ],
    [
      { v: '名稱' },
      { v: '公開發行狀態' },
      { v: '股票代碼' },
      { v: '設立日期' },
      { v: '統編' },
    ],
    [
      { v: rowCompanyRoot.name },
      { v: rowCompanyRoot.public_issue },
      { v: rowCompanyRoot.stock_code_from_publics },
      { v: rowCompanyRoot.date_approved },
      { v: rowCompanyRoot.uniID },
    ],
    // 上游
    ...rowsCompanyTop
      .map((rows, index) => {
        return [
          [
            { v: `上游第${index + 1}層`,  t: 's', s: headerStyle, _flag: 'header' },
          ],
          topCompanyHeader,
          ...rows.map(row => {
            return [
              { v: row.name },
              { v: row.public_issue },
              { v: row.stock_code_from_publics },
              { v: row.date_approved },
              { v: row.uniID },
              { v: row.bottomCompanys.join('\n'), s:  {alignment:{ wrapText: true }} },
              { v: formatPercentage(row['total-investment-ratio'] * 100) }, // 小數2位
              { v: String(row.routeAmount) },
            ]
          })
        ]
      })
      .flat(),
    // 下游
    ...rowsCompanyBottom
      .map((rows, index) => {
        return [
          [
            { v: `下游第${index + 1}層`,  t: 's', s: headerStyle, _flag: 'header' },
          ],
          bottomCompanyHeader,
          ...rows.map(row => {
            console.log(row['total-investment-ratio'])
            return [
              { v: row.name },
              { v: row.public_issue },
              { v: row.stock_code_from_publics },
              { v: row.date_approved },
              { v: row.uniID },
              { v: row.topCompanys.join('\n'), s:  {alignment:{ wrapText: true }} },
              { v: formatPercentage(row['total-investment-ratio'] * 100) }, // 小數2位
              { v: String(row.routeAmount) },
            ]
          })
        ]
      })
      .flat(),
  ]

  return table
}

export default function (dataset: ChartScoutRouteTree2Dataset, rootId: string, companyName: string) {
  
  const {
    TreeNodesCurrentMap
  } = parseTreeNodeData(dataset, rootId)

  // console.log(RowsMap)
  const RowsMap: Map<number, TreeFullNodeDatum[]> = new Map()
  Array.from(TreeNodesCurrentMap!)
    .forEach(([id, node]) => {
      const nodes: TreeFullNodeDatum[] = RowsMap.get(node._rowIndex) ?? []
      nodes.push(node)
      RowsMap.set(node._rowIndex, nodes)
    })

  // STEP 1: Create a new workbook
  const wb = XLSX.utils.book_new();

  // STEP 2: Create data rows and styles
  const table: any[] = parseTableData(RowsMap, TreeNodesCurrentMap!)

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(table);

  // 所有 header 合併欄位
  const headerIndexs = table
    .map((d, i) => d[0] && d[0]._flag === 'header' ? i : -1)
    .filter(d => d >= 0)
  const merge = headerIndexs.map(rowIndex => {
    return { s: { r: rowIndex, c: 0 }, e: { r: rowIndex, c: 7 } }
  })
  // [
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },{ s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
  // ];
  ws["!merges"] = merge
  // 寬度
  var wscols = [
      // {wch: 600}, // "characters"
      // {wpx: 500}, // "pixels"
      // ,
      // {hidden: true} // hide column
    {wch: 40},
    {wch: 15},
    {wch: 10},
    {wch: 12},
    {wch: 10},
    {wch: 40},
    {wch: 25},
    {wch: 25},
  ];
  ws['!cols'] = wscols;

  XLSX.utils.book_append_sheet(wb, ws, "readme demo");

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, `【${companyName}】股權結構分析表.xlsx`);
}
