

















































































































































































import { defineComponent, SetupContext, ref, computed, Ref, ComputedRef, watch, onMounted, WritableComputedRef } from '@vue/composition-api'
import { D3ModuleContainer } from '@bpchart/vue'
import { chartScoutRoute } from '@bpchart/d3-modules'
import { DrawerData } from './TreeChartDrawer.vue'
import AppSelectedList from '@/components/app/AppSelectList.vue'
import { filterOptions, defaultFilterValue, createTreeChartFilter, createLineChartFilter, FilterConfig } from './filterOptions'
import { formatPercentage } from '@/utils/text'
import { getElementSize, getTransform } from '@/utils/element'
import { getRouterCount } from '@/utils/parseGoData'
import { defaultExpandMode, treeParams2 } from './chartDefaults'

type Props = {
  uniID: string;
  companyName: string;
  chartData: chartScoutRoute.ChartScoutRouteTree2Dataset;
  drawerData: DrawerData | undefined;
  drawerVisible: boolean;
  filterValue: string;
  autoZoom: boolean;
  description: string;
}

type Transform = {
  x: number;
  y: number;
  k: number;
}

const expandModeOptions = [
  {
    label: '無收合',
    value: 'none'
  },
  {
    label: '依樹狀結構',
    value: 'hierarchy'
  },
  {
    label: '依上下游關係',
    value: 'direction'
  },
]

const createDrawerData = (
  d: chartScoutRoute.ChartScoutRouteTreeEventCallback,
  treeChartData: chartScoutRoute.ChartScoutRouteTree2Dataset,
  stockMin: number,
  stockExceptZero: boolean
): DrawerData  => {
  let totalInvestmentRatio = ''
  let routesNumber = 0
  let routesNumberFiltered = 0
  const mainRelatedToRoot = d.data.uniID === treeChartData.rootId ? 'root'
    : d.data.sourceData && d.data.sourceData['roles_related_to_root'].includes('neibor') ? 'up'
    : d.data.sourceData && d.data.sourceData['routes-in-id'].length ? 'up'
    : d.data.sourceData && d.data.sourceData['routes_to_downs-in-id'].length ? 'down'
    : 'up'
  const sourceNode = treeChartData.nodes.find(_d => _d.uniID === d.data.uniID)!
  // console.log('sourceNode', sourceNode)
  // 上游
  if (mainRelatedToRoot === 'up') {
    totalInvestmentRatio = formatPercentage(d.data.sourceData['total-investment-ratio'])
    routesNumber = sourceNode['routes-in-id']!.length
    routesNumberFiltered = getRouterCount(sourceNode['routes-of-stock-detail-single-value']!, stockMin, stockExceptZero)
  }
  // 下游
  else if (mainRelatedToRoot === 'down') {
    // api沒給所以要自己算（全部路徑持股相加）
    totalInvestmentRatio = formatPercentage(
      (sourceNode['routes_to_downs-of-stock-detail-single-value'] as number[])
      .reduce((prev, curr) => prev + curr, 0)
    )
    routesNumber = sourceNode['routes_to_downs-in-id']!.length
    routesNumberFiltered = getRouterCount(sourceNode['routes_to_downs-of-stock-detail-single-value']!, stockMin, stockExceptZero)
  }
  return {
    uniID: d.data.id,
    label: d.data.label,
    tags: d.data._tags,
    totalInvestmentRatio,
    routesNumber,
    routesNumberFiltered,
    shareholders: d.data.sourceData && d.data.sourceData.role !== '自然人'
      ? d.data.sourceData.detail.payload.basic.shareholders.map((shareholder: any) => {
        // const findEdge = treeChartData.edges.find(edge => {
        //   const edgeSourceUniID = edge['source-uniID'].split('_')[2] || edge['source-uniID'] // 人名的格式為 [company_name]_[sno]_[name]
        //   return edge['target-uniID'] === d.data.id
        //     && (edgeSourceUniID === shareholder.ROL_uniID || edgeSourceUniID === shareholder.ROL || edgeSourceUniID === shareholder.name)
        // })
        return {
          name: shareholder.name,
          stocks: shareholder.stocks ?? shareholder.contribution,
          // percentage: formatPercentage(findEdge ? findEdge.percentage : 0),
          percentage: formatPercentage((shareholder.stocks / d.data.sourceData.public_shares) * 100),
          title: shareholder.title,
          ROL: shareholder.ROL
        }
      })
      : [],
    sourceNodeData: sourceNode,
    nodeData: d.data.sourceData,
    mainRelatedToRoot
  }
}

export default defineComponent({
  props: {
    'uniID': {
      type: String,
      required: true
    },
    'companyName': {
      type: String,
      required: true
    },
    chartData: {
      type: Object as () => chartScoutRoute.ChartScoutRouteTree2Dataset,
      default () {
        return {
          nodes: [],
          edges: [],
          rootId: ''
        }
      }
    },
    drawerData: {
      type: Object as () => DrawerData | undefined
    },
    drawerVisible: {
      type: Boolean
    },
    filterValue: {
      type: String
    },
    // 螢幕變換尺寸時 zoom還原預設（用於縮放全螢幕時）
    autoZoom: {
      type: Boolean
    },
    description: {
      type: String
    },
    screenShotMode: {
      default () {
        return false
      }
    }
  },
  components: {
    D3ModuleContainer,
    AppSelectedList
  },
  setup (props: Props, { root, emit }: SetupContext) {

    const isProduction: Ref<boolean> = ref(process.env.VUE_APP_MODE === 'production')

    const syncDrawerData: WritableComputedRef<DrawerData | undefined> = computed({
      get () {
        return props.drawerData
      },
      set (v) {
        emit('update:drawerData', v)
      }
    })

    const syncDrawerVisible: WritableComputedRef<boolean> = computed({
      get () {
        return props.drawerVisible
      },
      set (v) {
        emit('update:drawerVisible', v)
      }
    })

    const syncFilterValue: WritableComputedRef<string> = computed({
      get () {
        return props.filterValue
      },
      set (v) {
        emit('update:filterValue', v)
      }
    })

    // bp-chart的vue component實體
    const refChart = ref(null)
    // <g> 的dom
    // const gSelector: Ref<SVGGElement | undefined> = ref(undefined)
    let svgSelector: SVGElement | undefined = undefined
    let gSelector: SVGGElement | undefined = undefined
    // onMounted(() => {
    //   gSelector.value = document.querySelector(`#chart-${props.uniID} g.chart-scout-route__group`) as SVGGElement
    // })


    const treeChartData: ComputedRef<chartScoutRoute.ChartScoutRouteTree2Dataset> = computed(() => {
      // const { stockMin, stockExceptZero } = createTreeChartFilter(syncFilterValue.value)
      return {
        nodes: props.chartData.nodes,
        edges: props.chartData.edges,
        rootId: props.uniID,
        // stockExceptZero,
        // stockMin
      }
    })
    // const treeChartTitle = ref('')
    const auxiliaryLine = ref(false)
    const scaleExtendLimit = ref(false)
    const treeExpandMode: Ref<chartScoutRoute.ExpandMode> = ref(defaultExpandMode)
    // watch(treeChartData, () => {
    //   // if ((treeChartRef.value as any)?.d3Module?.nodesDataFiltered) {
    //   //   const nodesData: Array<chartScoutRoute.TreeNodeData> = (treeChartRef.value as any)?.d3Module?.nodesDataFiltered
    //   //   console.log('--------------', treeChartData.value.nodes)
    //   // }
    //   let title = ''
    //   let beneficialArr = []
    //   let beneficialText = ''
    //   if (treeChartData.value.nodes) {
    //     beneficialArr = treeChartData.value.nodes.filter(d => {
    //       return d.role === '自然人' && d['total-investment-ratio'] >= 25
    //     })
    //     let beneficialTextArr = beneficialArr.map(d => {
    //       return `${d.name}（持股${formatPercentage(d['total-investment-ratio'])}）`
    //     })
    //     beneficialText = beneficialTextArr.join('、')
    //   }
    //   title = `【${props.companyName}】根據公開資料計算，有 ${beneficialArr.length} 位實質受益人`
    //   if (beneficialArr.length) {
    //     title += `，分別為 ${beneficialText}。`
    //   } else {
    //     title += '。'
    //   }
    //   treeChartTitle.value = title
    // }, { immediate: true })
    const treeChartParams: ComputedRef<chartScoutRoute.ChartScoutRouteTree2Params> = computed(() => {
      return {
        ...treeParams2,
        routeHighlightId: syncDrawerData.value && syncDrawerData.value.uniID ? syncDrawerData.value.uniID : '',
        // stockMin: cardToolBarData.value.stockMin,
        autoZoom: props.autoZoom,
        // transform: transform.value
        style: {
          auxiliaryLine: auxiliaryLine.value
        },
        expandMode: treeExpandMode.value,
        scaleExtent: scaleExtendLimit.value == true
          ? {
            min: 0.5,
            max: 2
          }
          : {
            min: 0,
            max: Infinity
          }
      }
    })
    const treeChartFilter = computed(() => {
      return createTreeChartFilter(syncFilterValue.value)
    })

    // search
    const keyword = ref('')
    const isSearchPopoverVisible = ref(false)
    const chartZoom: Ref<Transform | undefined> = ref()

    return {
      ChartScoutRouteTree2: chartScoutRoute.ChartScoutRouteTree2,
      isProduction,
      refChart,
      filterOptions,
      treeChartParams,
      treeChartData,
      treeExpandMode,
      treeChartFilter,
      auxiliaryLine,
      scaleExtendLimit,
      syncFilterValue,
      // treeChartTitle,
      expandModeOptions,
      keyword,
      isSearchPopoverVisible,
      chartZoom,
      forceFit: true,
      handleNodeClick (d: chartScoutRoute.ChartScoutRouteTreeEventCallback) {
        if (!d.data || !d.data.id) {
          return
        }
        // 點相同節點則關閉
        if (syncDrawerData.value && syncDrawerData.value.uniID && syncDrawerData.value.uniID === d.data.id) {
          syncDrawerVisible.value = false
          syncDrawerData.value = undefined
        }
        // 點未點擊的節點則打開
        else {
          const { stockMin, stockExceptZero } = createTreeChartFilter(syncFilterValue.value)
          syncDrawerVisible.value = true
          syncDrawerData.value = createDrawerData(d, treeChartData.value, stockMin, stockExceptZero)
        }
      },
      handleKeywordChange () {
        if (!keyword.value || !(refChart.value as any) || !(refChart.value as any).d3Module) {
          chartZoom.value = undefined
          syncDrawerVisible.value = false
          syncDrawerData.value = undefined
          return
        }
        // @ts-ignore
        const nodesDataFiltered: chartScoutRoute.TreeFullNodeDatum[] = (refChart.value.d3Module as chartScoutRoute.ChartScoutRouteTree2).treeNodesFiltered ?? []

        const findNode = nodesDataFiltered.find(node => {
          return (node.label.includes(keyword.value) || node.uniID.includes(keyword.value))
        })
        
        // 找到節點
        if (findNode) {
          // 取得<g>場景尺寸
          if (!gSelector || !svgSelector) {
            svgSelector = document.querySelector(`#chart-${props.uniID} svg`) as SVGElement 
            gSelector = document.querySelector(`#chart-${props.uniID} g.chart-scout-route__group`) as SVGGElement
          }
          const svgSize = getElementSize(svgSelector)
          // const gSize = getSVGGSize(gSelector)
          // const gTransform: any = getTransform(gSelector!)

          const { stockMin, stockExceptZero } = createTreeChartFilter(syncFilterValue.value)
          syncDrawerVisible.value = true
          syncDrawerData.value = createDrawerData({ data: findNode } as any, treeChartData.value, stockMin, stockExceptZero)
          
          chartZoom.value = {
            // x: - (findNode.x - ((svgSize.width / 2) - (findNode.width / 2))),
            // y: - findNode.y + (svgSize.height / 2),
            x: - findNode.x,
            y: - findNode.y,
            k: 1
          }
          
          console.log(findNode, chartZoom.value)
        } else {
          syncDrawerVisible.value = false
          syncDrawerData.value = undefined
        }
        
        // props.chartData.nodes.find(node => {
        //   node.
        // })
      }
    }
  }
})
