<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        stripe
        empty-text="目前暫無資料喔 :)"
        :data="currentAddressSimilarly.list"
        v-loading="isLoading">
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="近似公司">
          <template slot-scope="scope">
            {{scope.row.company_name}}
            <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
            <router-link
              target="_blank"
              :to="returnCompanyLinkTo(scope.row.company_name)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link>
            <!-- </a> -->
          </template>
        </el-table-column>
        <el-table-column
          label="登記地址"
          prop="company_address">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="searchableCount"
        @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { SEARCH_MAX_COUNT } from '@/const/config.js'
import { companyGet } from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.登記地址近似公司
      }
    },
    '登記地址近似公司': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentAddressSimilarly: {
        list: [],
        stat: {}
      }
    }
  },
  computed: {
    count () {
      if (this.currentAddressSimilarly.stat) {
        return this.currentAddressSimilarly.stat['total-count']
      } else {
        return 0
      }
    },
    searchableCount () {
      if (this.currentAddressSimilarly.stat) {
        if (this.currentAddressSimilarly.stat['total-count'] > SEARCH_MAX_COUNT) {
          return SEARCH_MAX_COUNT
        } else {
          return this.currentAddressSimilarly.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    returnCompanyLinkTo (companyName) {
      if (!companyName) {
        return null
      }
      const dataTypeIndex = codes.DATA_TYPE_INDEX_DIST[codes.DEFAULT_DATA_TYPE]
      const query = {
        basic: {
          get: ['list', 'cls'],
          keyword: companyName,
          keyword_type: 'company',
          'page-index': 1,
          sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE)
        }
      }
      const queryString = JSON.stringify(query)
      return {
        name: 'companyList',
        params: {
          dataTypeIndex
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      }
    },
    changePage (page) {
      const searchRequest = {
        get: {
          登記地址近似公司: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.登記地址近似公司) {
            this.currentAddressSimilarly = data.payload.登記地址近似公司
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    '登記地址近似公司': {
      handler (d) {
        if (d) {
          this.currentAddressSimilarly = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
