















import { defineComponent, SetupContext, ref, computed } from '@vue/composition-api'

type Props = {
  visible: boolean
}

export default defineComponent({
  props: {
    visible: {
      default () {
        return false
      }
    }
  },
  setup (props: Props, { emit }: SetupContext) {

    const syncVisible = computed({
      get () {
        return props.visible
      },
      set (d) {
        emit('update:visible', d)
      }
    })
    return {
      syncVisible,
      handleClose () {
        syncVisible.value = false
        emit('onClosed')
      }
    }
  }
})
