<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源： 經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentCompanysHistory.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="變更日期"
          width="100"
          prop="date">
        </el-table-column>
        <el-table-column
          label="變更項目"
          width="200"
          prop="col_name">
        </el-table-column>
        <el-table-column
          label="變更前"
          prop="old">
          <template slot-scope="scope">
            <component
              :is="returnDetailComponent(scope.row.col_name_en)"
              :value="scope.row.old">
            </component>
          </template>
        </el-table-column>
        <el-table-column
          label="變更後"
          prop="new">
          <template slot-scope="scope">
            <component
              :is="returnDetailComponent(scope.row.col_name_en)"
              :value="scope.row.new"
              :compareValue="scope.row.old">
            </component>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="companysHistorySearchableCount"
        @current-change="changeCompanysHistoryPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as config from '@/const/config.js'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import DefaultDetail from './DefaultDetail.vue'
import EtaxDetail from './EtaxDetail.vue'
import PeopleDetail from './PeopleDetail.vue'
import * as companysHistoryUtils from '@/utils/companysHistoryUtils.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.companys_history
      }
    },
    'companys_history': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  components: {
    DefaultDetail,
    EtaxDetail,
    PeopleDetail
  },
  data () {
    return {
      isLoading: false,
      currentCompanysHistory: {
        list: [],
        stat: {}
      },
      emphasizedClass: 'color-danger',
    }
  },
  computed: {
    companysHistoryCount () {
      if (this.currentCompanysHistory.stat) {
        return this.currentCompanysHistory.stat['total-count']
      } else {
        return 0
      }
    },
    companysHistorySearchableCount () {
      if (this.currentCompanysHistory.stat) {
        if (this.currentCompanysHistory.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentCompanysHistory.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    returnDetailComponent (col_name_en) {
      return companysHistoryUtils.returnDetailComponent(col_name_en)
    },
    isShowColumnDetail (row) {
      if (!row.col_name_en) {
        return false
      }
      if (row.col_name_en === 'directors' ||
      row.col_name_en === 'president' ||
      row.col_name_en === 'managers') {
        return true
      } else {
        return false
      }
    },
    changeCompanysHistoryPage (page) {
      const searchRequest = {
        get: {
          companys_history: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.companys_history) {
            this.currentCompanysHistory = data.payload.companys_history
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'companys_history': {
      handler (d) {
        if (d) {
          this.currentCompanysHistory = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
