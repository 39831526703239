<template>
  <li class="article-item">
    <div class="article-item-header">
      <router-link
        class="article-title"
        target="_blank"
        :to="verdictLinkTo">
        <span v-html="verdictTitle"></span>
      </router-link>
      <div class="article-info">
        <ul class="article-info-list">
          <li class="article-info-item">
            <font-awesome-icon :icon="[ 'fas', 'balance-scale' ]" />
            <span>{{verdict.type}}</span>
          </li>
          <li class="article-info-item">
            <font-awesome-icon :icon="[ 'far', 'clock' ]" />
            <span>{{verdict.date}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="article-item-container">
      <div
        class="content"
        v-html="verdictContent">
      </div>
    </div>
    <div class="article-item-footer">
      <div class="article-footer-title">提及關鍵字</div>
      <ul class="list list-keyword">
        <li
          class="item"
          v-for="k in relatedKeywords"
          :key="k">
          {{k}}
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import utils from '@/utils/utils.js'

export default {
  props: {
    verdict: {
      required: true
    },
  },
  data () {
    return {
      colorClass: 'color-danger',
    }
  },
  computed: {
    relatedKeywords () {
      let RelatedKeywordsSet = new Set()
      if (this.verdict.keywords) {
        if (this.verdict.keywords.company_name && this.verdict.keywords.company_name.length) {
          this.verdict.keywords.company_name.forEach(d => RelatedKeywordsSet.add(d))
        }
        if (this.verdict.keywords.representative_name && this.verdict.keywords.representative_name.length) {
          this.verdict.keywords.representative_name.forEach(d => RelatedKeywordsSet.add(d))
        }
        if (this.verdict.keywords.shareholders && this.verdict.keywords.shareholders.length) {
          this.verdict.keywords.shareholders.forEach(d => RelatedKeywordsSet.add(d))
        }
        if (this.verdict.keywords.managers && this.verdict.keywords.managers.length) {
          this.verdict.keywords.managers.forEach(d => RelatedKeywordsSet.add(d))
        }
      }
      return Array.from(RelatedKeywordsSet)
    },
    verdictLinkTo () {
      let query = {}
      // 如果是公司查詢加上關鍵字比對
      if (this.verdict.keywords) {
        if (this.verdict.keywords.company_name && this.verdict.keywords.company_name.length) {
          query.rCompany = this.verdict.keywords.company_name.join(',')
        }
        if (this.verdict.keywords.representative_name && this.verdict.keywords.representative_name.length) {
          query.rRepresentative = this.verdict.keywords.representative_name.join(',')
        }
        if (this.verdict.keywords.shareholders && this.verdict.keywords.shareholders.length) {
          query.rShareHolders = this.verdict.keywords.shareholders.join(',')
        }
        if (this.verdict.keywords.managers && this.verdict.keywords.managers.length) {
          query.rManagers = this.verdict.keywords.managers.join(',')
        }
      }
      return {
        name: 'verdictDetail',
        params: {
          uniID: this.verdict.uniID
        },
        query
      }
    },
    verdictTitle () {
      let text = ''
      if (this.verdict.title) {
        text = this.verdict.title
        this.relatedKeywords.forEach(d => {
          let newText = `<span class="${this.colorClass}">${d}</span>`
          text = utils.replaceAll(text, d, newText)
        })
      }
      return text
    },
    verdictContent () {
      let text = ''
      if (this.verdict.content) {
        text = this.verdict.content
        this.relatedKeywords.forEach(d => {
          let newText = `<span class="${this.colorClass}">${d}</span>`
          text = utils.replaceAll(text, d, newText)
        })
        text = text + '...'
      }
      return text
    }
  }
}
</script>
