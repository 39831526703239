<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentManagers.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="name">
          <template slot-scope="scope">
            {{scope.row.name}}
            <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
            <router-link
              target="_blank"
              v-if="scope.row.name"
              :to="returnRepresentativeLinkTo(scope.row.name)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link>
            <!-- </a> -->
          </template>
        </el-table-column>
        <el-table-column
          label="到職日期"
          prop="stocks">
          <template slot-scope="scope">
            {{scope.row.arrival_date || '-'}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="managersSearchableCount"
        @current-change="changeManagersPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as config from '@/const/config.js'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.managers
      }
    },
    'managers': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentManagers: {
        list: [],
        stat: {}
      }
    }
  },
  computed: {
    managersCount () {
      if (this.currentManagers.stat) {
        return this.currentManagers.stat['total-count']
      } else {
        return 0
      }
    },
    managersSearchableCount () {
      if (this.currentManagers.stat) {
        if (this.currentManagers.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentManagers.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    returnRepresentativeLinkTo (representativeName) {
      if (!representativeName) {
        return null
      }
      // 預設的比較人物查詢條件
      const basicList = [
        {
          field: 'all',
          keyword: representativeName
        }
      ]
      const defaultGet = {
        companys: {},
        verdicts: {},
        news: {
          // 'remove-duplicate': 1
        }
      }
      return {
        name: 'multiPersonDetail',
        params: {
          menuIndex: representativeName
        },
        query: {
          basicList: JSON.stringify(basicList),
          get: JSON.stringify(defaultGet)
        }
      }
    },
    changeManagersPage (page) {
      const searchRequest = {
        get: {
          managers: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.managers) {
            this.currentManagers = data.payload.managers
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'managers': {
      handler (d) {
        if (d) {
          this.currentManagers = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
