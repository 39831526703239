<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源： 經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentCompanyBranchs.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="統一編號"
          width="100"
          prop="uniID">
        </el-table-column>
        <el-table-column
          label="分公司名稱"
          prop="company_name">
          <template slot-scope="scope">
            {{scope.row.company_name||'-'}}
            <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
            <!-- <router-link
              target="_blank"
              v-if="scope.row.company_name"
              :to="returnCompanyLinkTo(scope.row.uniID)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link> -->
            <!-- </a> -->
          </template>
        </el-table-column>
        <el-table-column
          label="公司狀況"
          prop="company_state">
        </el-table-column>
        <el-table-column
          label="分公司核准設立日期"
          width="160"
          prop="date_approved">
          <template slot-scope="scope">
            {{scope.row.date_approved || '-'}}
          </template>
        </el-table-column>
        <el-table-column
          label="最後核准變更設立日期"
          width="160"
          prop="date_approved_latest">
          <template slot-scope="scope">
            {{scope.row.date_approved_latest || '-'}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="companyBranchsSearchableCount"
        @current-change="changeCompanyBranchsPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as config from '@/const/config.js'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.company_branchs
      }
    },
    'company_branchs': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentCompanyBranchs: {
        list: [],
        stat: {}
      }
    }
  },
  computed: {
    companyBranchsCount () {
      if (this.currentCompanyBranchs.stat) {
        return this.currentCompanyBranchs.stat['total-count']
      } else {
        return 0
      }
    },
    companyBranchsSearchableCount () {
      if (this.currentCompanyBranchs.stat) {
        if (this.currentCompanyBranchs.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentCompanyBranchs.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    returnCompanyLinkTo (uniID) {
      if (!uniID) {
        return null
      }
      const dataTypeIndex = codes.DATA_TYPE_INDEX_DIST[codes.DEFAULT_DATA_TYPE]
      const query = {
        basic: {
          get: ['list', 'cls'],
          keyword: uniID,
          keyword_type: '統一編號',
          'page-index': 1,
          sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE)
        }
      }
      const queryString = JSON.stringify(query)
      return {
        name: 'companyList',
        params: {
          dataTypeIndex
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      }
    },
    changeCompanyBranchsPage (page) {
      const searchRequest = {
        get: {
          company_branchs: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.company_branchs) {
            this.currentCompanyBranchs = data.payload.company_branchs
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'company_branchs': {
      handler (d) {
        if (d) {
          this.currentCompanyBranchs = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
