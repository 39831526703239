import { GoNode, GoEdge } from '@/types/apis/goV3'


export const filterStockMin = (sourceNodes: Array<GoNode>, sourceEdges: Array<GoEdge>, rootID: string, stockMin: number) => {
  let newData = {
    nodes: [] as Array<GoNode>,
    edges: [] as Array<GoEdge>
  }
  // step1 過濾百分比
  if (stockMin == 0) {
    newData.edges = sourceEdges.map(d => d)
  } else {
    newData.edges = sourceEdges.filter(edge => {
      const percentage = edge.percentage >= 0 ? edge.percentage : 0 // 負值視為 0
      return percentage >= stockMin
    })
  }
  // step2 過濾掉無連接到根節點的線
  const filterEdgesFromRoot = (edges: Array<GoEdge>) => {
    let newEdges: Array<GoEdge> = []

    const searchEdge = (currentID: string) => {
      const topFromCurrent = edges.filter(edge => edge['target-uniID'] === currentID)
      const downFromCurrent = edges.filter(edge => edge['source-uniID'] === currentID)
      for (const edge of topFromCurrent) {
        let isExist = newEdges.some(newEdge => {
          return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID']
        })
        if (isExist == false) {
          newEdges.push(edge)
          searchEdge(edge['source-uniID'])
        }
      }
      for (const edge of downFromCurrent) {
        let isExist = newEdges.some(newEdge => {
          return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID']
        })
        if (isExist == false) {
          newEdges.push(edge)
          searchEdge(edge['target-uniID'])
        }
      }
    }
    searchEdge(rootID)

    return newEdges
  }
  newData.edges = filterEdgesFromRoot(newData.edges)
  // step3 過濾掉無連接線的節點
  newData.nodes = sourceNodes.filter(node => {
    return newData.edges.find((edge) => node.uniID === edge['source-uniID'] || node.uniID === edge['target-uniID']) != null
  })
  return newData
}

// export const isCompanyClosed = (company_state: string | undefined) => {
//   if (company_state && company_state !== '核准設立') {
//     if (company_state === '歇業'
//     || company_state === '廢止'
//     || company_state === '廢止已清算完結'
//     || company_state === '廢止許可'
//     || company_state === '廢止許可已清算完結'
//     || company_state === '廢止認許'
//     || company_state === '廢止認許已清算完結'
//     || company_state === '廢止登記'
//     || company_state === '撤回認許'
//     || company_state === '撤回認許已清算完結'
//     || company_state === '撤回登記'
//     || company_state === '撤回登記已清算完結'
//     || company_state === '撤銷'
//     || company_state === '撤銷登記'
//     || company_state === '撤銷已清算完結'
//     || company_state === '撤銷無須清算'
//     || company_state === '撤銷許可'
//     || company_state === '撤銷認許'
//     || company_state === '撤銷認許已清算完結'
//     || company_state === '撤銷公司設立'
//     || company_state === '解散'
//     || company_state === '解散已清算完結'
//     || company_state === '合併解散'
//     || company_state === '核准設立，但已命令解散') {
//       return true
//     }
//   }
//   return false
// }

export const isCompanyClosed = (company_operate: string | undefined) => {
  if (company_operate !== '營業中') {
    return true
  }
  return false
}

export const returnTags = (node: GoNode) => {
  let tags = []
  if (node.name_PEP_name || node.name_PEP_family) {
    tags.push({
      text: '政',
      style: 'tagPeps'
    })
  }
  if (node.role === '自然人' && node['total-investment-ratio'] >= 25) {
    tags.push({
      text: '益',
      style: 'tagBeneficial'
    })
  }
  return tags
}

// export const returnTagTooltip = (node: GoNode) => {
//   let text = ''
//   try {
//     if (node.name_PEP_name && node.name_PEP_name.length) {
//       node.name_PEP_name.map(pepName => {
//         let positionAndExperience = (pepName.position || []).concat(pepName.experience || [])
//         positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
//         text += '．與列名 PEPS 者同名'
//         text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
//       })
//     }
//     if (node.name_PEP_family && node.name_PEP_family.length) {
//       node.name_PEP_family.map(pepFamily => {
//         let positionAndExperience = (pepFamily.position || []).concat(pepFamily.experience || [])
//         positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
//         if (text) {
//           text += '</br>'
//         }
//         text += '．與列名 PEPS 者之家屬同名'
//         text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
//       })
//     }
//   } catch (e) { console.error(e) }

//   return text
// }

export const createTagTooltipText = (node: GoNode) => {
  let text = ''
  try {
    if (node.name_PEP_name && node.name_PEP_name.length) {
      node.name_PEP_name.map(pepName => {
        let positionAndExperience = (pepName.position || []).concat(pepName.experience || [])
        positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
        text += '．與列名 PEPS 者同名'
        text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
      })
    }
    if (node.name_PEP_family && node.name_PEP_family.length) {
      node.name_PEP_family.map(pepFamily => {
        let positionAndExperience = (pepFamily.position || []).concat(pepFamily.experience || [])
        positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
        if (text) {
          text += '</br>'
        }
        text += '．與列名 PEPS 者之家屬同名'
        text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
      })
    }
  } catch (e) { console.error(e) }

  return text
}

// 取得路徑筆數
export const getRouterCount = (routesOfStockDetailSingleValue: number[] = [], stockMin: number, stockExceptZero: boolean) => {
  let predicate = (d: number) => d >= stockMin
  if (stockMin == 0 && stockExceptZero) {
    predicate = (d: number) => d > 0
  } else if (stockMin == 0 && !stockExceptZero) {
    predicate = (d: number) => true
  }
  let count = 0
  routesOfStockDetailSingleValue.forEach(d => {
    if (predicate(d)) {
      count ++
    }
  })
  return count
}