<template>
  <div class="page-grid">
    <div class="page-grid-left" v-loading="isFilterLoading">
      <div class="aside-filter">
        <div class="aside-filter-container">
          <div class="aside-filter__box">
            <div class="aside-filter-title">時間範圍</div>
            <div class="aside-filter-content">
              <!-- 時間範圍 -->
              <div class="filter-group">
                <!-- <div class="filter-group-title">{{NEWS_FILTER_ITEM_DICT.time}}</div> -->
                <el-row>
                  <el-col style="color:#606266;font-size:13px;text-align:left;line-height:30px">
                    起始時間
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-date-picker
                      v-model="startDate"
                      type="date"
                      placeholder="請選擇起始時間"
                      value-format="yyyy-MM-dd"
                      style="100%">
                    </el-date-picker>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col style="color:#606266;font-size:13px;text-align:left;line-height:30px">
                    結束時間
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-date-picker
                      v-model="endDate"
                      type="date"
                      placeholder="請選擇結束時間"
                      value-format="yyyy-MM-dd"
                      style="100%">
                    </el-date-picker>
                  </el-col>
                  <!-- {{capitalAmountSliderOptions[capitalAmountSliderValue[0]].label}} - {{capitalAmountSliderOptions[capitalAmountSliderValue[1]].label}} -->
                </el-row>
              </div>
            </div>
          </div>
          <div class="aside-filter__box">
            <div class="aside-filter-title">分類篩選</div>
            <div class="aside-filter-content">
              <!-- 提及公司名稱 -->
              <div class="filter-group">
                <div class="filter-group-title">{{NEWS_FILTER_ITEM_DICT.company}}</div>
                <el-tree
                  ref="company"
                  show-checkbox
                  node-key="id"
                  :data="companyOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree>
              </div>
              <!-- 提及人物 -->
              <div class="filter-group">
                <div class="filter-group-title">{{NEWS_FILTER_ITEM_DICT.people}}</div>
                <el-tree
                  ref="people"
                  show-checkbox
                  node-key="id"
                  :data="peopleOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree>
              </div>
            </div>
          </div>
          <div class="aside-filter__box">
            <div class="aside-filter-title">潛在風險</div>
            <div class="aside-filter-content">
              <div
                class="filter-group"
                v-for="(customOptions,index) in customOptionArr"
                :key="index">
                <div class="filter-group-title">
                  {{customOptionTitles[index]}}
                  <el-checkbox
                    style="margin-left: 10px"
                    v-model="customOptionSelectAllArr[index]"
                    @change="handleCheckAll(customOptionSelectAllArr[index], index)">
                    全選
                  </el-checkbox>
                </div>
                <el-checkbox-group v-model="customValueArr[index]" size="mini">
                  <el-checkbox
                    border
                    v-for="item in customOptions"
                    :key="item.value"
                    :label="item.value">
                    {{item.label}}
                  </el-checkbox>
                </el-checkbox-group>
                <!-- <el-tree
                  ref="customs"
                  show-checkbox
                  node-key="id"
                  :data="customOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree> -->
              </div>
              <!-- <div class="filter-group">
                <el-checkbox-group v-model="customValueArr[index]" size="mini">
                  <el-checkbox
                    border
                    v-for="item in negativeOptions"
                    :key="item.value"
                    :label="item.value">
                    {{item.label}}
                  </el-checkbox>
                </el-checkbox-group>
              </div> -->
            </div>
          </div>
          <div class="aside-filter__box">
            <div class="aside-filter-title">熱門關鍵字</div>
            <div class="aside-filter-content">
              <div class="filter-group">
                <el-checkbox-group v-model="adeptAllValue" size="mini">
                  <el-checkbox
                    border
                    v-for="item in adeptAllOptions"
                    :key="item.value"
                    :label="item.value">
                    {{item.label}}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <div class="aside-filter-footer">
          <el-button
            type="primary"
            @click="filterSearch">
            篩選
          </el-button>
          <el-button
            @click="clearFilterCondition">
            清除篩選條件
          </el-button>
        </div>
      </div>
    </div>
    <div class="page-grid-right">
      <div class="company-container">
        <section>
          <h4 class="section-title">
            {{title}}
            <span class="section-sub-title">共為您搜尋到 {{newsCount}} 筆資料</span>
            <span class="section-sub-title">資料來源： 藍星球資訊</span>
          </h4>
          <el-popover
            trigger="click"
            width="500"
            v-if="newsSearchCondition.length">
            <p
              v-for="(text,index) in newsSearchCondition"
              :key="index">
              {{text}}
            </p>
            <el-button slot="reference" type="text" size="mini">查看目前篩選條件</el-button>
          </el-popover>
          <div class="article-list-set">
            <div class="article-list-toolbar">
              <div class="toolbar-left">
                <el-select
                  size="mini"
                  placeholder="排序"
                  v-model="newsListSort"
                  @change="onNewsListSortChange">
                  <el-option
                    v-for="item in newsListSortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="toolbar-right">
                <el-pagination
                  layout="prev, pager, next, jumper"
                  :total="newsSearchableCount"
                  :current-page.sync="currentPage"
                  @current-change="pageSearch">
                </el-pagination>
              </div>
            </div>
            <ul class="article-list" v-loading="isListLoading">
              <NewsCard
                v-for="news in currentNews.list"
                :key="news.uniID"
                :news="news"
                :companyName="companyName"
                :uniID="uniID"
                :searchQuery="currentSearchQuery">
              </NewsCard>
            </ul>
            <div class="align-right">
              <el-pagination
                layout="prev, pager, next, jumper"
                :total="newsSearchableCount"
                :current-page.sync="currentPage"
                @current-change="pageSearch">
              </el-pagination>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import NewsCard from './NewsCard.vue'
import store from '@/store/index.js'

const newsListSortOptions = [
  {
    label: '依時間由近至遠',
    value: '{"key":"datetime_pub","order":"desc"}',
    data: {"key":"datetime_pub","order":"desc"}
  },
  {
    label: '依時間由遠至近',
    value: '{"key":"datetime_pub","order":"asc"}',
    data: {"key":"datetime_pub","order":"asc"}
  },
]

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.news
      }
    },
    'news': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      // required: false
      required: true
    },
    'companyName': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
  },
  components: {
    NewsCard
  },
  data () {
    return {
      NEWS_FILTER_ITEM_DICT: codes.NEWS_FILTER_ITEM_DICT,
      newsListSortOptions,
      // searchTypeOptions,
      isFilterLoading: false,
      isListLoading: false,
      // judgmentTypeOptions,
      currentSearchQuery: {
        uniID: this.uniID,
        get: {
          news: {
            'page-index': 1,
            sort: JSON.parse(newsListSortOptions[0].value),
            time: {},
            filter: []
          }
        }
      },
      currentNews: {
        list: [],
        stat: {}
      },
      // startDate: '',
      // endDate: moment().format('YYYY-MM-DD'),
      // searchType: '',
      // judgmentType: '',
      currentPage: 1,
      defaultProps: {},
      newsListSort: newsListSortOptions[0].value,
      // 後分類篩選選單
      adeptAllOptions: [],
      companyOptions: [],
      peopleOptions: [],
      // negativeOptions: [],
      customOptionArr: [],
      customOptionTitles: [], // 對應 customOptionArr 的標題
      customOptionSelectAllArr: [], // 全選 checkbox
      adeptAllValue: [],
      customValueArr: [],
      // negativeValue: [],
      // companyValue: [],
      // peopleValue: [],
      startDate: '',
      endDate: moment().format('YYYY-MM-DD')
    }
  },
  watch: {
    'news': {
      handler (d) {
        if (d) {
          this.currentNews = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    },
    'currentNews': {
      handler (d) {
        // -- 後分類選單 --
        if (this.currentNews.cls) {
          // 熱門關鍵字
          if (this.currentNews.cls.adept_all) {
            // 清空熱門關鍵字的value
            this.adeptAllValue = []
            this.adeptAllOptions = this.currentNews.cls.adept_all.map(d => {
              let children = null
              return {
                label: d.key,
                value: JSON.stringify(d),
                source: d
              }
            })
            try {
              this.adeptAllOptions = this.adeptAllOptions.sort((a, b) => {
                return b.source.doc_count - a.source.doc_count
              })
            } catch (e) {
              console.error(e)
            }
            if (this.adeptAllOptions.length > 20) {
              this.adeptAllOptions.length = 20
            }
          } else {
            this.adeptAllOptions = []
          }
          // 潛在風險
          if (this.currentNews.cls.negative) {
            this.negativeOptions = this.currentNews.cls.negative
              .map(d => {
                let children = null
                return {
                  label: d.key,
                  value: JSON.stringify(d),
                  source: d
                }
              })
              .filter(d => {
                return d.source.doc_count > 0
              })
            try {
              this.negativeOptions = this.negativeOptions.sort((a, b) => {
                return b.source.doc_count - a.source.doc_count
              })
            } catch (e) {
              console.error(e)
            }
            if (this.negativeOptions.length > 20) {
              this.negativeOptions.length = 20
            }
          } else {
            this.negativeOptions = []
          }
          // 提及公司名稱
          if (this.currentNews.cls.company) {
            this.companyOptions = this.currentNews.cls.company.map(d => {
              let children = null
              if (d.sub) {
                children = d.sub.map(subData => {
                  return {
                    id: subData.key,
                    label: subData.key,
                    source: subData
                  }
                })
              }
              return {
                id: d.key,
                label: d.key,
                children,
                source: d
              }
            })
          } else {
            this.companyOptions = []
          }
        }
        // 提及人物
        if (this.currentNews.cls.people) {
          this.peopleOptions = this.currentNews.cls.people.map(d => {
            let children = null
            if (d.sub) {
              children = d.sub.map(subData => {
                return {
                  id: subData.key,
                  label: subData.key,
                  source: subData
                }
              })
            }
            return {
              id: d.key,
              label: d.key,
              children,
              source: d
            }
          })
        } else {
          this.peopleOptions = []
        }
        // 提及人物
        if (this.currentNews.cls.custom) {
          this.customOptionTitles = Object.keys(this.currentNews.cls.custom)
          this.customValueArr = this.customOptionTitles.map(d => []) // 清空value
          this.customOptionSelectAllArr = this.customOptionTitles.map(d => false)
          this.customOptionArr = this.customOptionTitles.map(key => {
            const arr = this.currentNews.cls.custom[key]
            
            return arr.map(d => {
              return {
                label: d.key,
                value: JSON.stringify(d),
                source: d
              }
            })
          })
        } else {
          this.customOptionArr = []
        }
      },
      immediate: true
    },
  },
  computed: {
    riskKeywordGroup () {
      return store.state.riskKeywordGroup
    },
    newsCount () {
      if (this.currentNews.stat['total-count']) {
        return this.currentNews.stat['total-count']
      } else {
        return 0
      }
    },
    newsSearchableCount () {
      if (this.currentNews.stat['total-count']) {
        if (this.currentNews.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentNews.stat['total-count']
        }
      } else {
        return 0
      }
    },
    newsSearchCondition () {
      let descList = []
      if (this.currentSearchQuery.get.news.time &&
      this.currentSearchQuery.get.news.time.start &&
      this.currentSearchQuery.get.news.time.end) {
        descList.push(`時間範圍：${this.currentSearchQuery.get.news.time.start}~${this.currentSearchQuery.get.news.time.end}`)
      }
      if (this.currentSearchQuery.get.news.filter.length) {
        descList = descList.concat(this.currentSearchQuery.get.news.filter.map(d => d._desc))
      }
      return descList
    }
    // currentNewsList () {
    //   if (this.currentNews.list.length && this.judgmentType) {
    //     return this.currentNews.list.filter(d => {
    //       return d.type === this.judgmentType
    //     })
    //   } else {
    //     return this.currentNews.list
    //   }
    // }
  },
  methods: {
    onNewsListSortChange (sortValue) {
      this.currentPage = 1
      this.currentSearchQuery.get.news['page-index'] = this.currentPage
      this.currentSearchQuery.get.news.sort = JSON.parse(sortValue)
      this.startDate = ''
      this.endDate = moment().format('YYYY-MM-DD')

      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isListLoading = false
          if (data.payload.news) {
            this.currentNews = data.payload.news
          }
        })
        .catch(e => {
          this.isListLoading = false
        })
    },
    clearFilterCondition () {
      // this.$refs.court.setCheckedNodes([])
      // this.$refs.JType.setCheckedNodes([])
      // this.$refs.people.setCheckedNodes([])
      // 清除條件
      this.currentSearchQuery.get.news.filter = []
      this.currentSearchQuery.get.news.time = {}
      this.adeptAllValue = []

      this.isFilterLoading = true
      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isFilterLoading = false
          this.isListLoading = false
          if (data.payload.news) {
            this.currentNews = data.payload.news
          }
        })
        .catch(e => {
          this.isFilterLoading = false
          this.isListLoading = false
        })
    },
    filterSearch () {
      const self = this
      
      // let searchRequest = {
      //   uniID: this.uniID,
      //   get: {
      //     news: {
      //       'page-index': this.currentPage,
      //       // time: {}
      //       filter: []
      //     }
      //   }
      // }
      // 查詢第1頁
      this.currentPage = 1
      this.currentSearchQuery.get.news['page-index'] = this.currentPage

      // -- 取得查詢參數 --
      let adeptAllData = returnAdeptAllData(this.adeptAllValue)
      if (adeptAllData.query.length) {
        this.currentSearchQuery.get.news.filter.push(adeptAllData)
      }
      // let negativeData = returnNegativeData(this.negativeValue)
      // if (negativeData.query.length) {
      //   this.currentSearchQuery.get.news.filter.push(negativeData)
      // }
      let companyData = returnCompanyData()
      if (companyData.query.length) {
        this.currentSearchQuery.get.news.filter.push(companyData)
      }
      let peopleData = returnPeopleData()
      if (peopleData.query.length) {
        this.currentSearchQuery.get.news.filter.push(peopleData)
      }
      let customData = returnCustomData(this.customValueArr)
      if (customData.query.length) {
        this.currentSearchQuery.get.news.filter.push(customData)
      }
      if (this.startDate && this.endDate) {
        this.$set(this.currentSearchQuery.get.news, 'time', {
          start: this.startDate,
          end: this.endDate
        })
      }

      
      this.isFilterLoading = true
      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isFilterLoading = false
          this.isListLoading = false
          if (data.payload.news) {
            this.currentNews = data.payload.news
          }
        })
        .catch(e => {
          this.isFilterLoading = false
          this.isListLoading = false
        })

      // adeptAll
      function returnAdeptAllData (adeptAllValue) {
        let filterQuery = [] // 查詢條件
        let _desc = '' // 顯示查詢條件文字
        let _keywords = []
        let adeptAllTextArr = []
        adeptAllValue.forEach(d => {
          let source = JSON.parse(d)
          filterQuery.push(source.queries)
          adeptAllTextArr.push(source.key)
        })
        if (adeptAllTextArr.length) {
          const text = adeptAllTextArr.join('、')
          _desc = `${codes.NEWS_FILTER_ITEM_DICT.adept_all}(${text})`
          _keywords = adeptAllTextArr
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc, // 查詢條件說明文字
          _keywords // 關鍵字
        }
      }

      // negative
      // function returnNegativeData (negativeValue) {
      //   let filterQuery = [] // 查詢條件
      //   let _desc = '' // 顯示查詢條件文字
      //   let _keywords = []
      //   let negativeTextArr = []
      //   negativeValue.forEach(d => {
      //     let source = JSON.parse(d)
      //     filterQuery.push(source.queries)
      //     negativeTextArr.push(source.key)
      //   })
      //   if (negativeTextArr.length) {
      //     const text = negativeTextArr.join('、')
      //     _desc = `${codes.NEWS_FILTER_ITEM_DICT.negative}(${text})`
      //     _keywords = negativeTextArr
      //   }
      //   return {
      //     method: 'should',
      //     query: filterQuery,
      //     _desc, // 查詢條件說明文字
      //     _keywords // 關鍵字
      //   }
      // }

      // company
      function returnCompanyData () {
        // 取得已勾選tree nodes
        const companyNodes = self.$refs.company.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let _desc = '' // 顯示查詢條件文字
        let companyTextArr = []
        companyNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            companyTextArr.push(d.source.key)
          }
        })
        if (companyTextArr.length) {
          const text = companyTextArr.join('、')
          _desc = `${codes.NEWS_FILTER_ITEM_DICT.company}(${text})`
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc // 查詢條件說明文字
        }
      }

      // people
      function returnPeopleData () {
        // 取得已勾選tree nodes
        const peopleNodes = self.$refs.people.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let _desc = '' // 顯示查詢條件文字
        let peopleTextArr = []
        peopleNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            peopleTextArr.push(d.source.key)
          }
        })
        if (peopleTextArr.length) {
          const text = peopleTextArr.join('、')
          _desc = `${codes.NEWS_FILTER_ITEM_DICT.people}(${text})`
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc // 查詢條件說明文字
        }
      }

      function returnCustomData (customValueArr) {
        // // 取得已勾選tree nodes
        // const customNodes = self.$refs.customs
        //   .map(d => d.getCheckedNodes())
        //   .flat()
        let filterQuery = [] // 查詢條件
        let _desc = '' // 顯示查詢條件文字
        let _keywords = []
        let textArr = []
        customValueArr
          .flat()
          .forEach(d => {
            let source = JSON.parse(d)
            filterQuery.push(source.queries)
            textArr.push(source.key)
          })
        if (textArr.length) {
          const text = textArr.join('、')
          _desc = `${codes.NEWS_FILTER_ITEM_DICT.negative}(${text})`
          _keywords = textArr
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc, // 查詢條件說明文字
          _keywords // 關鍵字
        }
      }
    },
    pageSearch () {
      // let searchRequest = {
      //   uniID: this.uniID,
      //   get: {
      //     news: {
      //       'page-index': this.currentPage,
      //       // time: {}
      //     }
      //   }
      // }
      this.currentSearchQuery.get.news['page-index'] = this.currentPage
      // if (this.startDate) {
      //   searchRequest.get.news.time.start = this.startDate
      // }
      // if (this.endDate) {
      //   searchRequest.get.news.time.end = this.endDate
      // }
      // case 公司查詢
      // if (this.uniID != null) {
        // searchRequest.uniID = this.uniID
        this.isListLoading = true
        apis.companyGet(this.currentSearchQuery)
          .then((data) => {
            this.isListLoading = false
            if (data.payload.news) {
              this.currentNews = data.payload.news
            }
          })
          .catch(e => {
            this.isListLoading = false
          })
      // }
      // case 人物查詢
    //   else if (this.field != null) {
    //     searchRequest.basic = {
    //       field: this.field,
    //       keyword: this.keyword
    //     }
    //     this.isLoading = true
    //     apis.searchGet(searchRequest)
    //       .then((data) => {
    //         this.isLoading = false
    //         if (data.payload.news) {
    //           this.currentNews = data.payload.news
    //         }
    //       })
    //       .catch(e => {
    //         this.isLoading = false
    //       })
    //   }
    },
    handleCheckAll (value, index) {
      if (value == true) {
        // this.$refs.customs[index].setCheckedKeys(this.customOptionArr[index].map(d => d.id))
        this.$set(this.customValueArr, index, this.customOptionArr[index].map(d => d.value))
      } else {
        // this.$refs.customs[index].setCheckedKeys([])
        this.$set(this.customValueArr, index, [])
      }
      
    }
  },
  async created () {
    await store.dispatch('actionRiskKeywordGroup')
    
    // 初始化自訂關鍵字的查詢條件
    this.currentSearchQuery.get.news['cls-custom'] = store.state.riskKeywordGroup.reduce((prev, current) => {
      if (current.switch == 0) {
        return prev
      }
      return {
        ...prev,
        [current.keyword_group_name]: current.keywords
      }
    }, {})
  }
}

</script>
