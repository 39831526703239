<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：經濟部國際貿易局</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentTrades.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="年度"
          width="120"
          prop="year">
        </el-table-column>
        <el-table-column
          label="總進口實績">
          <template slot-scope="scope">
            {{returnImportOrExportLabel(scope.row.import)}}
          </template>
        </el-table-column>
        <el-table-column
          label="總出口實績">
          <template slot-scope="scope">
            {{returnImportOrExportLabel(scope.row.export)}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="tradesSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
    <div v-show="tradesCount">
      <!-- <div
        id="tradesChart"
        ref="chart"
        style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
      </div> -->
      <TradesChart
        v-if="currentTrades.list.length"
        :basic="basic"
        :trades="currentTrades">
      </TradesChart>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import TradesChart from '@/components/TradesChart/TradesChart.vue'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.trades
      }
    },
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'trades': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  components: {
    TradesChart
  },
  data () {
    return {
      isLoading: false,
      currentTrades: {
        list: [],
        stat: {}
      },
      currentPage: 1,
      chartDom: null
    }
  },
  computed: {
    tradesCount () {
      if (this.currentTrades.stat['total-count']) {
        return this.currentTrades.stat['total-count']
      } else {
        return 0
      }
    },
    tradesSearchableCount () {
      if (this.currentTrades.stat['total-count']) {
        if (this.currentTrades.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentTrades.stat['total-count']
        }
      } else {
        return 0
      }
    },
    // chartOffsetHeight () {
    //   if (this.chartDom && this.chartDom.offsetHeight != null) {
    //     return this.chartDom.offsetHeight
    //   } else {
    //     return 0
    //   }
    // }
  },
  methods: {
    returnImportOrExportLabel (code) {
      return codes.IMPORT_AND_EXPORT_DATA[code].label
    },
    search () {
      let searchRequest = {
        get: {
          trades: {
            'page-index': this.currentPage
          }
        },
        uniID: this.uniID
      }
      if (this.startDate) {
        searchRequest.get.trades.time.start = this.startDate
      }
      if (this.endDate) {
        searchRequest.get.trades.time.end = this.endDate
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.trades) {
            this.currentTrades = data.payload.trades
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
    /*
    renderChart (currentTradeList) {
      // console.log(dom.offsetHeight)
      const chart = echarts.init(this.chartDom)

      const chartOptions = {
        title: {
          text: `${this.basic.company_name} 歷年實績統計`
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter (d) {
            // tooltip自訂格式
            let label0 = ''
            let label1 = ''
            for (let key in codes.IMPORT_AND_EXPORT_DATA) {
              if (codes.IMPORT_AND_EXPORT_DATA[key].chartValue === d[0].value) {
                label0 = codes.IMPORT_AND_EXPORT_DATA[key].label
                break
              }
            }
            for (let key in codes.IMPORT_AND_EXPORT_DATA) {
              if (codes.IMPORT_AND_EXPORT_DATA[key].chartValue === d[1].value) {
                label1 = codes.IMPORT_AND_EXPORT_DATA[key].label
                break
              }
            }
            return `${d[0].axisValue}年<br>${d[0].seriesName}: ${label0}<br>${d[1].seriesName}: ${label1}`
          }
        },
        legend: {
          data: ['進口實績', '出口實績'],
          top: '30px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '金額(單位:百萬美元)',
          // nameLocation: 'center',
          nameTextStyle: {
            padding: [28,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          name: '年份',
          nameTextStyle: {
            padding: [-8,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          data: currentTradeList.map(d => d.year).reverse()
        },
        series: [
          {
            name: '進口實績',
            type: 'bar',
            data: currentTradeList.map(d => {
              return codes.IMPORT_AND_EXPORT_DATA[d.import].chartValue || 0
            }).reverse()
          },
          {
            name: '出口實績',
            type: 'bar',
            data: currentTradeList.map(d => {
              return codes.IMPORT_AND_EXPORT_DATA[d.export].chartValue || 0
            }).reverse()
          }
        ]
      }

      chart.setOption(chartOptions)

      window.onresize = function () {
        chart.resize()
      }
      window.dispatchEvent(new Event('resize'))
    }
    */
  },
  mounted () {
    /*
    // 取得渲染圖表的dom
    this.chartDom = this.$refs.chart
    // 渲染圖表
    if (this.currentTrades && this.currentTrades.list && this.currentTrades.list.length) {
      this.renderChart(this.currentTrades.list)
    }
    */
  },
  watch: {
    'trades': {
      handler (d) {
        if (d) {
          this.currentTrades = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    },
    // 'currentTrades': {
    //   handler (d) {
    //     // 渲染圖表
    //     if (d && d.list && d.list.length) {
    //       this.renderChart(d.list)
    //     }
    //   }
    // }
  }
}
</script>
