export default {
  getVersion () {
    const version = localStorage.getItem('bp__dbs__relationshipChartVersion')
    if (version) {
      return Number(version)
    } else {
      return 1 // default
    }
  },
  setVersion (d) {
    localStorage.setItem('bp__dbs__relationshipChartVersion', String(d))
  }
}