<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源： 經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentFactorys.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="工廠名稱"
          width="300"
          prop="factory_name">
        </el-table-column>
        <el-table-column
          label="工廠登記核准日期"
          width="160"
          prop="date_approved">
        </el-table-column>
        <el-table-column
          label="工廠登記狀態"
          width="120"
          prop="factory_state">
        </el-table-column>
        <el-table-column
          label="主要產品"
          prop="main_product">
        </el-table-column>
        <el-table-column
          label="工廠地址"
          prop="factory_address">
        </el-table-column>
        
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="factorysSearchableCount"
        @current-change="changeFactorysPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as config from '@/const/config.js'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.factorys
      }
    },
    'factorys': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentFactorys: {
        list: [],
        stat: {}
      }
    }
  },
  computed: {
    factorysCount () {
      if (this.currentFactorys.stat) {
        return this.currentFactorys.stat['total-count']
      } else {
        return 0
      }
    },
    factorysSearchableCount () {
      if (this.currentFactorys.stat) {
        if (this.currentFactorys.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentFactorys.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    changeFactorysPage (page) {
      const searchRequest = {
        get: {
          factorys: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.factorys) {
            this.currentFactorys = data.payload.factorys
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'factorys': {
      handler (d) {
        if (d) {
          this.currentFactorys = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
