export const getSVGGSize = (svgGroup: any) => {
  let width = 0
  let height = 0
  let x = 0
  let y = 0
  try {
    const getBBox = svgGroup.getBBox()
    width = getBBox.width
    height = getBBox.height
  } catch (e) { console.error(e) }
  return { width, height, x, y }
}

export const getElementSize = (el: any) => {
  try {
    return el.getBoundingClientRect()
  } catch (e) { console.error(e) }
  return { width: 0, height: 0, x: 0, y: 0 }
}

// // 取得 transform中的 scale數值
// export const getTransformScale = (element: Element): number => {
//   const transformStr = element.getAttribute('transform') ?? ''
//   const re = /(\w+)\(([^)]*)\)/g
//   const obj: any = {}
//   let m: any
//   while (m = re.exec(transformStr)) {
//     obj[m[1]] = m[2]
//   }
//   return obj.scale ?? 1
// }

// export const getTransformTranslate = (element: Element): [number,number] => {
//   const transformStr = element.getAttribute('transform') ?? ''
//   const re = /(\w+)\(([^)]*)\)/g
//   const obj: any = {}
//   let m: any
//   while (m = re.exec(transformStr)) {
//     obj[m[1]] = m[2]
//   }
//   const translate = obj.translate ?? '0,0'
//   return translate.split(',')
// }

// 取得 transform中的值
// e.g.
// transform屬性值 "translate(10,20) scale(1.2)" 回傳 { translate: "10,20", scale: "1.2" }
export const getTransform = (element: Element): {[key:string]:string} => {
  if (!element.getAttribute) {
    return {}
  }
  const transformStr = element.getAttribute('transform') ?? ''
  const re = /(\w+)\(([^)]*)\)/g
  const obj: any = {}
  let m: any
  while (m = re.exec(transformStr)) {
    obj[m[1]] = m[2]
  }
  return obj
}