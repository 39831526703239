import { chartScoutRoute } from '@bpchart/d3-modules'

export type FilterConfig = {
  stockExceptZero: boolean; // 不顯示0持股
  stockMin: number; // 最小持股百分比
}

export const defaultFilterValue = 'all'

export const filterOptions = [
  {
    label: '全部顯示',
    value: 'all'
  },
  {
    label: '0% 不顯示',
    value: 'none0'
  },
  {
    label: '顯示持股 1% 以上路徑',
    value: 'noneUnder1'
  },
  {
    label: '顯示持股 5% 以上路徑',
    value: 'noneUnder5'
  },
  {
    label: '顯示持股 25% 以上路徑',
    value: 'noneUnder25'
  },
]

export const createTreeChartFilter = (value: string): FilterConfig => {
  if (value === 'all') {
    return {
      stockExceptZero: false,
      stockMin: 0
    }
  } else if (value === 'none0') {
    return {
      stockExceptZero: true,
      stockMin: 0
    }
  } else if (value === 'noneUnder1') {
    return {
      stockExceptZero: true,
      stockMin: 1
    }
  } else if (value === 'noneUnder5') {
    return {
      stockExceptZero: true,
      stockMin: 5
    }
  } else if (value === 'noneUnder25') {
    return {
      stockExceptZero: true,
      stockMin: 25
    }
  }
  return {
    stockExceptZero: false,
    stockMin: 0
  }
}

export const createLineChartFilter = (filterValue: string, sortValue: '1' | '-1'): chartScoutRoute.ChartScoutRouteLinesFilterConfig => {
  const { stockMin, stockExceptZero } = createTreeChartFilter(filterValue)
  return {
    stockMin,
    stockExceptZero,
    sort: {
      'routes-of-stock-detail-single-value': sortValue as any,
      'routes_to_downs-of-stock-detail-single-value': sortValue as any,
    }
  }
}