<template>
  <div>
    <ul
      :key="index"
      v-for="(item,index) in list">
      <li v-if="item.name">
        姓名：<span :class="{[emphasizedClass]:item.nameIsDiff}">{{item.name}}</span>
      </li>
      <li v-if="item.title">
        職稱：<span :class="{[emphasizedClass]:item.titleIsDiff}">{{item.title}}</span>
      </li>
      <li v-if="item.contribution">
        出資額(元)<span :class="{[emphasizedClass]:item.contributionIsDiff}">{{item.contribution | numberWithComma}}</span>
      </li>
      <li v-if="item.stocks">
        持有股份數：<span :class="{[emphasizedClass]:item.stocksIsDiff}">{{item.stocks | numberWithComma}}</span>
      </li>
      <li v-if="item.ROL">
        所代表法人：<span :class="{[emphasizedClass]:item.ROLIsDiff}">{{item.ROL}}</span>
      </li>
      <!-- 最後一個分隔線不顯示 -->
      <el-divider v-if="index<list.length-1"></el-divider>
    </ul>
    <ul v-if="!list.length">
      <li>-</li>
    </ul>
  </div>
</template>

<script>
import * as companysHistoryUtils from '@/utils/companysHistoryUtils.ts'

export default {
  props: {
    'value': {
      required: true
    },
    'compareValue': {
      required: false
    }
  },
  data () {
    return {
      list: [],
      // emphasizedClass: 'color-primary',
      emphasizedClass: 'color-danger',
      // emphasizedClass: 'color-warning',
      // emphasizedClass: 'color-success',
    }
  },
  created () {
    let list = JSON.parse(this.value)
    let compareList = this.compareValue != null ? JSON.parse(this.compareValue) : null
    
    if (compareList) {
      // -- 去除空白 --
      // list.forEach(data => {
      //   for (let i in data) {
      //     if (data[i] != null && typeof data[i] == 'string') {
      //       data[i].trim()
      //     }
      //   }
      // })
      // compareList.forEach(data => {
      //   for (let i in data) {
      //     if (data[i] != null && typeof data[i] == 'string') {
      //       data[i].trim()
      //     }
      //   }
      // })

      // -- 原陣列資料裡比對各欄位有異動的資料並註記 --
      this.list = companysHistoryUtils.parsePeopleDetail(list, compareList)
    } else {
      // 如無比較資料則不需加上註記
      this.list = list
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-divider.el-divider--horizontal {
  margin: 0px;
}
</style>
