





































































import { defineComponent, SetupContext, ref, computed, Ref, ComputedRef, WritableComputedRef, watch, onMounted } from '@vue/composition-api'
import { D3ModuleContainer } from '@bpchart/vue'
import { chartScoutRoute } from '@bpchart/d3-modules'
import { lineParams } from './chartDefaults'
import { filterOptions, defaultFilterValue, createTreeChartFilter, createLineChartFilter, FilterConfig } from './filterOptions'
import AppSelectedList from '@/components/app/AppSelectList.vue'
import { formatPercentage } from '@/utils/text'

type Props = {
  uniID: string;
  description: string;
  companyName: string;
  chartData: chartScoutRoute.ChartScoutRouteLinesDataset;
  filterValue: string;
  sortValue: '1' | '-1';
  autoZoom: boolean;
}

declare class ResizeObserver {
  constructor (callback: (entries: any, observer: any) => void)
  observe(target: Element): void;
  unobserve(target: Element): void;
  disconnect(): void;
}

type ContentRect = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

const sortOptions = [
  {
    label: '持股路徑由大至小',
    value: '-1'
  },
  {
    label: '持股路徑由小至大',
    value: '1'
  }
]

export const defaultSortValue = '-1'

export default defineComponent({
  props: {
    'uniID': {
      type: String,
      required: true
    },
    'description': {
      type: String,
      required: true
    },
    chartData: {
      type: Object as () => chartScoutRoute.ChartScoutRouteTreeDataset,
      default () {
        return {
          nodes: [],
          edges: [],
          rootId: ''
        }
      }
    },
    filterValue: {
      type: String
    },
    sortValue: {
      type: String as () => '1' | '-1'
    },
    // 螢幕變換尺寸時 zoom還原預設（用於縮放全螢幕時）
    autoZoom: {
      type: Boolean
    },
    screenShotMode: {
      default () {
        return false
      }
    }
  },
  components: {
    AppSelectedList,
    D3ModuleContainer
  },
  setup (props: Props, { root, emit }: SetupContext) {
    
    const syncSortValue: WritableComputedRef<'1' | '-1'> = computed({
      get () {
        return props.sortValue
      },
      set (v) {
        emit('update:sortValue', v)
      }
    })

    const syncFilterValue: WritableComputedRef<string> = computed({
      get () {
        return props.filterValue
      },
      set (v) {
        emit('update:filterValue', v)
      }
    })

    const chartParams = computed(() => {
      return {
        ...lineParams,
        autoZoom: props.autoZoom
      }
    })

    const chartFilter: ComputedRef<chartScoutRoute.ChartScoutRouteLinesFilterConfig> = computed(() => {
      return createLineChartFilter(props.filterValue, props.sortValue)
    })

    const height: Ref<string> = ref('')

    const GResizeObserver = new ResizeObserver((entries: any, observer: any) => {
      const entry = entries[0]
      const contentRect: ContentRect = entry.contentRect
      if (contentRect && contentRect.height > 0) {
        height.value = contentRect.height + 100 + 'px'
      }
    })

    onMounted(() => {
      setTimeout(() => {
        const lineChartG = document.querySelector(`#chart-${props.uniID} .chart-scout-route__group`)
        GResizeObserver!.observe(lineChartG!)
      }) // @Q@ 經過測試需要加上 setTimeout才不會有 bug，但也不太確定為什麼
    })

    return {
      ChartScoutRouteLines: chartScoutRoute.ChartScoutRouteLines,
      chartParams,
      filterOptions,
      sortOptions,
      syncSortValue,
      syncFilterValue,
      forceFit: true,
      chartFilter,
      height,
      handleLineSortChange (sortSelectValue: any) {
        syncSortValue.value = sortSelectValue
      },
      handleLineFilterSelectChange (filterSelectValue: '1' | '-1') {
        syncFilterValue.value = filterSelectValue
      },
    }
  }
})
