<template>
  <div class="page-grid">
    <div class="page-grid-left" v-loading="isFilterLoading">
      <div class="aside-filter">
        <div class="aside-filter-container">
          <div class="aside-filter__box">
            <div class="aside-filter-title">分類篩選</div>
            <div class="aside-filter-content">
              <!-- 審判法院 -->
              <div class="filter-group">
                <div class="filter-group-title">{{VERDICTS_FILTER_ITEM_DICT.court}}</div>
                <el-tree
                  ref="court"
                  show-checkbox
                  node-key="id"
                  :data="courtOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree>
              </div>
              <!-- 案件類型 -->
              <div class="filter-group">
                <div class="filter-group-title">{{VERDICTS_FILTER_ITEM_DICT.JType}}</div>
                <el-tree
                  ref="JType"
                  show-checkbox
                  node-key="id"
                  :data="JTypeOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree>
              </div>
              <!-- 提及人物 -->
              <div class="filter-group">
                <div class="filter-group-title">{{VERDICTS_FILTER_ITEM_DICT.people}}</div>
                <el-tree
                  ref="people"
                  show-checkbox
                  node-key="id"
                  :data="peopleOptions"
                  :props="defaultProps">
                  <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                    <span class="label">{{ data.label }}</span>
                    <span class="count">{{ data.source.doc_count }}</span>
                  </span>
                </el-tree>
              </div>
              <!-- 時間範圍 -->
              <div class="filter-group">
                <div class="filter-group-title">{{VERDICTS_FILTER_ITEM_DICT.time}}</div>
                <el-row>
                  <el-col style="color:#606266;font-size:13px;text-align:left;line-height:30px">
                    起始時間
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-date-picker
                      v-model="startDate"
                      type="date"
                      placeholder="請選擇起始時間"
                      value-format="yyyy-MM-dd"
                      style="100%">
                    </el-date-picker>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col style="color:#606266;font-size:13px;text-align:left;line-height:30px">
                    結束時間
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-date-picker
                      v-model="endDate"
                      type="date"
                      placeholder="請選擇結束時間"
                      value-format="yyyy-MM-dd"
                      style="100%">
                    </el-date-picker>
                  </el-col>
                  <!-- {{capitalAmountSliderOptions[capitalAmountSliderValue[0]].label}} - {{capitalAmountSliderOptions[capitalAmountSliderValue[1]].label}} -->
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="aside-filter-footer">
          <el-button
            type="primary"
            @click="filterSearch">
            篩選
          </el-button>
          <el-button
            @click="clearFilterCondition">
            清除篩選條件
          </el-button>
        </div>
      </div>
    </div>
    <div class="page-grid-right">
      <div class="company-container">
        <section>
          <h4 class="section-title">
            {{title}}
            <span class="section-sub-title">共為您搜尋到 {{verdictsCount}} 筆資料</span>
            <span class="section-sub-title">資料來源： 司法院</span>
          </h4>
          <el-popover
            trigger="click"
            width="500"
            v-if="verdictsSearchCondition.length">
            <p
              v-for="(text,index) in verdictsSearchCondition"
              :key="index">
              {{text}}
            </p>
            <el-button slot="reference" type="text" size="mini">查看目前篩選條件</el-button>
          </el-popover>
          <div class="article-list-set">
            <div class="article-list-toolbar">
              <div class="toolbar-left">
                <el-select
                  size="mini"
                  placeholder="排序"
                  v-model="verdictsListSort"
                  @change="onVerdictsListSortChange">
                  <el-option
                    v-for="item in verdictsListSortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="toolbar-right">
                <el-pagination
                  layout="prev, pager, next, jumper"
                  :total="verdictsSearchableCount"
                  :current-page.sync="currentPage"
                  @current-change="pageSearch">
                </el-pagination>
              </div>
            </div>
            <ul class="article-list" v-loading="isListLoading">
              <VerdictsCard
                v-for="(verdict,index) in currentVerdicts.list"
                :key="index"
                :verdict="verdict">
              </VerdictsCard>
            </ul>
            <div class="align-right">
              <el-pagination
                layout="prev, pager, next, jumper"
                :total="verdictsSearchableCount"
                :current-page.sync="currentPage"
                @current-change="pageSearch">
              </el-pagination>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
// import searchTypeOptions from '@/const/searchTypeOptions.js'
// import judgmentTypeOptions from '@/const/judgmentTypeOptions.js'

import VerdictsCard from './VerdictsCard.vue'

const verdictsListSortOptions = [
  {
    label: '依時間由近至遠',
    value: '{"key":"JDate","order":"desc"}',
    data: {"key":"JDate","order":"desc"}
  },
  {
    label: '依時間由遠至近',
    value: '{"key":"JDate","order":"asc"}',
    data: {"key":"JDate","order":"asc"}
  },
]

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.verdicts
      }
    },
    'verdicts': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    // case 公司查詢
    'basic': {
      required: false,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      // required: false
      required: true
    },
    // case 人物查詢
    // 'field': {
    //   required: false
    // },
    // 'keyword': {
    //   required: false
    // }
  },
  components: {
    VerdictsCard
  },
  data () {
    return {
      VERDICTS_FILTER_ITEM_DICT: codes.VERDICTS_FILTER_ITEM_DICT,
      verdictsListSortOptions,
      // searchTypeOptions,
      isFilterLoading: false,
      isListLoading: false,
      // judgmentTypeOptions,
      currentSearchQuery: {
        uniID: this.uniID,
        get: {
          verdicts: {
            'page-index': 1,
            sort: JSON.parse(verdictsListSortOptions[0].value),
            time: {},
            filter: []
          }
        }
      },
      currentVerdicts: {
        list: [],
        stat: {}
      },
      // startDate: '',
      // endDate: moment().format('YYYY-MM-DD'),
      // searchType: '',
      // judgmentType: '',
      currentPage: 1,
      defaultProps: {},
      verdictsListSort: verdictsListSortOptions[0].value,
      // 後分類篩選選單
      courtOptions: [],
      JTypeOptions: [],
      peopleOptions: [],
      courtValue: [],
      JTypeValue: [],
      peopleValue: [],
      startDate: '',
      endDate: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    verdictsCount () {
      if (this.currentVerdicts.stat['total-count']) {
        return this.currentVerdicts.stat['total-count']
      } else {
        return 0
      }
    },
    verdictsSearchableCount () {
      if (this.currentVerdicts.stat['total-count']) {
        if (this.currentVerdicts.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentVerdicts.stat['total-count']
        }
      } else {
        return 0
      }
    },
    verdictsSearchCondition () {
      let descList = []
      if (this.currentSearchQuery.get.verdicts.time &&
      this.currentSearchQuery.get.verdicts.time.start &&
      this.currentSearchQuery.get.verdicts.time.end) {
        descList.push(`時間範圍：${this.currentSearchQuery.get.verdicts.time.start}~${this.currentSearchQuery.get.verdicts.time.end}`)
      }
      if (this.currentSearchQuery.get.verdicts.filter.length) {
        descList = this.currentSearchQuery.get.verdicts.filter.map(d => d._desc)
      }
      return descList
    }
    // currentVerdictsList () {
    //   if (this.currentVerdicts.list.length && this.judgmentType) {
    //     return this.currentVerdicts.list.filter(d => {
    //       return d.type === this.judgmentType
    //     })
    //   } else {
    //     return this.currentVerdicts.list
    //   }
    // }
  },
  methods: {
    onVerdictsListSortChange (sortValue) {
      this.currentPage = 1
      this.currentSearchQuery.get.verdicts['page-index'] = this.currentPage
      this.currentSearchQuery.get.verdicts.sort = JSON.parse(sortValue)

      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isListLoading = false
          if (data.payload.verdicts) {
            this.currentVerdicts = data.payload.verdicts
          }
        })
        .catch(e => {
          this.isListLoading = false
        })
    },
    clearFilterCondition () {
      // this.$refs.court.setCheckedNodes([])
      // this.$refs.JType.setCheckedNodes([])
      // this.$refs.people.setCheckedNodes([])
      this.currentSearchQuery.get.verdicts.filter = []
      this.currentSearchQuery.get.verdicts.time = {}

      this.isFilterLoading = true
      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isFilterLoading = false
          this.isListLoading = false
          if (data.payload.verdicts) {
            this.currentVerdicts = data.payload.verdicts
          }
        })
        .catch(e => {
          this.isFilterLoading = false
          this.isListLoading = false
        })
    },
    filterSearch () {
      const self = this
      this.currentPage = 1
      this.currentSearchQuery.get.verdicts['page-index'] = this.currentPage
      // let searchRequest = {
      //   uniID: this.uniID,
      //   get: {
      //     verdicts: {
      //       'page-index': this.currentPage,
      //       // time: {}
      //       filter: []
      //     }
      //   }
      // }

      let courtData = returnCourtData()
      if (courtData.query.length) {
        this.currentSearchQuery.get.verdicts.filter.push(courtData)
      }
      let JTypeData = returnJTypeData()
      if (JTypeData.query.length) {
        this.currentSearchQuery.get.verdicts.filter.push(JTypeData)
      }
      let peopleData = returnPeopleData()
      if (peopleData.query.length) {
        this.currentSearchQuery.get.verdicts.filter.push(peopleData)
      }
      if (this.startDate && this.endDate) {
        this.$set(this.currentSearchQuery.get.verdicts, 'time', {
          start: this.startDate,
          end: this.endDate
        })
      }

      this.isFilterLoading = true
      this.isListLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isFilterLoading = false
          this.isListLoading = false
          if (data.payload.verdicts) {
            this.currentVerdicts = data.payload.verdicts
          }
        })
        .catch(e => {
          this.isFilterLoading = false
          this.isListLoading = false
        })

      // court
      function returnCourtData () {
        // 取得已勾選tree nodes
        const courtNodes = self.$refs.court.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let courtTextArr = []
        courtNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            courtTextArr.push(d.source.key)
          }
        })
        if (courtTextArr.length) {
          const text = courtTextArr.join('、')
          infoTextArr.push(`${codes.VERDICTS_FILTER_ITEM_DICT.court}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }

      // JType
      function returnJTypeData () {
        // 取得已勾選tree nodes
        const JTypeNodes = self.$refs.JType.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let JTypeTextArr = []
        JTypeNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            JTypeTextArr.push(d.source.key)
          }
        })
        if (JTypeTextArr.length) {
          const text = JTypeTextArr.join('、')
          infoTextArr.push(`${codes.VERDICTS_FILTER_ITEM_DICT.JType}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }

      // people
      function returnPeopleData () {
        // 取得已勾選tree nodes
        const peopleNodes = self.$refs.people.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let peopleTextArr = []
        peopleNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            peopleTextArr.push(d.source.key)
          }
        })
        if (peopleTextArr.length) {
          const text = peopleTextArr.join('、')
          infoTextArr.push(`${codes.VERDICTS_FILTER_ITEM_DICT.people}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
    },
    pageSearch () {
      // let searchRequest = {
      //   uniID: this.uniID,
      //   get: {
      //     verdicts: {
      //       'page-index': this.currentPage,
      //       // time: {}
      //     }
      //   }
      // }
      this.currentSearchQuery.get.verdicts['page-index'] = this.currentPage
      // if (this.startDate) {
      //   searchRequest.get.verdicts.time.start = this.startDate
      // }
      // if (this.endDate) {
      //   searchRequest.get.verdicts.time.end = this.endDate
      // }
      // case 公司查詢
      // if (this.uniID != null) {
        // searchRequest.uniID = this.uniID
        this.isListLoading = true
        apis.companyGet(this.currentSearchQuery)
          .then((data) => {
            this.isListLoading = false
            if (data.payload.verdicts) {
              this.currentVerdicts = data.payload.verdicts
            }
          })
          .catch(e => {
            this.isListLoading = false
          })
      // }
      // case 人物查詢
    //   else if (this.field != null) {
    //     searchRequest.basic = {
    //       field: this.field,
    //       keyword: this.keyword
    //     }
    //     this.isLoading = true
    //     apis.searchGet(searchRequest)
    //       .then((data) => {
    //         this.isLoading = false
    //         if (data.payload.verdicts) {
    //           this.currentVerdicts = data.payload.verdicts
    //         }
    //       })
    //       .catch(e => {
    //         this.isLoading = false
    //       })
    //   }
    }
  },
  watch: {
    'verdicts': {
      handler (d) {
        if (d) {
          this.currentVerdicts = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    },
    'currentVerdicts': {
      handler (d) {
        // -- 後分類選單 --
        if (this.currentVerdicts.cls) {
          // 審判法院
          if (this.currentVerdicts.cls.court) {
            this.courtOptions = this.currentVerdicts.cls.court.map(d => {
              let children = null
              if (d.sub) {
                children = d.sub.map(subData => {
                  return {
                    id: subData.key,
                    label: subData.key,
                    source: subData
                  }
                })
              }
              return {
                id: d.key,
                label: d.key,
                children,
                source: d
              }
            })
          } else {
            this.courtOptions = []
          }
          // 案件類型
          if (this.currentVerdicts.cls.JType) {
            this.JTypeOptions = this.currentVerdicts.cls.JType.map(d => {
              let children = null
              if (d.sub) {
                children = d.sub.map(subData => {
                  return {
                    id: subData.key,
                    label: subData.key,
                    source: subData
                  }
                })
              }
              return {
                id: d.key,
                label: d.key,
                children,
                source: d
              }
            })
          } else {
            this.JTypeOptions = []
          }
          // 提及人物
          if (this.currentVerdicts.cls.people) {
            this.peopleOptions = this.currentVerdicts.cls.people.map(d => {
              let children = null
              if (d.sub) {
                children = d.sub.map(subData => {
                  return {
                    id: subData.key,
                    label: subData.key,
                    source: subData
                  }
                })
              }
              return {
                id: d.key,
                label: d.key,
                children,
                source: d
              }
            })
          } else {
            this.peopleOptions = []
          }
        }
      },
      immediate: true
    }
  }
}
</script>
