





















































































import { defineComponent, SetupContext, ref, computed, Ref, WritableComputedRef, watch, onMounted } from '@vue/composition-api'
import ChartCardDrawer from '@/components/charts/ChartCardDrawer.vue'
import { chartScoutRoute } from '@bpchart/d3-modules'
import { filterOptions, createTreeChartFilter } from './filterOptions'
import { getRouterCount } from '@/utils/parseGoData'
import { formatNumberWithComma } from '@/utils/text'

type Props = {
  visible: boolean;
  value: DrawerData;
  filterValue: boolean;
}

export type DrawerData = {
  uniID: string;
  label: string;
  tags: Array<{
    backgroundColor: string;
    label: string;
    textColor: string;
    tooltip: string;
  }>;
  totalInvestmentRatio: string;
  routesNumber: number;
  routesNumberFiltered: number; // 1%以下路徑數
  shareholders: Array<{
    name: string;
    stocks: number;
    percentage: number;
    title: string;
    ROL: string;
  }>;
  nodeData: chartScoutRoute.SourceNode | {};
  sourceNodeData: chartScoutRoute.SourceNode | {};
  mainRelatedToRoot: 'root' | 'up' | 'down' | 'neibor'
}

export const createDrawerData = (): DrawerData => {
  return {
    uniID: '',
    label: '',
    tags: [],
    totalInvestmentRatio: '',
    routesNumber: 0,
    routesNumberFiltered: 0,
    shareholders: [],
    nodeData: {},
    sourceNodeData: {},
    mainRelatedToRoot: 'up'
  }
}

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object as () => DrawerData,
      default () {
        return createDrawerData()
      }
    },
    filterValue: {
      
    },
    isRoot: {
      default: () => false
    }
  },
  components: {
    ChartCardDrawer
  },
  setup (props: Props, { root, emit }: SetupContext) {
    const syncValue: WritableComputedRef<DrawerData> = computed({
      get (): DrawerData {
        return props.value
      },
      set (v: DrawerData) {
        emit('input', v)
      }
    })
    const syncFilterValue: WritableComputedRef<boolean> = computed({
      get () {
        return props.filterValue
      },
      set (v: boolean) {
        emit('update:filterValue', v)
      }
    })
    const syncVisible: WritableComputedRef<boolean> = computed({
      get () {
        return props.visible
      },
      set (v) {
        emit('update:visible', v)
      }
    })

    return {
      formatNumberWithComma,
      filterOptions,
      syncValue,
      syncFilterValue,
      syncVisible,
      handleDrawerClosed () {
        syncVisible.value = !syncVisible.value
        syncValue.value = createDrawerData()
      },
      handleDrawerFilterChange (value: string) {
        const nodeData = (syncValue.value.nodeData as chartScoutRoute.SourceNode)
        // 計算篩選路徑的筆數
        if ((!nodeData.roles_related_to_root || nodeData.roles_related_to_root.includes('up'))
        && nodeData['routes-of-stock-detail-single-value']) {
          const { stockMin, stockExceptZero } = createTreeChartFilter(value)
          const count = getRouterCount((syncValue.value.sourceNodeData as chartScoutRoute.SourceNode)['routes-of-stock-detail-single-value']!, stockMin, stockExceptZero)
          root.$set(syncValue.value, 'routesNumberFiltered', count)
        } else if (nodeData.roles_related_to_root.includes('down')
        && nodeData['routes_to_downs-of-stock-detail-single-value']) {
          const { stockMin, stockExceptZero } = createTreeChartFilter(value)
          const count = getRouterCount((syncValue.value.sourceNodeData as chartScoutRoute.SourceNode)['routes_to_downs-of-stock-detail-single-value']!, stockMin, stockExceptZero)
          root.$set(syncValue.value, 'routesNumberFiltered', count)
        }
      },
      openLineChart (uniID: string) {
        emit('openLineChart', uniID)
      },
      returnCompanyLinkTo (keyword: string, keywordType = 'scout') {
        if (!keyword) {
          return null
        }
        return {
          name: 'list',
          query: {
            keyword,
            keywordtype: keywordType
          }
        }
      },
    }
  }
})
