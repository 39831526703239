<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：公開資訊觀測站</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="期別"
          width="180"
          prop="title1">
        </el-table-column>
        <el-table-column
          label="期別"
          prop="title2">
        </el-table-column>
        <el-table-column
          :label="thisYearSeasonTitle"
          width="180"
          prop="thisYearSeason">
        </el-table-column>
        <el-table-column
          :label="lastYearSeasonTitle"
          width="180"
          prop="lastYearSeason">
        </el-table-column>
        <el-table-column
          :label="thisYearTitle"
          width="180"
          prop="thisYear">
        </el-table-column>
        <el-table-column
          :label="lastYearTitle"
          width="180"
          prop="lastYear">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import echarts from 'echarts'
import * as apis from '@/apis/index.ts'
// import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
// import * as config from '@/const/config.js'
// import ifrssTitleList from '@/const/ifrssTitleList.js'

const ifrssTitleList = [
  {
    title1: '簡明負債資產',
    title2: '資產總計',
    title1Span: {
      rowspan: 4,
      colspan: 1
    }
  },
  {
    title1: '',
    title2: '負債總計',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '',
    title2: '權益總計',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '',
    title2: '每股淨值(元)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '簡明綜合損益表',
    title2: '營業收入',
    title1Span: {
      rowspan: 4,
      colspan: 1
    }
  },
  {
    title1: '',
    title2: '營業利益(損失)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '',
    title2: '稅前淨利(淨損)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '',
    title2: '基本每股盈餘(元)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '簡明現金流量表',
    title2: '營業活動之淨現金流入(流出)',
    title1Span: {
      rowspan: 3,
      colspan: 1
    }
  },
  {
    title1: '',
    title2: '投資活動之淨現金流入(流出)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  },
  {
    title1: '',
    title2: '籌資活動之淨現金流入(流出)',
    title1Span: {
      rowspan: 0,
      colspan: 0
    }
  }
]

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.ifrss_summary
      }
    },
    'ifrss_summary': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      thisYearSeasonTitle: '',
      lastYearSeasonTitle: '',
      thisYearTitle: '',
      lastYearTitle: '',
      tableData: []
    }
  },
  methods: {
    // formatNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    returnImportOrExportLabel (code) {
      return codes.IMPORT_AND_EXPORT_DATA[code].label
    },
    search () {
      let searchRequest = {
        get: {
          ifrss_summary: {
            'page-index': this.currentPage
          }
        },
        uniID: this.uniID
      }
      if (this.startDate) {
        searchRequest.get.ifrss_summary.time.start = this.startDate
      }
      if (this.endDate) {
        searchRequest.get.ifrss_summary.time.end = this.endDate
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.ifrss_summary) {
            this.currentRevenueHistory = data.payload.ifrss_summary
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (ifrssTitleList[rowIndex].title1Span) {
          return ifrssTitleList[rowIndex].title1Span
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  },
  watch: {
    'ifrss_summary': {
      handler (d) {
        if (d && d.list && d.list[0] && d.list[0].view) {
          // -- 組出表格資料 --
          const viewData = JSON.parse(d.list[0].view)
          const colOrder = viewData.col_order // 欄位排序資料
          this.tableData = ifrssTitleList.map(d => {
            const findItem = viewData[d.title2]
            return {
              title1: d.title1,
              title2: d.title2,
              thisYearSeason: findItem[colOrder[0]],
              lastYearSeason: findItem[colOrder[1]],
              thisYear: findItem[colOrder[2]],
              lastYear: findItem[colOrder[3]]
            }
          })
          this.thisYearSeasonTitle = colOrder[0]
          this.lastYearSeasonTitle = colOrder[1]
          this.thisYearTitle = colOrder[2]
          this.lastYearTitle = colOrder[3]
        }
      },
      immediate: true
    }
  }
}
</script>
