<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">共為您搜尋到 {{itemsCount}} 筆資料</span>
      <span class="section-sub-title">資料來源：公開資料觀測站</span>
    </h4>
    <div class="table-set">
      <!-- <div class="table-toolbar">
        <div class="toolbar-left">
          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="起始日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <span class="connect">至</span>
          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="結束日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <!- - <el-select class="toolbar-input" v-model="searchType" placeholder="搜尋方式" size="mini">
            <el-option
              v-for="item in searchTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="search">
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="@Q@ 其實我也不知道這個是什麼" placement="bottom">
            <font-awesome-icon :icon="['far', 'question-circle']" style="vertical-align:middle;margin-right: 4px;"/>
          </el-tooltip> - ->
        </div>
      </div> -->
      <el-table
        :data="currentData.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="日期"
          width="180">
          <template slot-scope="scope">
            {{scope.row.date_pub | emptyFormat}}
          </template>
        </el-table-column>
        <el-table-column
          label="主旨">
          <template slot-scope="scope">
            <a
              target="_blank"
              v-if="scope.row.uri"
              :href="scope.row.uri">
              {{scope.row.title | emptyFormat}}
            </a>
            <div
              v-else>
              {{scope.row.title | emptyFormat}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="searchTableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
// import searchTypeOptions from '@/const/searchTypeOptions.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.publics_news
      }
    },
    'publics_news': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      // searchTypeOptions,
      isLoading: false,
      currentData: {
        list: [],
        stat: {}
      },
      startDate: '',
      endDate: moment().format('YYYY-MM-DD'),
      searchType: '',
      currentPage: 1
    }
  },
  computed: {
    itemsCount () {
      if (this.currentData.stat['total-count']) {
        return this.currentData.stat['total-count']
      } else {
        return 0
      }
    },
    searchTableCount () {
      if (this.currentData.stat['total-count']) {
        if (this.currentData.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentData.stat['total-count']
        }
      } else {
        return 0
      }
    },
  },
  methods: {
    search () {
      let searchRequest = {
        get: {
          publics_news: {
            'page-index': this.currentPage,
            time: {}
          }
        },
        uniID: this.uniID
      }
      // if (this.startDate) {
      //   searchRequest.get.publics_news.time.start = this.startDate
      // }
      // if (this.endDate) {
      //   searchRequest.get.publics_news.time.end = this.endDate
      // }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.publics_news) {
            this.currentData = data.payload.publics_news
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'publics_news': {
      handler (d) {
        if (d) {
          this.currentData = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
