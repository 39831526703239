<template>
  <div>
    <span :class="{[emphasizedClass]:isEmphasized}">
      {{value | emptyFormat | numberWithComma}}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    'value': {
      required: true
    },
    'compareValue': {
      required: false
    }
  },
  data () {
    return {
      // emphasizedClass: 'color-primary',
      emphasizedClass: 'color-danger',
      // emphasizedClass: 'color-warning',
      // emphasizedClass: 'color-success',
    }
  },
  computed: {
    isEmphasized () {
      if (this.compareValue != null && this.value != this.compareValue) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-divider.el-divider--horizontal {
  margin: 0px;
}
</style>
