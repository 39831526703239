<template>
  <div>
    <div class="separate-title">最近查詢</div>
    <ul
      style="overflow: auto"
      class="list list--aside list--aside-links">
      <li
        class="item"
        :key="item.keyword"
        v-for="item in recentQuery">
        <router-link
          class="link"
          target="_blank"
          :to="returnRouterLinkTo(item)">
          {{item.keyword}}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import recentQueryStorage from '@/storage/recentQueryStorage.js'

export default {
  data () {
    return {
      recentQuery: []
    }
  },
  methods: {
    returnRouterLinkTo (item) {
      return {
        name: item.name,
        params: item.params,
        query: item.query
      }
    }
  },
  created () {
    // 最近查詢
    this.recentQuery = recentQueryStorage.getAll()
  }
}
</script>
