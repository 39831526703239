<template>
  <li class="article-item">
    <div class="article-item-header">
      <router-link
        class="article-title"
        target="_blank"
        :to="newsLinkTo">
        <span v-html="newsTitle"></span>
      </router-link>
      <div class="article-info">
        <ul class="article-info-list">
          <li class="article-info-item">
            <font-awesome-icon :icon="[ 'far', 'newspaper' ]" />
            <span>{{news.media}}</span>
          </li>
          <li class="article-info-item">
            <font-awesome-icon :icon="[ 'fas', 'user' ]" />
            <span>{{news.author}}</span>
          </li>
          <li class="article-info-item">
            <font-awesome-icon :icon="[ 'far', 'clock' ]" />
            <span>{{datetimePub}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="article-item-container">
      <div
        class="content"
        v-html="newsContent">
      </div>
    </div>
    <div class="article-item-footer">
      <div
        class="related-keywords-container"
        v-if="relatedPeoples.length">
        <div class="article-footer-title">提及人物</div>
        <ul class="list">
          <li
            class="item"
            v-for="d in (isShowAllPeople ? relatedPeoples : relatedPeoples.slice(0,5))"
            :key="d">
            {{d}}
          </li>
        </ul>
        <div
          style="margin-top:5px"
          v-if="isShowAllPeople==false&&relatedPeoples.length>5">
          <a style="cursor:pointer" @click="isShowAllPeople=true">...顯示更多</a>
        </div>
      </div>
      <div
        class="related-keywords-container"
        v-if="relatedCustoms.length">
        <div class="article-footer-title">提及風險</div>
        <ul class="list">
          <li
            class="item"
            v-for="d in (isShowAllCustom ? relatedCustoms : relatedCustoms.slice(0,5))"
            :key="d">
            {{d}}
          </li>
        </ul>
        <div
          style="margin-top:5px"
          v-if="isShowAllCustom==false&&relatedCustoms.length>5">
          <a style="cursor:pointer" @click="isShowAllCustom=true">...顯示更多</a>
        </div>
      </div>
      <div
        class="related-keywords-container"
        v-if="relatedKeywords.length">
        <div class="article-footer-title">提及關鍵字</div>
        <ul class="list">
          <li
            class="item"
            v-for="d in (isShowAllKeyword ? relatedKeywords : relatedKeywords.slice(0,5))"
            :key="d">
            {{d}}
          </li>
        </ul>
        <div
          style="margin-top:5px"
          v-if="isShowAllKeyword==false&&relatedKeywords.length>5">
          <a style="cursor:pointer" @click="isShowAllKeyword=true">...顯示更多</a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import moment from 'moment'
import utils from '@/utils/utils.js'

export default {
  props: {
    news: {
      required: true
    },
    uniID: {
      required: true
    },
    companyName: {
      required: true
    },
    searchQuery: {
      required: true
    },
  },
  data () {
    return {
      colorClass: 'color-danger',
      isShowAllKeyword: false,
      isShowAllCustom: false,
      isShowAllPeople: false,
      relatedKeywords: [],
      relatedPeoples: [],
      relatedCustoms: []
    }
  },
  watch: {
    news: {
      handler () {
        // relatedPeoples
        let RelatedPeopleWithoutBracketsSet = new Set()
        if (this.news['keywords-of-cls'] && this.news['keywords-of-cls'].people) {
          let RelatedPeopleSet = new Set(this.news['keywords-of-cls'].people)
          this.relatedPeoples = Array.from(RelatedPeopleSet)

          RelatedPeopleWithoutBracketsSet = new Set(
            this.news['keywords-of-cls'].people.map(d => {
              return d.split('(')[0] // 去掉括號的字串 e.g. 詹宏志(董事長) -> 詹宏志
            })
          )
        }

        // relatedCustoms
        const RelatedCustomSet = new Set()
        if (this.news['keywords-of-cls'] && this.news['keywords-of-cls'].custom) {
          Object.keys(this.news['keywords-of-cls'].custom).forEach(key => {
            const custom = this.news['keywords-of-cls'].custom[key]
            custom.forEach(d => RelatedCustomSet.add(d))          
          })
          this.relatedCustoms = Array.from(RelatedCustomSet)
        }

        // relatedKeywords
        const RelatedKeywordSet = new Set()
        if (this.news['keywords-of-cls'] && this.news['keywords-of-cls'].adept_all) {
          this.news['keywords-of-cls'].adept_all.forEach(d => {
            // 排除people和custom相同的關鍵字
            if (RelatedPeopleWithoutBracketsSet.has(d) == false && RelatedCustomSet.has(d) == false) {
              RelatedKeywordSet.add(d)
            }
          })
          this.relatedKeywords = Array.from(RelatedKeywordSet)
        }
      },
      immediate: true
    }
  },
  computed: {
    newsLinkTo () {
      // // let query = {}
      // // 如果是公司查詢加上關鍵字比對
      // if (this.news.keywords) {
      //   if (this.news.keywords.company_name && this.news.keywords.company_name.length) {
      //     query.rCompanyName = this.news.keywords.company_name.join(',')
      //   }
      //   if (this.news.keywords.representative_name && this.news.keywords.representative_name.length) {
      //     query.rRepresentativeName = this.news.keywords.representative_name.join(',')
      //   }
      //   if (this.news.keywords.shareholders && this.news.keywords.shareholders.length) {
      //     query.rShareholders = this.news.keywords.shareholders.join(',')
      //   }
      //   if (this.news.keywords.managers && this.news.keywords.managers.length) {
      //     query.rManagers = this.news.keywords.managers.join(',')
      //   }
      // }
      let route = {
        name: 'newsDetail',
        params: {
          uniID: this.news.uniID
        },
        query: {
          company: this.uniID,
          name: this.companyName
        }
      }
      if (this.searchQuery.get.news.filter && this.searchQuery.get.news.filter.length) {
        let highlightArr = []
        this.searchQuery.get.news.filter.forEach(d => {
          if (d._keywords && d._keywords.length) {
            highlightArr = highlightArr.concat(d._keywords)
          }
        })
        route.query.highlight = highlightArr.join(',')
      }
      return route
    },
    newsTitle () {
      let text = ''
      if (this.news.title) {
        text = this.news.title
        this.relatedKeywords.forEach(d => {
          let newText = `<span class="${this.colorClass}">${d}</span>`
          text = utils.replaceAll(text, d, newText)
        })
      }
      return text
    },
    datetimePub () {
      return moment(this.news.datetime_pub).format('YYYY-MM-DD')
    },
    newsContent () {
      let text = ''
      if (this.news.content) {
        text = this.news.content
        this.relatedKeywords.forEach(d => {
          let newText = `<span class="${this.colorClass}">${d}</span>`
          text = utils.replaceAll(text, d, newText)
        })
        text = text + '...'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.article-item-footer {
  display: flex;
  
  .related-keywords-container {
    width: 200px
  }
}
</style>